import React, {useEffect, useState} from 'react';
import {Button, Form, Header, Icon, Image, Input, Message, Segment} from 'semantic-ui-react';
import axios from 'axios';
import {BASE_STYLES} from '../../Consts';
import _ from 'lodash';
import queryString from 'query-string';

export default function SellerContractConfirmation({match, history, location}) {
  const {ern} = queryString.parse(location.search);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [contact, setContact] = useState('');
  const [contactError, setContactError] = useState(false);

  const [isProceeding, setIsProceeding] = useState(false);
  const [error, setError] = useState(null);

  const doConfirm = () => {
    const isNameError = _.isEmpty(name);
    const isContactError = _.isEmpty(contact);

    if (isNameError || isContactError) {
      setNameError(isNameError);
      setContactError(isContactError);
      return false;
    }

    setIsProceeding(true);
    axios.post('/apis/seller/contracts', {ern, name, contact})
      .then(response => {
        const contract = response.data;
        history.replace({
          pathname: './contract/viewer',
          contract: contract,
        });
      })
      .catch(error => {
        setError(error);
      })
      .finally(() => {
        setIsProceeding(false);
      });
  };

  return (
    <div style={{
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'center',
      backgroundColor: BASE_STYLES.THEME_COLOR,
      padding: 10,
    }}>
      <Image src={'/images/carmon.png'} style={{width: 160, alignSelf: 'center', marginBottom: (ern ? 10 : 0)}} />
      {ern && <><Segment size={'large'} attached={error !== null}>
        <Header>카몬 계약서 확인</Header>
        <Form loading={isProceeding} size={'large'}>
          <Form.Field
            control={Input}
            label="이름"
            placeholder="예약자명"
            value={name}
            onChange={(e, {value}) => setName(value)}
            error={nameError && {
              content: '예약에 사용하신 이름을 입력해주세요.',
              pointing: 'above',
            }}
          />
          <Form.Field
            control={Input}
            label="전화번호"
            placeholder="예약전화번호"
            value={contact}
            onChange={(e, {value}) => {
              const refinedValue = _.replace(value, /[^0-9.]/g, '');
              setContact(refinedValue);
            }}
            error={contactError && {
              content: '예약에 사용하신 전화번호를 입력해주세요.',
              pointing: 'above',
            }}
          />
          <Button size={'large'} fluid primary content={'계약서 확인'} onClick={() => doConfirm()} />
        </Form>
      </Segment>
        <Message error attached="bottom" hidden={error === null}>
          <Icon name={'exclamation triangle'} /> {error?.response.data.message}
        </Message>
      </>}
    </div>
  );
}