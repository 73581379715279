import React, {useEffect, useState} from 'react';
import {
  Button, Dimmer,
  Divider,
  Form,
  Header,
  Icon,
  Image,
  Input,
  List,
  Loader,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react';
import axios from 'axios';
import {BASE_STYLES, CARMON_INFO} from '../Consts';
import _ from 'lodash';
import queryString from 'query-string';
import moment from 'moment';

export default function DealerBuyerInformation({match, history, location}) {
  const {edi} = queryString.parse(location.search);

  // const [name, setName] = useState('');
  // const [nameError, setNameError] = useState(false);
  // const [contact, setContact] = useState('');
  // const [contactError, setContactError] = useState(false);
  //

  const [isConfirmOpened, setIsConfirmOpened] = useState(false);
  const [isProceeding, setIsProceeding] = useState(false);
  const [data, setData] = useState({
    isSent: null,
    sendTime: null,
    buyerInfo: {},
  });
  const [error, setError] = useState(null);
  const [confirmed, setConfirmed] = useState(false);

  const doConfirm = () => {
    setIsProceeding(true);
    axios.post('/apis/dealer/buyer-information', {edi})
      .then(response => {
        try {
          window.location.href = 'kakaotalk://inappbrowser/close';
        } finally {
          setConfirmed(true);
          setIsConfirmOpened(false);
        }
      })
      .catch(error => {
        setError(error);
      })
      .finally(() => {
        setIsProceeding(false);
      });
  };

  useEffect(() => {
    if (edi) {
      setIsProceeding(true);
      axios.get(`/apis/dealer/buyer-information?edi=${edi}`)
        .then(response => setData(response.data))
        .catch(error => setError(error.response.data))
        .finally(() => setIsProceeding(false));
    }
  }, []);

  return (
    <>
      <Dimmer active={isProceeding} page>
        <Loader size="massive">Loading...</Loader>
      </Dimmer>

      <div style={{
        minHeight: '100vh',
        backgroundColor: BASE_STYLES.THEME_COLOR,
        padding: '20px 10px',
      }}>
        <Image src={'/images/carmon.png'} centered style={{width: 160, marginBottom: (edi ? 10 : 0)}} />
        {
          _.isEmpty(edi)
            ?
            (<Message error>
              <Message.Header>
                <Icon name={'exclamation triangle'} />
                {error ? error.message : '잘못된 요청입니다.'}
              </Message.Header>
            </Message>)
            :
            (
              data.isSent
                ?
                (<Message warning icon size={'large'}>
                  <Icon name={'exclamation triangle'} />
                  <Message.Content>
                    <Message.Header> 이미 매수자 정보를 전달하셨습니다.</Message.Header>
                    (전달일시 : {moment(data.sendTime).format('YYYY-MM-DD HH:mm')})
                    <Message.List>
                      {data.buyerInfo.type === '1'
                        ?
                        (
                          <>
                            <Message.Item>성명 : {data.buyerInfo.name}</Message.Item>
                            <Message.Item>주민등록번호 : {data.buyerInfo.registrationNumber}</Message.Item>
                          </>
                        )
                        :
                        (
                          <>
                            <Message.Item>법인명 : {data.buyerInfo.name}</Message.Item>
                            <Message.Item>법인등록번호 : {data.buyerInfo.registrationNumber}</Message.Item>
                          </>

                        )
                      }
                      <Message.Item>주소 : {data.buyerInfo.address} {data.buyerInfo.addressDetail}</Message.Item>
                    </Message.List>
                  </Message.Content>
                </Message>)
                :
                (
                  <>
                    <Form loading={isProceeding} size={'large'}>
                      <Segment>
                        <Header as="h4" dividing>
                          <Icon name="building" />
                          소속 상사 정보
                        </Header>
                        <Form.Field
                          control={Input}
                          readOnly
                          label="상사명"
                          value={data.buyerInfo.companyName || ''}
                        />
                        <Form.Field
                          control={Input}
                          readOnly
                          label="사업자등록번호"
                          value={data.buyerInfo.businessNumber || ''}
                        />
                        <Form.Field
                          control={Input}
                          readOnly
                          label="대표자"
                          value={data.buyerInfo.representative || ''}
                        />
                        <Form.Field
                          control={Input}
                          readOnly
                          label="대표전화"
                          value={data.buyerInfo.contactNumber || ''}
                        />
                        <Form.Field
                          control={Input}
                          readOnly
                          label="주소"
                          value={data.buyerInfo.address || ''}
                        />
                        <Form.Field
                          control={Input}
                          readOnly
                          value={data.buyerInfo.addressDetail || ''}
                        />
                      </Segment>
                      <Segment>
                        <Header as="h4" dividing>
                          <Icon name="user" />
                          매수자 정보
                        </Header>
                        <Form.Field
                          control={Input}
                          readOnly
                          label={data.buyerInfo.companyType === '1' ? '성명' : '법인명'}
                          value={data.buyerInfo.companyType === '1' ? data.buyerInfo.representative || '' : data.buyerInfo.companyName || ''}
                        />
                        <Form.Field
                          control={Input}
                          readOnly
                          label={data.buyerInfo.companyType === '1' ? '주민등록번호' : '법인등록번호'}
                          value={data.buyerInfo.buyerRegistrationNumber || ''}
                        />
                        <Form.Field
                          control={Input}
                          readOnly
                          label={data.buyerInfo.companyType === '1' ? '주소' : '법인소재지'}
                          value={data.buyerInfo.address || ''}
                        />
                        <Form.Field
                          control={Input}
                          readOnly
                          value={data.buyerInfo.addressDetail || ''}
                        />
                        <Modal
                          onClose={() => setIsConfirmOpened(false)}
                          onOpen={() => setIsConfirmOpened(true)}
                          open={isConfirmOpened}
                          trigger={<Button disabled={confirmed} fluid primary icon={'thumbs up outline'}
                                           content={'확인완료'} />}
                        >
                          <Modal.Header>꼭 확인하세요!</Modal.Header>
                          <Modal.Content>
                            <Modal.Description>
                              <p>다음의 정보가 판매자에게 전달됩니다.</p>
                              <List as={'ul'}>
                                <List.Item as={'li'}>
                                  <Header
                                    as={'h5'}>{data.buyerInfo.companyType === '1' ? '성명' : '법인명'} : {data.buyerInfo.representative}</Header>
                                </List.Item>
                                <List.Item as={'li'}>
                                  <Header
                                    as={'h5'}>{data.buyerInfo.companyType === '1' ? '주민등록번호' : '법인등록번호'} : {data.buyerInfo.buyerRegistrationNumber}</Header>
                                </List.Item>
                                <List.Item as={'li'}>
                                  <Header
                                    as={'h5'}>{data.buyerInfo.companyType === '1' ? '주소' : '법인소재지'} : {data.buyerInfo.address} {data.buyerInfo.addressDetail}</Header>
                                </List.Item>
                              </List>
                              <p>정보를 전달하시겠습니까?</p>
                            </Modal.Description>
                          </Modal.Content>
                          <Modal.Actions>
                            <Button onClick={() => setIsConfirmOpened(false)} content={'취소'} />
                            <Button
                              primary
                              content="전달하기"
                              icon="send"
                              onClick={() => doConfirm()}
                            />
                          </Modal.Actions>
                        </Modal>
                      </Segment>
                    </Form>
                    <Message warning>
                      <Message.Header>
                        <Icon name={'exclamation triangle'} /> 꼭 확인해주세요!
                      </Message.Header>
                      <Message.List>
                        <Message.Item>
                          딜러님의 소속 상사 및 매수자 정보를 반드시 확인해주세요!
                        </Message.Item>
                        <Message.Item>
                          상사 정보 또는 매수자 정보가 다르거나, 문제가 있을 경우 카몬고객센터(<a
                          href={`tel:${CARMON_INFO.REPRESENTATIVE_TEL}`}>{CARMON_INFO.REPRESENTATIVE_TEL}</a>)로 연락주세요.
                        </Message.Item>
                      </Message.List>
                    </Message>
                  </>
                )
            )
        }
      </div>
    </>
  );
}