import axios from 'axios';

export const LOGIN = "AUTOOASIS/LOGIN";
export const LOGOUT = "AUTOOASIS/LOGOUT";

export const isLogin = (success) => ({ type: LOGIN, success });
export const logout = () => ({ type: LOGOUT });

const initalState = {
  isLogined: false,
  message: '',
};

export const login = (state = initalState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLogined: action.success,
      };
    case LOGOUT:
      return {
        ...state,
        isLogined: false,
      };

    default:
      return state;
  }
};
