import React from 'react';
import {Route} from 'react-router-dom';
import SellerContractConfirmation from './SellerContractConfirmation';
import SellerContractDocument from './SellerContractDocument';

export default function SellerContractDocuments({match, location, history}) {
  return (
    <>
      <Route exact path={`${match.path}`} component={SellerContractConfirmation} />
      <Route path={`${match.path}/viewer`} component={SellerContractDocument} />
    </>
  );
}
