import React, {useEffect} from 'react';
import {Grid, Header, Image, Message, Table} from 'semantic-ui-react';
import moment from 'moment';
import {CommonUtil} from '../../utils/CommonUtil';
import {CARMON_INFO} from '../../Consts';
import _ from 'lodash';
import {numToKorean} from 'num-to-korean';

const TH = {paddingTop: 3, paddingBottom: 3, textAlign: 'center'};
const TD = {paddingTop: 3, paddingBottom: 3};
const INNER_TD = {paddingTop: 1, paddingBottom: 1};
const DATA_TD = {paddingTop: 5, paddingBottom: 5};
const INFO_TD = {fontSize: 10, padding: 0, verticalAlign: 'top', border: 0, lineHeight: 1.3};

const BORDER = '1px solid rgba(34, 36, 38, 0.1)';

export default function SellerContractDocument({match, location, history}) {
  const {contract: contractData} = location;

  useEffect(() => {
    if (!contractData) history.goBack();

    const viewport = document.querySelector('meta[name=viewport]');
    if (viewport) viewport.content = 'width=device-width, initial-scale=0.5, minimum-scale=0.0, maximum-scale=2.0, user-scalable=yes';
  }, []);

  const getPageMargins = () => {
    return `
    * {
      font-size:10pt;
      background-color:#fff !transparent;
    }
    
    .page {
        width: 210mm;
        min-height: 297mm;
        padding: 10mm;
        margin: 10mm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        display:fixed;
    }
    
    .receiver-signature {
      font-family: Museum-Medium !important;
      color: rgba(34, 36, 38, 0.8);
      font-size: 16px;
      font-weight: bold;
      font-style: italic;
      position: absolute;
      width: 50;
      height: 50;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    
    @page {
        size: A4;
        margin: 0;
    }
    
    @media print {    
        html, body {
            width: 210mm;
            height: 297mm;
            background-color:#fff !important;        
        }
        
        .page {
            margin: 0;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
        }
    }`;
  };

  return (
    <>
      <style>{getPageMargins()}</style>

      <div id={'carmon-contract-document'} className="page">
        <section id={'sec-title'} style={{textAlign: 'center'}}>
          <Header as="h3" style={{marginBottom: 0}}>자동차양도증명서(자동차매매업자거래용)</Header>
          {/*<span style={{float:'left'}}>지역 및 일련번호 <strong>경기 31-20-300000</strong></span>*/}
          <div><span style={{float: 'right', fontSize: '7pt'}}>※ 유의사항을 읽고 작성하여 주시기 바랍니다.</span></div>
        </section>

        <section id={'sec-contract-1st'} style={{marginBottom: 3}}>
          <Table compact celled basic unstackable fixed style={{borderRadius: 0, borderLeft: 0, borderRight: 0}}>
            <Table.Body>
              <Table.Row>
                <Table.Cell style={{
                  width: 200,
                  ...TH,
                }}>
                  중고자동차 제시 또는 매도번호
                </Table.Cell>
                <Table.Cell />
              </Table.Row>
            </Table.Body>
          </Table>
        </section>

        <section id={'sec-contract-2nd'} style={{marginBottom: 3}}>
          <Table compact celled basic unstackable fixed
                 style={{borderRadius: 0, borderLeft: 0, borderRight: 0, margin: 0}}>
            <Table.Body>
              <Table.Row>
                <Table.Cell rowSpan="3" style={{width: 54, ...TH}}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>계</span>
                    <span>약</span>
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>당</span>
                    <span>사</span>
                    <span>자</span>
                  </div>
                </Table.Cell>
                <Table.Cell
                  style={{width: 44, paddingLeft: 2, paddingRight: 2, textAlign: 'center', borderRight: BORDER}}>
                  양도인<br />(갑)
                </Table.Cell>
                <Table.Cell style={{padding: 0, borderLeft: 0, verticalAlign: 'top'}}>
                  <Table compact celled basic unstackable fixed style={{border: 0, margin: 0}}>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell style={{
                          width: 68,
                          fontSize: 12,
                          borderRight: 0,
                          verticalAlign: 'top', ...INNER_TD,
                        }}>성명(명칭)</Table.Cell>
                        <Table.Cell style={{borderLeft: 0, ...INNER_TD}}>
                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}>
                            <span style={{marginRight: 6}}>{contractData.transferName}</span>
                            <span style={{fontSize: '7pt'}}>(서명 또는 인)</span>
                            <Image
                              src={_.replace(contractData.transferSignature, 'carmon-dev', 'carmon-test')}
                              style={{
                                position: 'absolute',
                                width: 50,
                                height: 50,
                              }} />
                          </div>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{fontSize: 12, borderRight: 0, verticalAlign: 'top', ...INNER_TD}}>전화번호</Table.Cell>
                        <Table.Cell style={{
                          borderLeft: 0,
                          textAlign: 'right',
                          ...INNER_TD,
                        }}>{contractData.transferContact}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            fontSize: 12,
                            borderRight: 0,
                            verticalAlign: 'top',
                            minHeight: 40,
                            height: 40,
                            ...INNER_TD,
                          }}>주소</Table.Cell>
                        <Table.Cell
                          style={{
                            borderLeft: 0,
                            fontSize: 11,
                            verticalAlign: 'top',
                            ...INNER_TD,
                          }}>{contractData.transferAddress}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Table.Cell>
                <Table.Cell style={{width: 44, paddingLeft: 2, paddingRight: 2, textAlign: 'center'}}>
                  양수인<br />(을)
                </Table.Cell>
                <Table.Cell style={{padding: 0, borderRight: 0, verticalAlign: 'top'}}>
                  <Table compact celled basic unstackable fixed style={{border: 0, margin: 0}}>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell style={{
                          width: 68,
                          fontSize: 12,
                          borderRight: 0,
                          verticalAlign: 'top',
                          ...INNER_TD,
                        }}>성명(명칭)</Table.Cell>
                        <Table.Cell style={{borderLeft: 0, ...INNER_TD}}>
                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}>
                            <div style={{marginRight: 6}}>
                              <span>{contractData.receiverCompanyName}</span>
                              <span style={{fontSize: 11}}>(상품용)</span>
                            </div>
                            <span className={'receiver-signature'}>{contractData.receiverRepresentative}</span>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{fontSize: 12, borderRight: 0, verticalAlign: 'top', ...INNER_TD}}>전화번호</Table.Cell>
                        <Table.Cell style={{
                          borderLeft: 0,
                          textAlign: 'right',
                          ...INNER_TD,
                        }}>{contractData.receiverContact}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          style={{
                            fontSize: 12,
                            borderRight: 0,
                            verticalAlign: 'top',
                            minHeight: 40,
                            height: 40,
                            ...INNER_TD,
                          }}>주소</Table.Cell>
                        <Table.Cell
                          style={{
                            borderLeft: 0,
                            fontSize: 11,
                            verticalAlign: 'top',
                            ...INNER_TD,
                          }}>{contractData.receiverAddress} {contractData.receiverAddressDetail}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Table compact celled basic unstackable fixed
                 style={{borderRadius: 0, borderLeft: 0, borderRight: 0, borderTop: 0, margin: 0}}>
            <Table.Body>
              <Table.Row>
                <Table.Cell rowSpan="3" style={{width: 68, ...TH, borderRight: BORDER}}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>자</span>
                    <span>동</span>
                    <span>차</span>
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>매</span>
                    <span>매</span>
                    <span>업</span>
                    <span>자</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{width: 110, ...TH, borderLeft: 0}}>
                  등록번호 및 상호
                </Table.Cell>
                <Table.Cell style={{...TD}}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>제22-0001-000001호</span>
                    <span>(주)카몬</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{...TD}} />
              </Table.Row>
              <Table.Row>
                <Table.Cell style={{...TH}}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                  }}>
                    <span>대</span>
                    <span>표</span>
                    <span>자</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{...TD}}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}>
                    <span style={{marginRight: 6}}>{CARMON_INFO.REPRESENTATIVE}</span>
                    <span style={{fontSize: '7pt'}}>(직인)</span>
                    <Image
                      src={'/images/carmon-stamp.png'}
                      style={{
                        position: 'absolute',
                        width: 40,
                        height: 40,
                      }} />
                  </div>
                </Table.Cell>
                <Table.Cell style={{...TD}} />
              </Table.Row>
              <Table.Row>
                <Table.Cell style={{...TH}}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                  }}>
                    <span>취</span>
                    <span>급</span>
                    <span>자</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{...TD}}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}>
                    <span style={{marginRight: 6}}>{CARMON_INFO.REPRESENTATIVE}</span>
                    <span style={{fontSize: '7pt'}}>(인)</span>
                    <Image
                      src={'/images/carmon-stamp.png'}
                      style={{
                        position: 'absolute',
                        width: 40,
                        height: 40,
                      }} />
                  </div>
                </Table.Cell>
                <Table.Cell />
              </Table.Row>
            </Table.Body>
          </Table>
        </section>

        <section id={'sec-contract-3rd'} style={{marginBottom: 3}}>
          <Table compact celled basic unstackable fixed style={{borderRadius: 0, borderLeft: 0, borderRight: 0}}>
            <Table.Body>
              <Table.Row>
                <Table.Cell style={{
                  width: 178,
                  ...TH,
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}>
                    <span>계</span>
                    <span>약</span>
                    <span>년</span>
                    <span>월</span>
                    <span>일</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{...TD, textAlign: 'center'}}>
                  <div style={{
                    width: 160,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                  }}>
                    <span>{moment(contractData.contractDate).format('YYYY 년')}</span>
                    <span>{moment(contractData.contractDate).format('MM 월')}</span>
                    <span>{moment(contractData.contractDate).format('DD 일')}</span>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </section>

        <section id={'sec-contract-4th'}
                 style={{textAlign: 'center', marginTop: 8, marginBottom: 3, borderTop: BORDER}}>
          <Header as="h4" style={{margin: 0}}>중고자동차 매매계약서</Header>

          <Table compact celled basic unstackable fixed
                 style={{borderRadius: 0, borderLeft: 0, borderRight: 0, margin: 0}}>
            <Table.Body>
              <Table.Row>
                <Table.Cell style={{
                  width: 136,
                  ...TH,
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>자</span>
                    <span>동</span>
                    <span>차</span>
                    <span>등</span>
                    <span>록</span>
                    <span>번</span>
                    <span>호</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{...DATA_TD}}>{contractData.carId}</Table.Cell>
                <Table.Cell style={{
                  width: 136,
                  ...TH,
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>주</span>
                    <span>행</span>
                    <span>거</span>
                    <span>리</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{
                  ...DATA_TD,
                  textAlign: 'right',
                }}>{CommonUtil.Unit.format(contractData.carDistance)} Km</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={{
                  width: 136,
                  ...TH,
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>차</span>
                    <span>종</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{...DATA_TD}}>{contractData.carType} {contractData.carYear} 년식</Table.Cell>
                <Table.Cell style={{
                  width: 136,
                  ...TH,
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>차</span>
                    <span>명</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{...DATA_TD}}>{contractData.carModel} {contractData.carSeries}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={{
                  width: 136,
                  ...TH,
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>차</span>
                    <span>대</span>
                    <span>번</span>
                    <span>호</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{...DATA_TD}}>{contractData.carVim}</Table.Cell>
                <Table.Cell style={{
                  width: 136,
                  ...TH,
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>계</span>
                    <span>약</span>
                    <span>금</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{...DATA_TD, textAlign: 'right'}}>0 원</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={{
                  width: 136,
                  ...TH,
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>중</span>
                    <span>도</span>
                    <span>금</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{...DATA_TD, textAlign: 'right'}}>0 원</Table.Cell>
                <Table.Cell style={{
                  width: 136,
                  ...TH,
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>잔</span>
                    <span>금</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{...DATA_TD, textAlign: 'right'}}>0 원</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={{
                  width: 136,
                  ...TH,
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>매</span>
                    <span>매</span>
                    <span>금</span>
                    <span>액</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{...TD, textAlign: 'right'}}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    lineHeight: 1.1,
                  }}>
                    <span>{CommonUtil.Unit.format(contractData.bidAmount)} 원</span>
                    <span style={{fontSize: 11}}>({numToKorean(contractData.bidAmount)}원)</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{
                  width: 136,
                  ...TH,
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>등</span>
                    <span>록</span>
                    <span>비</span>
                    <span>용</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{...DATA_TD, textAlign: 'right'}}>0 원</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={{
                  width: 136,
                  ...TH,
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>매</span>
                    <span>매</span>
                    <span>알</span>
                    <span>선</span>
                    <span>수</span>
                    <span>수</span>
                    <span>료</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{...DATA_TD, textAlign: 'right'}}>0 원</Table.Cell>
                <Table.Cell style={{
                  width: 136,
                  ...TH,
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>관</span>
                    <span>리</span>
                    <span>비</span>
                    <span>용</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{...DATA_TD, textAlign: 'right'}}>0 원</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={{
                  width: 136,
                  ...TH,
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span>자</span>
                    <span>동</span>
                    <span>차</span>
                    <span>인</span>
                    <span>도</span>
                    <span>일</span>
                  </div>
                </Table.Cell>
                <Table.Cell
                  style={{...DATA_TD}}>{moment(contractData.contractDate).format('YYYY년 MM월 DD일')}</Table.Cell>
                <Table.Cell style={{
                  width: 136,
                  ...TH,
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <span style={{letterSpacing: -1}}>압류 및 저당권 등록여부</span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{...DATA_TD}} />
              </Table.Row>
            </Table.Body>
          </Table>
        </section>

        <section id={'sec-contract-5th'}>
          <Table collapsing compact basic unstackable fixed
                 style={{marginTop: 8, borderRadius: 0, border: 0}}>
            <Table.Body>
              <Table.Row style={{border: 0}}>
                <Table.Cell style={{...INFO_TD, width: 110}}>제1조(당사자표시)</Table.Cell>
                <Table.Cell style={{...INFO_TD}}>양도인을 "갑"이라 하고, 양수인을
                  "을"이라
                  한다.</Table.Cell>
              </Table.Row>
              <Table.Row style={{border: 0}}>
                <Table.Cell style={{...INFO_TD}}>제2조(동시이행
                  등)</Table.Cell>
                <Table.Cell style={{...INFO_TD}}>
                  ① "갑"은 잔금 수령과 상환으로 소유권이전등록에 필요한 서류와 매매목적물을 "을"에게 인도하기로 한다. 다만, "갑"과 "을" 의 합의에 따라 매매금액의
                  2/3 이상의 상당액을 지급한 경우에는 매매목적물을 인도할 수 있다.
                  ② "을"은 "갑"에게 잔금을 지급함과 동시에 소유권이전등록의 절차에 필요한 서류와 등록비용을 자동차매매업자(이하 "매매업자"라 한다)에게 내주어야 한다. 다만, 매매업자가
                  매수할 때에는 그러하지 아니하다.
                  ③ 매매업자는 잔금지급일부터 15일 이내에 자동차소유권 이전등록 신청을 하여야 한다.
                </Table.Cell>
              </Table.Row>
              <Table.Row style={{border: 0}}>
                <Table.Cell style={{...INFO_TD}}>제3조(공과금
                  부담)</Table.Cell>
                <Table.Cell style={{...INFO_TD}}>
                  이 자동차에 대한 제세공과금은 자동차 인도일을 기준으로 하여, 그 기준일까지의 분은 "갑"이 부담하고 기준일 다음날부터의 분은 "을"이 부담한다. 다만, 관계
                  법령에 제세공과금 납부에 관하여 특별한 규정이 있는 경우에는 그에 따른다.
                </Table.Cell>
              </Table.Row>
              <Table.Row style={{border: 0}}>
                <Table.Cell
                  style={{...INFO_TD}}>제4조(사고책임)</Table.Cell>
                <Table.Cell style={{...INFO_TD}}>
                  "을"은 이 자동차를 인수한 때부터 발생하는 모든 사고에 대하여 자기를 위하여 운행하는 자로서의 책임을 진다.
                </Table.Cell>
              </Table.Row>
              <Table.Row style={{border: 0}}>
                <Table.Cell style={{...INFO_TD}}>제5조(법률상의
                  하자책임)</Table.Cell>
                <Table.Cell style={{...INFO_TD}}>
                  ① 자동차 인도일 이전에 발생한 행정처분 또는 이전등록 요건의 불비 등의 하자에 대해서는 "갑"이 그 책임을 진다.
                  ② 매매업자는 「자동차관리법」 제58조제1항에 따라 자동차의 성능ㆍ상태의 점검 내용을 "을"에게 알려야 하고, "을"이 원하는 경우 같은 법 제58조의4에 해당하는 자가 자동차
                  가격을 조사ㆍ산정한 내용을 알려야 한다.
                </Table.Cell>
              </Table.Row>
              <Table.Row style={{border: 0}}>
                <Table.Cell style={{...INFO_TD}}>제6조(해약금
                  등)</Table.Cell>
                <Table.Cell style={{...INFO_TD}}>
                  ① "갑"이 이 계약을 위반한 경우에는 "갑"은 해약금으로 계약금의 2배액을 "을"에게 배상해야 하며, "을"이 위약한 경우에는 "을"은 "갑"에게 계약금의
                  반환을 요구할 수 없다. 다만, 손해배상의 청구는 방해하지 않는다.
                  ② 제5조제2항에 따라 "갑"이 고지한 자동차의 성능ㆍ상태의 점검내용 중 주행거리, 사고 또는 침수사실이 다르거나, "갑"이 자동차의 성능ㆍ상태의 점검내용 또는 압류ㆍ저당권의
                  등록 여부를 거짓으로 고지하거나 고지하지 아니한 경우 "을"은 자동차인도일로부터 30일 이내에 매매계약을 해제할 수 있으며, 이 경우 "을"은 자동차를 즉시 "갑"에게 반환하고
                  "갑"은 자동차의 반환과 동시에 이미 지급받은 매매금액을 "을"에게 반환하여야 한다.
                </Table.Cell>
              </Table.Row>
              <Table.Row style={{border: 0}}>
                <Table.Cell style={{...INFO_TD}}>제7조(매매업자의
                  책임)</Table.Cell>
                <Table.Cell style={{...INFO_TD}}>
                  제5조의 하자에 대해서는 매매업자가 매도인과 동일한 책임을 지며, 시ㆍ도의 조례가 하자보증금을 예치하도록 하는 경
                  우 매수인의 요청이 있을 때에는 그 하자보증금으로 매수인에게 우선 지급해야 한다. 다만, 매매업자는 양도인 또는 그 하자에 책임이 있는 자에
                  대하여 구상권을 행사할 수 있다.
                </Table.Cell>
              </Table.Row>
              <Table.Row style={{border: 0}}>
                <Table.Cell style={{...INFO_TD}}>제8조(등록 지체
                  책임)</Table.Cell>
                <Table.Cell style={{...INFO_TD}}>
                  "갑"과 "을"이 매매업자에게 이전등록의 대행에 필요한 서류 등의 발급 또는 권한의 위임을 한 후 매매업자가 이전등록
                  신청을 대행하지 않을 때에는 이에 대한 모든 책임을 매매업자가 진다.
                </Table.Cell>
              </Table.Row>
              <Table.Row style={{border: 0}}>
                <Table.Cell style={{...INFO_TD}}>제9조(할부승계
                  특약)</Table.Cell>
                <Table.Cell style={{...INFO_TD}}>
                  "갑"이 자동차를 할부로 구입하여 할부금을 다 내지 않은 상태에서 "을"에게 양도하는 경우에는 나머지 할부금을 "을"이
                  승계하여 부담할 것인지의 여부를 특약사항란에 적어야 한다.
                </Table.Cell>
              </Table.Row>
              <Table.Row style={{border: 0}}>
                <Table.Cell
                  style={{...INFO_TD}}>제10조(계약서)</Table.Cell>
                <Table.Cell style={{...INFO_TD}}>
                  이 계약서는 <span
                  style={{
                    fontSize: 10,
                    textDecoration: 'underline',
                  }}>{moment(contractData.contractDate).format('YYYY')}</span>년 <span
                  style={{
                    fontSize: 10,
                    textDecoration: 'underline',
                  }}>{moment(contractData.contractDate).format('MM')}</span>월 <span
                  style={{
                    fontSize: 10,
                    textDecoration: 'underline',
                  }}>{moment(contractData.contractDate).format('DD')}</span>일 4통 작성하여
                  "갑"이 1통, "을"이 1통, 등록절차를 대행하는 매매업자가 2통씩을 각각 지닌다.
                </Table.Cell>
              </Table.Row>
              <Table.Row style={{border: 0}}>
                <Table.Cell style={{...INFO_TD, paddingTop: 12}}>(특약사항) : </Table.Cell>
                <Table.Cell style={{...INFO_TD, paddingTop: 12}} />
              </Table.Row>
              <Table.Row style={{border: 0}}>
                <Table.Cell colSpan={2} style={{border: 0, paddingLeft: 0, paddingRight: 0}}>
                  <div style={{border: BORDER, padding: 4, lineHeight: 1.2}}>
                    <span style={{fontSize: 11, marginBottom: 6}}>※ 본인은 자동차관리법 제12조제2항에 의거 상기차량 매매에 따른 소유권 이전등록행위 일체를 위임하며, 개인정보 보호법 제15조 및 제24조에 의거 상사에서 부가가치세 신고와
                    관련하여 주민등록번호를 이용, 국세청(홈택스)에서 사업자 등록 유무를 조회하는데 동의함.</span>
                    <div
                      style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                      <span style={{textDecoration: 'underline', marginRight: 10}}>{contractData.transferName}</span>
                      <span style={{fontSize: '7pt'}}>(서명 또는 인)</span>
                      <Image
                        src={_.replace(contractData.transferSignature, 'carmon-dev', 'carmon-test')}
                        style={{
                          position: 'absolute',
                          width: 50,
                          height: 50,
                        }} />
                    </div>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </section>

        <section id={'sec-contract-6th'} style={{marginTop: 8}}>
          <Header style={{fontSize: 14, marginBottom: 8}}>
            「자동차등록규칙」 제33조제2항제2호에 따라 위의 중고자동차매매계약서 기재내용과 같이 양도하였음을 증명합니다.
          </Header>

          <Table compact unstackable style={{borderRadius: 0, border: 0, margin: 0}}>
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={4} style={{...INNER_TD}}>
                  <div style={{
                    width: 160,
                    float: 'right',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                  }}>
                    <span>{moment(contractData.contractDate).format('YYYY 년')}</span>
                    <span>{moment(contractData.contractDate).format('MM 월')}</span>
                    <span>{moment(contractData.contractDate).format('DD 일')}</span>
                  </div>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  style={{width: '10%', ...TH, border: 0, borderBottom: BORDER, borderBottomWidth: 2}}>양도인</Table.Cell>
                <Table.Cell style={{
                  width: '40%',
                  ...INNER_TD,
                  border: 0,
                  borderBottom: BORDER,
                  borderBottomWidth: 2,
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}>
                    <span style={{marginRight: 6}}>{contractData.transferName}</span>
                    <span style={{fontSize: '7pt'}}>(서명 또는 인)</span>
                    <Image
                      src={_.replace(contractData.transferSignature, 'carmon-dev', 'carmon-test')}
                      style={{
                        position: 'absolute',
                        width: 50,
                        height: 50,
                      }} />
                  </div>
                </Table.Cell>
                <Table.Cell
                  style={{width: '10%', ...TH, border: 0, borderBottom: BORDER, borderBottomWidth: 2}}>양수인</Table.Cell>
                <Table.Cell
                  style={{
                    width: '40%', ...INNER_TD,
                    border: 0,
                    borderBottom: BORDER,
                    borderBottomWidth: 2,
                  }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}>
                    <div style={{marginRight: 6}}>
                      <span>{contractData.receiverCompanyName}</span>
                      <span style={{fontSize: 11}}>(상품용)</span>
                    </div>
                    <span style={{fontSize: '7pt'}}>(서명 또는 인)</span>
                    <span className={'receiver-signature'}>{contractData.receiverRepresentative}</span>
                  </div>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell colSpan={4} style={{...INNER_TD}}>
                  <div style={{
                    float: 'right',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                  }}>
                    <span style={{fontSize: 11}}>[카몬계약서번호] {contractData.contractNumber}</span>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </section>
      </div>
    </>
  );
}