import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {login} from '../reducers/AutoOasisAuthReducer';
import {logger} from 'redux-logger/src';

const persistConfig = {
  key: "root",
  // localStorage에 저장합니다.
  storage,
  // auth, board, studio 3개의 reducer 중에 auth reducer만 localstorage에 저장합니다.
  whitelist: ["login"]
  // blacklist -> 그것만 제외합니다
};

const rootReducer = combineReducers({login});
const wrapperedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: wrapperedReducer,
  middleware: [],
  devTools: true,
  preloadState: {
    login:
      {
        isLogined:  false,
        message: '',
      }
  },
});

export const persistor = persistStore(store);
