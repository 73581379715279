import React, {useEffect, useReducer, useState} from 'react';
import {
  Button,
  Container, Dimmer, Dropdown,
  Form,
  Grid,
  Header,
  Icon, Image, List, Loader,
  Message,
  Segment,
  Table,
  TextArea,
  Transition,
} from 'semantic-ui-react';
import axios from 'axios';
import {toast} from 'react-semantic-toasts';
import {useParams} from 'react-router-dom';
import DaumPostCodeModal from '../components/common/DaumPostCode';
import moment from 'moment';
import _ from 'lodash';
import CarmonConfirm from '../components/common/Confirm';
import {BASE_STYLES} from '../Consts';

const AcceptType = {
  NONE: 'none',
  ACCEPT: 'accept',
  DENIED: 'denied',
};

const AuctionState = {
  SALES_YES: 'SY',
  SALES_NO: 'SN',
};

const CarmonSellerAccept = () => {
  const params = useParams();
  const [reservation, setReservation] = useState({});
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const [salesState, setSalesState] = useState(params.acceptType);
  const [daumPostModalOptions, setDaumPostModalOptions] = useState({
    address: '',
    addressDetail: '',
    open: false,
    callback: (address) => {
      document.getElementById('address').value = address;
      setFormData(prevState => {
        return {
          ...prevState,
          address: address,
        };
      });

      setFormError(prev => {
        return {
          ...prev,
          address: false,
        };
      });
    },
  });

  const nopeReasonOptions = [
    {key: 1, value: 1, text: '낙찰 가격이 마음에 들지 않습니다.'},
    {key: 2, value: 2, text: '당장 차량을 판매할 수 없게 되었습니다.'},
    {key: 3, value: 3, text: '팔 생각이 없었습니다.'},
    {key: 4, value: 4, text: '기타'},
  ];

  useEffect(() => {
    if (params.acceptType !== AcceptType.ACCEPT && params.acceptType !== AcceptType.DENIED) {
      setLoading(false);
      window.location = '/';
    } else {
      retrieveReservation();

      setFormError(() => {
        return {
          bankName: true,
          bankAccount: true,
          address: true,
          transportDate: true,
          transportTime: true,
        };
      });
    }
  }, []);

  useEffect(() => {
    const reserv = moment(formData.transportDate);
    if (formData.transportTime) reserv.hours(formData.transportTime).minutes(0).seconds(0);
    formData.transportDate = reserv;
  }, [formData]);

  const confirmReducer = (state, action) => {
    switch (action.type) {
      case 'confirmSubmit':
        const confirmState = Object.assign({}, {
          title: '차량 판매 확정',
          content: `차량 판매를 확정하시겠습니까?`,
          open: true,
          centered: true,
          close: () => confirmDispatch({type: 'hide'}),
          callBack: () => {
            submitSellerAccept();
          },
        });
        return confirmState;
      case 'hide':
        const hideState = Object.assign({}, {open: false});
        return hideState;
    }
  };

  const [confirmOptions, confirmDispatch] = useReducer(confirmReducer, {});

  const retrieveReservation = async () => {
    try {
      const response = await axios.get(`/apis/external/accept/${params.reservationNumber}`);
      if (response.status === 200) {
        setReservation(response.data);
        setLoading(false);
      }
    } catch (error) {
      toast({
        type: 'error',
        title: 'ERROR',
        time: 5000,
        description: error.response.data.message,
      });
    }
  };

  const submitSellerAccept = async () => {
    try {
      setLoading(true);

      const postData = _.cloneDeep(formData);
      if (salesState === AcceptType.DENIED) {
        if (postData.hasOwnProperty('reasonDetail') && _.trim(postData['reasonDetail']).length > 0) {
          postData['reason'] = formData['reasonDetail'];
        } else if (!postData.hasOwnProperty('reasonDetail') || _.trim(postData['reasonDetail']).length === 0) {
          let reason = nopeReasonOptions.filter(v => v.value === postData['reason']);
          postData['reason'] = _.isEmpty(reason) ? '' : reason[0].text;
        }
      }

      const response = await axios.post(`/apis/external/${salesState}/${params.reservationNumber}`, postData);

      if (response.status === 200) {
        setLoading(false);
        retrieveReservation();
        toast({
          type: 'success',
          title: salesState === AcceptType.ACCEPT ? '판매확정' : '확인',
          time: 2000,
          description: salesState === AcceptType.ACCEPT ? '판매가 확정되었습니다. 곧 알림톡으로 다음 절차를 안내드리겠습니다.' : '응답해주셔서 감사합니다.',
        });
      }

    } catch (error) {
      setLoading(false);
      toast({
        type: 'error',
        title: 'ERROR',
        time: 5000,
        description: error.response.data.message,
      });
    }
  };

  const eventHandler = {
    onChangeForm: (event, {name, value, required}) => {
      setFormData(prevState => {
        return {...prevState, [name]: value};
      });

      if (required) {
        setFormError(prevState => {
          return {...prevState, [name]: _.isEmpty(String(value))};
        });
      }
    },
    onClickSubmit: async () => {
      if (salesState === AcceptType.ACCEPT) {
        // const filtered = _.filter(formError, (error, name) => {
        //   return error;
        // });
        //
        // if (filtered.length > 0) {
        //   toast({
        //     type: 'warning',
        //     title: '필수 입력값을 확인해주세요.',
        //     time: 2000,
        //   });
        //
        //   return false;
        // }
        confirmDispatch({type: 'confirmSubmit'});
      } else {
        submitSellerAccept();
      }
    },
  };

  return (
    <>
      <Dimmer active={loading} page>
        <Loader size="massive">Loading</Loader>
      </Dimmer>

      <div style={{
        minHeight: '100vh',
        backgroundColor: BASE_STYLES.THEME_COLOR,
        padding: '20px 10px',
      }}>
        <Image src={'/images/carmon.png'} centered style={{width: 160, marginBottom: 10}} />
        <Segment.Group>
          <Segment><Header as="h1">차량이 낙찰되었습니다!</Header></Segment>
          <Segment>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={2}>예약번호</Table.Cell>
                  <Table.Cell><Header as="h2">{reservation.reservationNumber}</Header></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>차량번호</Table.Cell>
                  <Table.Cell><Header as="h2">{reservation.licensePlateNumber}</Header></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>낙찰가</Table.Cell>
                  <Table.Cell><Header as="h2">{Number(reservation.winBidAmount).toLocaleString()}</Header></Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            <Message positive style={{display: salesState === AcceptType.ACCEPT ? '' : 'none'}}>
              <Message.Header>축하드립니다!<br />고객님의 차량이 판매되었습니다!</Message.Header>
              <p>차량양도 절차에 대해 안내드립니다.</p>

              <List as="ol">
                <List.Item as="li">차량 판매를 확정하세요.</List.Item>
                <List.Item as="li">잠시후 차량 매수자 정보를 알림톡으로 발송해드립니다.</List.Item>
                <List.Item as="li">차량 매도용 인감증명서를 미리 준비해주세요. (매수자 정보는 위 알림톡에 포함되어있습니다.)</List.Item>
                <List.Item as="li">차량 판매금액을 입금받을 계좌번호와 탁송기사님이 방문할 장소와 시간을 정해주세요.</List.Item>
                <List.Item as="li">
                  탁송기사님께 다음 서류를 전달해주세요.
                  <List.List style={{listStyle: 'none'}}>
                    <List.Item><Icon name="right triangle" />인감증명서 (자동차매도용)</List.Item>
                    <List.Item><Icon name="right triangle" />신분증 사본 (촬영)</List.Item>
                  </List.List>
                </List.Item>
                <List.Item as="li">차량 양도확인 서명을 해주세요.</List.Item>
              </List>

              <p>계좌번호가 잘못됐거나 탁송기사 방문요청에 응하지 않으셨을 경우 입금이 어려운점 미리 양해부탁드립니다.</p>
            </Message>

            {/*<Message positive style={{display: salesState === AcceptType.ACCEPT ? '' : 'none'}}>
              <Message.Header>고객님의 차량이 판매되었습니다! 축하드립니다!</Message.Header>
              <p>차량양도 절차에 대해 안내드립니다.</p>

              <List as="ol">
                <List.Item as="li">차량 판매금액을 입금받을 계좌번호와 탁송기사님이 방문할 장소와 시간을 정해주세요.</List.Item>
                <List.Item as="li">
                  탁송기사님께 다음 서류를 전달해주세요.
                  <List.List style={{listStyle: 'none'}}>
                    <List.Item><Icon name="right triangle" />인감증명서 (자동차매도용)</List.Item>
                    <List.Item><Icon name="right triangle" />신분증 사본 (촬영)</List.Item>
                  </List.List>
                </List.Item>
                <List.Item as="li">차량 양도확인 서명을 해주세요.</List.Item>
              </List>

              <p>계좌번호가 잘못됐거나 탁송기사 방문요청에 응하지 않으셨을 경우 입금이 어려운점 미리 양해부탁드립니다.</p>
            </Message>*/}

            <Message negative style={{display: salesState === AcceptType.DENIED ? '' : 'none'}}>
              <Message.Header>차량 판매를 취소하시겠습니까?</Message.Header>
              <p>취소하시려는 사유를 알려주시면, 더 나은 서비스로 보답하겠습니다.</p>
              <p>카몬을 이용해주셔서 감사합니다.</p>
            </Message>
          </Segment>
          {/*<Transition*/}
          {/*  visible={(reservation.auctionState !== AuctionState.SALES_YES && reservation.auctionState !== AuctionState.SALES_NO) && salesState === AcceptType.ACCEPT && !loading}*/}
          {/*  animation="slide down"*/}
          {/*  duration={200}>*/}
          {/*  <Segment>*/}
          {/*    <Form size="big" id="form">*/}
          {/*      <Form.Input required*/}
          {/*                  label="은행"*/}
          {/*                  name="bankName"*/}
          {/*                  placeholder="입금 받을 은행명"*/}
          {/*                  onChange={eventHandler.onChangeForm} />*/}

          {/*      <Form.Input required*/}
          {/*                  label="계좌번호"*/}
          {/*                  name="bankAccount"*/}
          {/*                  placeholder="입금 받을 계좌번호"*/}
          {/*                  onChange={eventHandler.onChangeForm} />*/}

          {/*      <Form.Input required*/}
          {/*                  name="address"*/}
          {/*                  id="address"*/}
          {/*                  readOnly*/}
          {/*                  label="탁송기사 방문 주소"*/}
          {/*                  placeholder="탁송기사가 방문할 주소 입력"*/}
          {/*                  onChange={eventHandler.onChangeForm}*/}
          {/*                  action={*/}
          {/*                    <Button color="orange"*/}
          {/*                            icon="search"*/}
          {/*                            size="big"*/}
          {/*                            onClick={() => {*/}
          {/*                              setDaumPostModalOptions(prevState => {*/}
          {/*                                return {*/}
          {/*                                  ...prevState,*/}
          {/*                                  open: true,*/}
          {/*                                };*/}
          {/*                              });*/}
          {/*                            }}*/}
          {/*                    />*/}
          {/*                  } />*/}

          {/*      <Form.Input type="text"*/}
          {/*                  name="addressDetail"*/}
          {/*                  placeholder="나머지 주소"*/}
          {/*                  onChange={eventHandler.onChangeForm} />*/}

          {/*      <Form.Input required*/}
          {/*                  label="탁송기사 방문 요청일"*/}
          {/*                  type="date"*/}
          {/*                  name="transportDate"*/}
          {/*                  min={moment().format('YYYY-MM-DD')}*/}
          {/*                  max={moment().add(7, 'days').format('YYYY-MM-DD')}*/}
          {/*                  onChange={eventHandler.onChangeForm}*/}
          {/*                  action={*/}
          {/*                    <Dropdown button basic floating*/}
          {/*                              scrolling*/}
          {/*                              size="huge"*/}
          {/*                              placeholder="시간"*/}
          {/*                              name="transportTime"*/}
          {/*                              required*/}
          {/*                              options={[*/}
          {/*                                {key: 'am7', value: 7, text: '7:00'},*/}
          {/*                                {key: 'am8', value: 8, text: '8:00'},*/}
          {/*                                {key: 'am9', value: 9, text: '9:00'},*/}
          {/*                                {key: 'am10', value: 10, text: '10:00'},*/}
          {/*                                {key: 'am11', value: 11, text: '11:00'},*/}
          {/*                                {key: 'am12', value: 12, text: '12:00'},*/}
          {/*                                {key: 'pm1', value: 13, text: '13:00'},*/}
          {/*                                {key: 'pm2', value: 14, text: '14:00'},*/}
          {/*                                {key: 'pm3', value: 15, text: '15:00'},*/}
          {/*                                {key: 'pm4', value: 16, text: '16:00'},*/}
          {/*                                {key: 'pm5', value: 17, text: '17:00'},*/}
          {/*                                {key: 'pm6', value: 18, text: '18:00'},*/}
          {/*                                {key: 'pm7', value: 19, text: '19:00'},*/}
          {/*                                {key: 'pm8', value: 19, text: '20:00'},*/}
          {/*                                {key: 'pm9', value: 19, text: '21:00'},*/}
          {/*                                {key: 'pm10', value: 19, text: '22:00'},*/}
          {/*                              ]}*/}
          {/*                              onChange={eventHandler.onChangeForm}*/}
          {/*                    />}*/}
          {/*                  labelPosition="right"*/}
          {/*      />*/}
          {/*    </Form>*/}
          {/*  </Segment>*/}
          {/*</Transition>*/}
          <Transition
            visible={(reservation.auctionState !== AuctionState.SALES_YES && reservation.auctionState !== AuctionState.SALES_NO) && salesState === AcceptType.DENIED}
            animation="slide down"
            duration={200}>
            <Segment>
              <Form size="huge">
                <Form.Dropdown fluid options={nopeReasonOptions}
                               name="reason"
                               label="판매하지 않는 이유는 무엇입니까?"
                               placeholder="선택해주세요."
                               onChange={eventHandler.onChangeForm}
                               selection />
                <Form.TextArea
                  style={{display: (formData.reason && (formData.reason === 4 || formData.reason === '기타')) ? '' : 'none'}}
                  name="reasonDetail"
                  placeholder="여유가 되신다면, 판매하지 않는 이유를 알려주세요."
                  onChange={eventHandler.onChangeForm} />
              </Form>

            </Segment>
          </Transition>
          <Transition
            visible={(reservation.auctionState === AuctionState.SALES_YES || reservation.auctionState === AuctionState.SALES_NO)}
            animation="slide down"
            duration={200}>
            <Segment textAlign="center">
              <Header as="h2" color={reservation.auctionState === AuctionState.SALES_YES ? 'blue' : 'red'}>
                {reservation.auctionState === AuctionState.SALES_YES ?
                  '차량 판매가 확정되었습니다.'
                  :
                  '다음 사유로 판매 거부 처리되었습니다.'}
              </Header>
              <Header as="h3">
                {
                  reservation.auctionState === AuctionState.SALES_NO ? _.trim(reservation.deniedMessage) : ''
                }
              </Header>
            </Segment>
          </Transition>
          <Segment>
            <Grid stackable>
              <Grid.Column width={16}>
                <Button fluid
                        size="massive"
                        color={salesState === AcceptType.ACCEPT ? 'teal' : 'orange'}
                        icon="paper plane"
                        form="form"
                        type="submit"
                        disabled={reservation.auctionState === AuctionState.SALES_YES || reservation.auctionState === AuctionState.SALES_NO}
                        content={salesState === AcceptType.ACCEPT ? '판매확정' : '제출'}
                        onClick={eventHandler.onClickSubmit} />
              </Grid.Column>
            </Grid>
          </Segment>
          <CarmonConfirm {...confirmOptions} />
        </Segment.Group>
        <DaumPostCodeModal modalOption={daumPostModalOptions}
                           setModalOption={setDaumPostModalOptions} />

      </div>
    </>
  );
};

export default CarmonSellerAccept;
