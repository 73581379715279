import React, {useEffect, useState} from 'react';
import {Comment, Header, Menu, MenuItem, Segment, Table} from 'semantic-ui-react';

const AutoOasisDashboard = () => {
  return (
    <Segment basic>
      <Header>Carmon x AutoOasis.</Header>
    </Segment>
  );
}

export default AutoOasisDashboard
