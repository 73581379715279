export const ReservationCheckExists = {
  NEW: 'N',
  EXISTS: 'E',
};

export const ImageBaseURL = process.env.IMAGE_BASE_URL;

export const CARMON_INFO = {
  REPRESENTATIVE: '성백환',
  PERSONAL_INFO: '성백환',
  REPRESENTATIVE_TEL: '070-7576-4981',
  ADDRESS: '서울특별시 구로구 경인로 579-1, B동 308호 (신도림동, 안성빌딩)',
  POSTAL_CODE: '08212',
  BUSINESS_NUMBER: '457-87-02409',
  //SELLER_NUMBER: '제 2021-서울구로-2508호',
  CONTACT_EMAIL: 'carmonster000@gmail.com',
  BANK_NAME: '국민은행',
  BANK_ACCOUNT_OWNER: '(주)카몬스터',
  BANK_ACCOUNT_NUMBER: '981401-00-048850',
};

export const BASE_STYLES = {
  THEME_COLOR: '#00aed9',
  THEME_SUB_COLOR: '#feb948',
  THEME_ITEM_COLOR: '#f0f0f0',
  THEME_BG_COLOR: '#696969',
  FONT_COLOR: 'rgb(27, 28, 29)',
  BORDER_COLOR: 'rgba(34,36,38,.1)',
  FONT_SIZE: {
    MAX: 22,
    BASE: 18,
    SUB: 16,
    MIN: 14,
  },
};

export const PARTS = [
  [
    {
      idx: 0,
      position: 'outFront',
      position_name: '정면',
      required: true,
    },
    {
      idx: 1,
      position: 'outLeft',
      position_name: '측면 (왼쪽)',
      required: true,
      description: '차량 왼쪽 측면 전체가 보이게',
    },
    {
      idx: 2,
      position: 'outRight',
      position_name: '측면 (오른쪽)',
      required: true,
      description: '차량 오른쪽 측면 전체가 보이게',
    },
  ],
  [
    {
      idx: 3,
      position: 'outRear',
      position_name: '후방',
      required: true,
      description: '후방',
    },
    {
      idx: 4,
      position: 'outTop',
      position_name: '상판',
      required: true,
      description: '선루프가 있으면 보이게',
    },
    {
      idx: 5,
      position: 'bottom',
      position_name: '하부',
      required: true,
      description: '차량 하부',
    },
  ],
  [
    {
      idx: 6,
      position: 'inPanel',
      position_name: '계기판',
      required: true,
      description: '계기판',
    },
    {
      idx: 7,
      position: 'inSeat',
      position_name: '시트',
      required: true,
      description: '흠집이 있으면 나오게',
    },
    {
      idx: 8,
      position: 'inDriverDoor',
      position_name: '운전석 도어',
      required: true,
      description: '운전석 옵션이 드러나도록',
    },
  ],
  [
    {
      idx: 9,
      position: 'inNavigation',
      position_name: '네비게이션',
      required: true,
      description: '네비게이션',
    },
    {
      idx: 10,
      position: 'inCenterFascia',
      position_name: '센터페시아',
      required: true,
      description: '센터페시아',
    },
    {
      idx: 11,
      position: 'etc1',
      position_name: '기타1',
      required: false,
      description: '추가로 필요한 경우',
    },
  ],
  [
    {
      idx: 12,
      position: 'etc2',
      position_name: '기타2',
      required: false,
      description: '추가로 필요한 경우',
    },
    {
      idx: 13,
      position: 'etc3',
      position_name: '기타3',
      required: false,
      description: '추가로 필요한 경우',
    },
    {
      idx: 14,
      position: 'etc4',
      position_name: '기타4',
      required: false,
      description: '추가로 필요한 경우',
    },
  ],
];

export const BANK_TYPE = {
  BANK: 'BANK',
  STOCK: 'STOCK',
};