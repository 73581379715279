import React, {useCallback, useEffect, useReducer, useRef, useState} from 'react';
import {
  Button,
  Checkbox,
  Comment,
  Container, Dropdown,
  Form, Header, Icon,
  Input,
  Menu,
  MenuItem, Pagination,
  Segment,
  Select,
  Table,
} from 'semantic-ui-react';

import axios from 'axios';
import _ from 'lodash';
import {toast} from 'react-semantic-toasts';
import WindowPopup from '../../components/common/WindowPopup';
import moment from 'moment';

const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
const settlementState = [
  {key: 0, value: ' ', text: '전체'},
  {key: 1, value: 'N', text: '미정산'},
  {key: 2, value: 'Y', text: '정산완료'},
];

const AutoOasisSettlement = () => {
  const [currentActivePage, setCurrentActivePage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [pageable, setPageable] = useState({page: 0, size: 10});
  const [searchForm, setSearchForm] = useState({
    reservationFromDate:startOfMonth,
    reservationToDate:endOfMonth,
  });
  const [centerList, setCenterList] = useState([]);

  const [pcrTemporaryPopupOption, setPcrTemporaryPopupOption] = useState({open: false, reservationNumber: ''});

  const pcrTemporaryPopupOpen = useCallback((reservationNumber) => {
    setPcrTemporaryPopupOption({open: true, reservationNumber: reservationNumber});
  });

  const pcrTemporaryPopupClose = useCallback(() => {
    setPcrTemporaryPopupOption({open: false});
  });

  useEffect(() => {
    retrieveCenterList();
  }, []);

  useEffect(() => {
    retrieveReservation();
  }, [pageable.page, pageable.size]);

  const retrieveCenterList = async () => {
    const response = await axios.get('/apis/external/autooasis/center/list');

    const dropDownData = _.map(response.data, (row) => {
      return  { key: row.centerId, value: row.centerId, text: row.name };
    });

    setCenterList([{key: 0, value: 0, text: '전체'}, ...dropDownData]);
  }

  const retrieveReservation = (page) => {
    //search버튼을 통한 함수 호출시는 page param이 있음. api 중복 호출을 피하기 위한 로직
    if (_.isNumber(page) && (currentActivePage - 1) !== page) {
      setCurrentActivePage(page + 1);
      setPageable(prevState => {
        return {
          ...prevState,
          page: page
        };
      })

      return;
    }

    setLoading(true);

    let params = Object.assign({}, searchForm, pageable, { page: _.isNumber(page) ? page : pageable.page });

    axios.get('/apis/external/autooasis/settlement/list', {params: params}).then(({data}) => {
      data.content.forEach(row => {
        if (row['informationData']) row['informationData'] = JSON.parse(row.informationData);
      });

      dispatch({type: 'CHANGE_DATA', data: data});
    }).catch(error => {
      toast({
        type: "error",
        title: "ERROR",
        time: 2000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    }).finally(() => {
      setLoading(false);
    });
  }

  const exportExcel = () => {
    setLoading(true);
    axios.post(
      '/apis/external/autooasis/export/excel', searchForm,{responseType: 'blob'})
      .then(response =>{
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '카몬_정산리스트.xlsx');
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
        toast({
          type: "error",
          title: "ERROR",
          time: 2000,
          description: error.response && error.response.data && error.response.data.message || error
        });
      }).finally(() => {
        setLoading(false);
      });
  }

  const reservationReducer = (state, action) => {
    switch (action.type) {
      case 'CHANGE_SORT':
        if (state.column === action.column) {
          return {
            ...state,
            data: state.data.slice().reverse(),
            direction:
              state.direction === 'ascending' ? 'descending' : 'ascending',
          }
        }

        return {
          column: action.column,
          data: _.sortBy(state.data, [action.column]),
          direction: 'ascending',
        }
      case 'CHANGE_DATA':
        return {
          ...state,
          column: null,
          data: action.data,
          direction: null
        }
      default:
        throw new Error()
    }
  }

  const [state, dispatch] = useReducer(reservationReducer, {
    column: null,
    data: {},
    direction: null,
  })

  const {column, data, direction} = state;

  const eventHandler = {
    onChangeSearchForm: (e, {name, value}) => {
      setSearchForm(prevState => {
        return {
          ...prevState,
          [name]: value,
        }
      })
    },
  }

  const renderPage = () => {
    if(data && data.content && data.content.length > 0) {
      return _.map(data.content, (o, idx) => {
        return (
          <Table.Row key={`data_row_${idx}`}>
            <Table.Cell>{o.settlementRegDate}</Table.Cell>
            <Table.Cell>{o.reservationNumber}</Table.Cell>
            <Table.Cell>{o.reservationDate}</Table.Cell>
            <Table.Cell>{o.reservationTime} </Table.Cell>

            <Table.Cell>{o.licensePlateNumber}</Table.Cell>
            <Table.Cell>
              <Header as='h5' style={{margin: '0'}} color='grey'>
                {o.makerName}
              </Header>
              <Header as='h5' style={{margin: '0'}}>
                {o.modelName} ({o.year})
              </Header>
            </Table.Cell>

            <Table.Cell>{o.centerName}</Table.Cell>

            <Table.Cell>
              <Icon name={o.settlementYn === 'Y' ? 'check' : 'times'} color={o.settlementYn === 'Y' ? 'green' : 'red'} />
            </Table.Cell>
            <Table.Cell>{o.settlementDate || '-'}</Table.Cell>

            <Table.Cell>
              <Icon name={'file alternate outline'} color={'blue'} onClick={() => pcrTemporaryPopupOpen(o.reservationNumber)}></Icon>
            </Table.Cell>
          </Table.Row>
        );
      });
    } else {
      return (
        <Table.Cell colSpan={8} textAlign={'center'}>데이터가 없습니다.</Table.Cell>
      );
    }
  }

  return (
    <Segment basic loading={loading}>
      <Container fluid>
        <Segment attached='top'>
          <Form>
            <Form.Group widths='3'>
              <Form.Field>
                <label>정산등록일자</label>
                <Form.Group widths='equal'>
                  <Form.Field>
                    <Input size='small'
                           type='date'
                           name='reservationFromDate'
                           value={searchForm.reservationFromDate || ''}
                           onChange={eventHandler.onChangeSearchForm}/>
                  </Form.Field>
                  ~
                  <Form.Field>
                    <Input size='small'
                           type='date'
                           name='reservationToDate'
                           value={searchForm.reservationToDate || ''}
                           onChange={eventHandler.onChangeSearchForm}/>
                  </Form.Field>
                </Form.Group>
              </Form.Field>

              <Form.Field>
                <label>정비소</label>
                <Dropdown
                  name={'centerId'}
                  placeholder='정비소'
                  fluid
                  search
                  selection
                  options={centerList}
                  onChange={eventHandler.onChangeSearchForm}
                />
              </Form.Field>

              <Form.Field
                control={Input}
                name='licensePlateNumber'
                label='차량번호'
                placeholder='차량번호'
                onChange={eventHandler.onChangeSearchForm}
              />

              <Form.Field>
                <label>정산여부</label>
                <Dropdown
                  name={'settlementYn'}
                  placeholder='정산여부'
                  fluid
                  selection
                  options={settlementState}
                  onChange={eventHandler.onChangeSearchForm}
                />
              </Form.Field>
            </Form.Group>

          </Form>
        </Segment>

        <Segment attached='bottom' style={{background: `#f9fafb`}}>
          <Button  floated={'left'} color='green' onClick={exportExcel}><Icon name='file excel'/>Excel</Button>
          <Button floated={'right'} color='teal' onClick={() => retrieveReservation(0)}><Icon name='search'/>Search</Button>
          <div style={{clear:'both'}} />
        </Segment>
      </Container>

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>정산등록일</Table.HeaderCell>
            <Table.HeaderCell>예약번호</Table.HeaderCell>
            <Table.HeaderCell>예약일</Table.HeaderCell>
            <Table.HeaderCell>예약시간(24H)</Table.HeaderCell>
            <Table.HeaderCell>차량번호</Table.HeaderCell>
            <Table.HeaderCell>차종</Table.HeaderCell>
            <Table.HeaderCell>정비소</Table.HeaderCell>
            <Table.HeaderCell>정산여부</Table.HeaderCell>
            <Table.HeaderCell>정산완료일</Table.HeaderCell>
            <Table.HeaderCell>점검내용</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {renderPage()}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='10'>
              <Header as={'h4'} floated='left'>Total {data?.totalElements}건</Header>
              <Menu floated='right' secondary>
                <Dropdown header={'페이지당 목록 갯수'} selection
                          style={{minWidth:'120px', marginRight:'10px'}}
                          value={pageable.size}
                          options={[
                            {key: 10, value: 10, text: '10'},
                            {key: 15, value: 15, text: '15'},
                            {key: 20, value: 20, text: '20'},
                            {key: 30, value: 30, text: '30'},
                            {key: 50, value: 50, text: '50'}
                          ]}
                          onChange={(e, {value}) => {
                            setPageable(prevState => {
                              return {
                                ...prevState,
                                size: value
                              }
                            })
                          }}/>
                {
                  data?.totalPages &&
                  <Pagination activePage={currentActivePage}
                              totalPages={data?.totalPages}
                              siblingRange={4}
                              boundaryRange={0}
                              ellipsisItem={null}
                              onPageChange={(event, {activePage}) => {
                                setCurrentActivePage(activePage);
                                setPageable(prevState => {
                                  return {
                                    ...prevState,
                                    page: activePage - 1
                                  }
                                })
                              }
                              }/>
                }
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>

      {
        pcrTemporaryPopupOption.open && (
          <WindowPopup
            close={pcrTemporaryPopupClose}
            title={'카몬 - 정비소 차량 점검 내역'}
            width={700}
            height={800} >
            <Container text>
              <iframe src={`${process.env.EXTERNAL_BASE_URL}/external/performance-check/${pcrTemporaryPopupOption.reservationNumber}?editable=false`}
                      style={{border:0, width: '100%', height: `calc(100vh - 60px)`}} scrolling={'no'} frameBorder={0}/>
            </Container>

          </WindowPopup>
        )}
    </Segment>
  );
}

export default AutoOasisSettlement
