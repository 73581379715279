import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Container, Dimmer, Header, Icon, Segment} from 'semantic-ui-react';
import {SemanticToastContainer} from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import './configs/axios_config';

import CarmonUploadPicture from './views/UploadPicture';
import CarmonSellerAccept from './views/SellerAccept';
import CarmonDealerDeposit from './views/DealerDeposit';
import CarmonDealerCertification from './views/DealerCertification';
import CarmonSellerContract from './views/SellerContract';
import CarmonAuctionProgress from './views/AuctionProgress';
import CarmonAuctionRetry from './views/AuctionRetry';
import CarmonPerformanceReport from './views/PerformanceReport';
import SellerContractDocuments from './views/SellerContractDocuments';
import DealerBuyerInformation from './views/DealerBuyerInformation';
import SellerInformation from './views/SellerInformation';
import CarmonCenterRegistration from './views/center/CenterRegistration';
import CarPerformanceCheck from './views/CarPerformanceCheck';
import AutoOasisMain from './views/AutoOasis/AutoOasisMain';

function App() {
  return (
    <>
      <div
        id={'wrapper'}
        style={{
          position: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          overflowX: 'hidden',
          flex: '1',
        }}>
        <Segment basic style={{flex: 1, overflowY: 'auto', marginBottom: 0, padding: 0}}>
          <Router>
            <Switch>
              <Route exact path="/autooasis" component={AutoOasisMain} /> {/* 오토 오아시스 정산페이지 */}
              <Route path="/pages/center/registration" component={CarmonCenterRegistration} /> {/* 정비소 개별 서비스 가입 페이지 */}
              <Route path="/pages/dealer/submission" component={CarmonDealerCertification} /> {/*딜러 가입 서류 제출*/}
              <Route path="/pages/dealer/buyer-information" component={DealerBuyerInformation} /> {/*매도 인감 발급을 위한 매수자 정보 조회*/}
              <Route path="/pages/seller/seller-information" component={SellerInformation} /> {/*판매자 탁송/입금정보 입력*/}
              <Route path="/pages/seller/contract" component={SellerContractDocuments} /> {/*판매자 계약서 조회*/}
              <Route path="/external/image/:reservationNumber" component={CarmonUploadPicture} />
              <Route path="/external/performance-check/:reservationNumber" component={CarPerformanceCheck} />
              <Route exact path="/external/deposit/:reservationNumber" component={CarmonDealerDeposit} />
              <Route exact path="/external/contract/:reservationNumber" component={CarmonSellerContract} />
              <Route exact path="/external/report/:reservationNumber" component={CarmonPerformanceReport} />
              <Route exact path="/external/auction/progress/:reservationNumber" component={CarmonAuctionProgress} />
              <Route exact path="/external/auction/retry/:reservationNumber" component={CarmonAuctionRetry} />
              <Route path="/external/:acceptType/:reservationNumber" component={CarmonSellerAccept} /> {/*판매여부 확정/거부*/}
              <Route render={() => {
                return (
                  <Dimmer active={true}>
                    <Icon name="ban" color="red" size="huge" />
                    <Header as="h3" inverted>잘못된 접근입니다.</Header>
                  </Dimmer>
                );
              }} />
            </Switch>
          </Router>
        </Segment>
        {/*<Segment basic inverted style={{background: '#000', flex: '0 0 auto', marginTop: 0}}>
          <Container>
            상호명 : 카몬 <br />
            대표자 : 성백환 | 개인정보관리책임자 : 성백환<br />
            사업자등록번호 : 369-11-01281 | 통신판매업신고 : 제 2021-서울구로-2508호<br />
            사업장소재지 : 서울특별시 구로구 경인로 579, 235호 (신도림동, 안성빌딩)<br />
            사업제휴문의 : contact@carmon.in<br />
            Copyright © 카몬 All Rights Reserved
          </Container>
        </Segment>
        */}
      </div>
      <SemanticToastContainer position="top-center" />
    </>
  );
}

export default App;
