import React, {useContext, useEffect, useState} from 'react';
import {Button, Container, Form, Grid, Header, HeaderSubheader, Input, Segment} from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';
import {doLogin, isLogin, login} from '../../reducers/AutoOasisAuthReducer';
import axios from 'axios';
import {toast} from 'react-semantic-toasts';

const AutoOasisLogin = () => {
  const [loginForm, setLoginForm] = useState({userId: '', password: ''});
  const dispatch = useDispatch();

  const eventHandler = {
    doLogin: () => {
      axios.post('/apis/external/autooasis/manager/login', loginForm)
      .then(response => {
          if (response.status === 200) {
            dispatch(isLogin(true));
          }
      }).catch(error => {
        dispatch(isLogin(false));
        toast({
          type: 'error',
          title: 'ERROR',
          time: 2000,
          description: error.response.status === 401 ? "아이디 패스워드를 확인해주세요." : error.response.data,
        });
      });
    },
    changeForm: (e, {name, value}) => {
      setLoginForm(prevState => {
        return {
          ...prevState,
          [name]: value,
        }
      })
    }
  }

  return (
    <Segment basic style={{background:'#78bc29'}}>
      <Container text>
        <Grid style={{height: '100vh'}} centered={true} verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column style={{maxWidth: '450px'}}>
              <Header centered as={'h1'} size={'huge'} inverted style={{textAlign:'center', marginTop: '-15vh'}}>
                Auto Oasis
                <Header.Subheader>by Carmon</Header.Subheader>
              </Header>

              <Segment>
                <Form>
                  <Form.Field>
                    <label>ID</label>
                    <Input icon={'user'} iconPosition={'left'}
                           name={'userId'}
                           onChange={eventHandler.changeForm}/>
                  </Form.Field>
                  <Form.Field>
                    <label>Password</label>
                    <Input icon={'lock'} iconPosition={'left'}
                           type={'password'}
                           name={'password'}
                           onChange={eventHandler.changeForm}/>
                  </Form.Field>
                  <Button fluid color={'green'} onClick={eventHandler.doLogin}>Login</Button>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>

  )
}

export default AutoOasisLogin;
