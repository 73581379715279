import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import AutoOasisLogin from './AutoOasisLogin';
import AutoOasisSettlement from './AutoOasisSettlement';
import {useSelector} from 'react-redux';
import AutoOasisLayout from './AutoOasisLayout';

const AutoOasisMain = () => {
  const {isLogined} = useSelector(state => state.login)

  return (
    <>
      { !isLogined ? <AutoOasisLogin /> : <AutoOasisLayout /> }
    </>
);
}

export default AutoOasisMain;
