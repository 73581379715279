import React, {useEffect, useReducer, useRef, useState} from 'react';
import {
  Button,
  Dimmer,
  Divider,
  Dropdown,
  Form,
  Header,
  Icon,
  Image,
  List,
  Loader,
  Message, Modal,
  Segment,
  Table,
} from 'semantic-ui-react';
import axios from 'axios';
import DaumPostCodeModal from '../components/common/DaumPostCode';
import moment from 'moment';
import _ from 'lodash';
import CarmonConfirm from '../components/common/Confirm';
import {BANK_TYPE, BASE_STYLES} from '../Consts';
import queryString from 'query-string';
import {CommonUtil} from '../utils/CommonUtil';
import {toast} from 'react-semantic-toasts';

export default function SellerInformation({match, location, history}) {
  const {erra} = queryString.parse(location.search);

  const [loading, setLoading] = useState(true);
  const [isConfirmOpened, setIsConfirmOpened] = useState(false);

  const [reservation, setReservation] = useState({isDone: 'N'});
  const [error, setError] = useState(null);
  const [confirmError, setConfirmError] = useState(null);

  const [buyerInfo, setBuyerInfo] = useState({});
  const [buyerInfoError, setBuyerInfoError] = useState(null);
  const [isBuyerInfoLoading, setIsBuyerInfoLoading] = useState(false);

  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});

  const bankData = useRef([]);
  const [bankOptions, setBankOptions] = useState([]);

  const [daumPostModalOptions, setDaumPostModalOptions] = useState({
    address: '',
    addressDetail: '',
    open: false,
    callback: (address) => {
      document.getElementById('address').value = address;
      setFormData(prevState => {
        return {
          ...prevState,
          address: address,
        };
      });

      setFormError(prev => {
        return {
          ...prev,
          address: false,
        };
      });
    },
  });

  const getReservation = () => {
    setLoading(true);

    axios.get(`/apis/seller/seller-information?erra=${erra}`)
      .then(response => setReservation(response.data))
      .catch(error => setError(error.response.data))
      .finally(() => setLoading(false));
  };

  const getBuyerInfo = () => {
    setIsBuyerInfoLoading(true);

    axios.get(`/apis/seller/buyer-information?aid=${reservation.auctionId}`)
      .then(response => setBuyerInfo(response.data))
      .catch(error => setBuyerInfoError(error.response.data))
      .finally(() => setIsBuyerInfoLoading(false));
  };

  const getBankData = () => {
    Object.entries(BANK_TYPE).map(([value]) => {
      retrieveCommonCode(value, value === BANK_TYPE.BANK ? 1 : 2);
    });
  }

  const retrieveCommonCode = async (codeGroup, order) => {
    try {
      const response = await axios.get(`/apis/common/code_list/${codeGroup}`);
      if (response.status === 200) {
        let optionData = [];

        response.data.map(row => {
          optionData.push({key: row.code, text: row.name, value: row.code, order: order});
        })

        bankData.current = [...bankData.current, ...optionData];
      }
    } catch (error) {
      toast({
        type: "error",
        title: "ERROR",
        time: 2000,
        description: error.response && error.response.data && error.response.data.message || error
      });
    }
  }

  const submitSellerAccept = async () => {
    try {
      setLoading(true);
      setConfirmError(null);

      const postData = _.cloneDeep(formData);
      postData.erra = erra;

      const response = await axios.post(`/apis/seller/seller-information`, postData);
      if (response.status === 200) {
        setIsConfirmOpened(false);
        getReservation();
      }
    } catch (error) {
      setConfirmError(error.response.data);
    } finally {
      setLoading(false);
    }
  };

  const confirmReducer = (state, action) => {
    switch (action.type) {
      case 'confirmSubmit':
        return Object.assign({}, {
          title: '차량 판매 확정',
          content: `차량 판매를 확정하시겠습니까?`,
          open: true,
          centered: true,
          close: () => confirmDispatch({type: 'hide'}),
          callBack: async () => await submitSellerAccept(),
        });
      case 'hide':
        return Object.assign({}, {open: false});
    }
  };

  const [confirmOptions, confirmDispatch] = useReducer(confirmReducer, {});
  const eventHandler = {
    onChangeForm: (event, {name, value, required}) => {
      setFormData(prevState => {
        return {...prevState, [name]: value};
      });

      if (required) {
        setFormError(prevState => {
          return {...prevState, [name]: _.isEmpty(String(value))};
        });
      }
    },
    onClickSubmit: async () => submitSellerAccept(),
  };

  useEffect(() => {
    setFormError(() => {
      return {
        bankName: true,
        bankAccount: true,
        address: true,
        transportDate: true,
        transportTime: true,
      };
    });

    getReservation();
    getBankData();
  }, []);

  useEffect(() => {
    const reserv = moment(formData.transportDate);
    if (formData.transportTime) reserv.hours(formData.transportTime).minutes(0).seconds(0);
    formData.transportDate = reserv;
  }, [formData]);

  useEffect(() => {
    setBankOptions(bankData.current.sort((o1, o2) => {
      return (o1.order >= o2.order && o1.key > o2.key) ? 1 : -1;
    }));
  },[bankData.current])

  return (
    <>
      <Dimmer active={loading} page>
        <Loader size="massive">Loading...</Loader>
      </Dimmer>

      <div style={{
        minHeight: '100vh',
        backgroundColor: BASE_STYLES.THEME_COLOR,
        padding: '20px 10px',
      }}>
        <Image src={'/images/carmon.png'} centered style={{width: 160, marginBottom: (erra ? 10 : 0)}} />
        {
          error
            ?
            (
              <Message error>
                <Message.Header>
                  <Icon name={'exclamation triangle'} />
                  {error ? error.message : '잘못된 요청입니다.'}
                </Message.Header>
              </Message>
            )
            :
            (
              <>
                <Message info>
                  <Message.Header>축하드립니다!<br />고객님의 차량이 판매되었습니다!</Message.Header>
                  <p>나머지 차량 양도 절차에 대해 안내드립니다.</p>

                  <Divider horizontal>
                    <span style={{fontSize: BASE_STYLES.FONT_SIZE.BASE}}>탁송기사 방문 전</span>
                  </Divider>
                  <Message.Content>
                    <List as="ol">
                      <List.Item as="li">먼저, <b>[차량 매수자 정보]</b>를 반드시 확인해주세요.</List.Item>
                      <List.Item as="li">확인하신 매수자 정보로 <b>[차량 매도용 인감증명서]</b>를 준비해주세요.</List.Item>
                      <List.Item as="li">
                        차량 판매금액을 <b>[입금계좌정보]</b>와 탁송기사님이 <b>[방문할 장소와 시간]</b>을 지정해주세요.
                        <div style={{margin: '4px 4px 0px 4px'}}>
                          <List
                            bulleted
                            style={{
                              // backgroundColor: BASE_STYLES.THEME_COLOR,
                              // color: BASE_STYLES.THEME_ITEM_COLOR,
                              fontWeight: 'bold',
                              padding: '2px 8px',
                              borderRadius: 4,
                            }}>
                            <List.Item>현재 인감증명서는 온라인으로 발급 받을 수 없습니다.</List.Item>
                            <List.Item>인감증명서를 준비하실 수 있는 시간을 확인하시고 방문시간을 지정해주세요.</List.Item>
                            <List.Item>입금계좌는 차량 소유주가 보유한 계좌만 가능합니다.</List.Item>
                          </List>
                        </div>
                      </List.Item>
                    </List>
                  </Message.Content>
                  <Divider horizontal>
                    <span style={{fontSize: BASE_STYLES.FONT_SIZE.BASE}}>탁송기사 방문 후</span>
                  </Divider>
                  <Message.Content>
                    <List as="ol">
                      <List.Item as="li">
                        탁송기사님께 다음 서류를 전달해주세요.
                        <div style={{margin: '4px 4px 0px 4px'}}>
                          <List
                            bulleted
                            style={{
                              fontWeight: 'bold',
                              padding: '2px 8px',
                              borderRadius: 4,
                            }}>
                            <List.Item>자동차 매도용 인감증명서</List.Item>
                            <List.Item>차량 소유자 신분증</List.Item>
                          </List>
                        </div>
                      </List.Item>
                      <List.Item as="li">차량 양도확인 서명을 해주세요.</List.Item>
                    </List>
                  </Message.Content>
                </Message>

                <Segment>
                  <Header dividing>
                    <Icon name="list alternate outline" />
                    예약 및 경매 정보
                  </Header>
                  <Table unstackable definition compact fixed>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={5}>예약번호</Table.Cell>
                        <Table.Cell>{reservation.reservationNumber}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>차량번호</Table.Cell>
                        <Table.Cell>{reservation.licensePlateNumber}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>낙찰가</Table.Cell>
                        <Table.Cell>{CommonUtil.Unit.format(reservation.winBidAmount)} 원</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Segment>

                {reservation.isDone === 'N' && <Segment>
                  <Header dividing>
                    <Icon name="user outline" />
                    매수자 정보
                  </Header>
                  {
                    _.isEmpty(buyerInfo)
                      ?
                      (<Button primary fluid icon={'check'} size={'large'} content={'매수자 정보 확인하기'}
                               onClick={() => getBuyerInfo()}
                               loading={isBuyerInfoLoading} />)
                      :
                      (<Table unstackable definition compact fixed>
                        <Table.Body>
                          <Table.Row>
                            {/*<Table.Cell width={5}>{buyerInfo.type === '1' ? '성명' : '법인명'}</Table.Cell>*/}
                            <Table.Cell width={5}>성명(법인명)</Table.Cell>
                            <Table.Cell>{buyerInfo.name}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            {/*<Table.Cell>{buyerInfo.type === '1' ? '주민등록번호' : '법인등록번호'}</Table.Cell>*/}
                            <Table.Cell>주민등록번호<br />(법인등록번호)</Table.Cell>
                            <Table.Cell>{buyerInfo.registrationNumber}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>주소<br />(법인소재지)</Table.Cell>
                            <Table.Cell>{buyerInfo.address} {buyerInfo.addressDetail}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>발송일시</Table.Cell>
                            <Table.Cell>{moment(buyerInfo.sendTime).format('YYYY-MM-DD HH:mm')}</Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>)
                  }
                </Segment>}

                {
                  reservation.isDone === 'N'
                    ?
                    (
                      <>
                        <Segment attached={'top'}>
                          <Header dividing>
                            <Icon name="credit card outline" />
                            입금 및 탁송정보 등록
                          </Header>
                          <Form id="form" size={'large'}>
                            {/*<Form.Input required*/}
                            {/*            label="은행"*/}
                            {/*            name="bankName"*/}
                            {/*            placeholder="입금 받을 은행명"*/}
                            {/*            onChange={eventHandler.onChangeForm} />*/}
                            <Form.Select fluid
                                         search
                                         required
                                         options={bankOptions}
                                         name='bankName'
                                         label='은행명'
                                         placeholder='은행명'
                                         onChange={eventHandler.onChangeForm} />

                            <Form.Input required
                                        label="계좌번호"
                                        name="bankAccount"
                                        placeholder="입금 받을 계좌번호"
                                        onChange={eventHandler.onChangeForm} />

                            <Form.Input required
                                        name="address"
                                        id="address"
                                        readOnly
                                        label="탁송기사 방문 주소"
                                        placeholder="탁송기사가 방문할 주소 입력"
                                        onChange={eventHandler.onChangeForm}
                                        action={
                                          <Button color="orange"
                                                  icon="search"
                                                  onClick={() => {
                                                    setDaumPostModalOptions(prevState => {
                                                      return {
                                                        ...prevState,
                                                        open: true,
                                                      };
                                                    });
                                                  }}
                                          />
                                        } />

                            <Form.Input type="text"
                                        name="addressDetail"
                                        placeholder="나머지 주소"
                                        onChange={eventHandler.onChangeForm} />

                            <Form.Input required
                                        label="탁송기사 방문 요청일"
                                        type="date"
                                        name="transportDate"
                                        min={moment().add(1, 'days').format('YYYY-MM-DD')}
                                        max={moment().add(7, 'days').format('YYYY-MM-DD')}
                                        onChange={eventHandler.onChangeForm}
                                        action={
                                          <Dropdown button basic floating
                                                    scrolling
                                                    placeholder="시간"
                                                    name="transportTime"
                                                    required
                                                    options={[
                                                      {key: 'am7', value: 7, text: '7:00'},
                                                      {key: 'am8', value: 8, text: '8:00'},
                                                      {key: 'am9', value: 9, text: '9:00'},
                                                      {key: 'am10', value: 10, text: '10:00'},
                                                      {key: 'am11', value: 11, text: '11:00'},
                                                      {key: 'am12', value: 12, text: '12:00'},
                                                      {key: 'pm1', value: 13, text: '13:00'},
                                                      {key: 'pm2', value: 14, text: '14:00'},
                                                      {key: 'pm3', value: 15, text: '15:00'},
                                                      {key: 'pm4', value: 16, text: '16:00'},
                                                      {key: 'pm5', value: 17, text: '17:00'},
                                                      {key: 'pm6', value: 18, text: '18:00'},
                                                      {key: 'pm7', value: 19, text: '19:00'},
                                                      {key: 'pm8', value: 19, text: '20:00'},
                                                      {key: 'pm9', value: 19, text: '21:00'},
                                                      {key: 'pm10', value: 19, text: '22:00'},
                                                    ]}
                                                    onChange={eventHandler.onChangeForm}
                                          />}
                                        labelPosition="right"
                            />
                          </Form>
                        </Segment>
                        <Segment attached={'bottom'}>
                          <Message error hidden={confirmError === null}>
                            <Message.Header>
                              <Icon name={'exclamation triangle'} /> 확인해주세요!
                            </Message.Header>
                            <List as="ul">
                              {_.map(confirmError?.details, detail => <List.Item as="li">{detail}</List.Item>)}
                            </List>
                          </Message>

                          <Button fluid
                                  color={'teal'}
                                  form="form"
                                  type="submit"
                                  size={'large'}
                                  content={
                                    _.isEmpty(buyerInfo)
                                      ?
                                      <><Icon name={'exclamation triangle'} /> 매수자 정보를 먼저 확인해주시기 바랍니다.</>
                                      :
                                      <><Icon name={'paper plane outline'} /> 제출하기</>
                                  }
                                  disabled={_.isEmpty(buyerInfo)}
                                  onClick={() => {
                                    console.log(_.values(formError));
                                    if (!_.every(_.values(formError), i => (i === false))) {
                                      return false;
                                    }
                                    setIsConfirmOpened(true);
                                  }} />
                        </Segment>

                        <CarmonConfirm {...confirmOptions} />
                        <DaumPostCodeModal modalOption={daumPostModalOptions}
                                           setModalOption={setDaumPostModalOptions} />
                        <Modal
                          onClose={() => setIsConfirmOpened(false)}
                          onOpen={() => setIsConfirmOpened(true)}
                          open={isConfirmOpened}>
                          <Modal.Header>꼭 확인하세요!</Modal.Header>
                          <Modal.Content>
                            <Modal.Description>
                              <p>입금정보 및 방문정보는 다음과 같습니다.</p>
                              <Table unstackable definition compact fixed>
                                <Table.Body>
                                  <Table.Row>
                                    <Table.Cell>입금은행</Table.Cell>
                                    <Table.Cell>{formData.bankName}</Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Cell>입금계좌</Table.Cell>
                                    <Table.Cell>{formData.bankAccount}</Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Cell>예금주</Table.Cell>
                                    <Table.Cell>{reservation.sellerBankAccountName}</Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Cell>탁송요청일시</Table.Cell>
                                    <Table.Cell>{moment(formData.transportDate).format('YYYY년 MM월 DD일 HH:mm')}</Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Cell>탁송주소</Table.Cell>
                                    <Table.Cell>{formData.address} {formData.addressDetail}</Table.Cell>
                                  </Table.Row>
                                </Table.Body>
                              </Table>
                              <p>등록하시겠습니까?</p>
                            </Modal.Description>
                          </Modal.Content>
                          <Modal.Actions>
                            <Button onClick={() => setIsConfirmOpened(false)} content={'취소'} />
                            <Button
                              primary
                              content="등록하기"
                              icon="send"
                              onClick={() => eventHandler.onClickSubmit()}
                            />
                          </Modal.Actions>
                        </Modal>
                      </>
                    )
                    :
                    (
                      <>
                        <Segment attached={'top'}>
                          <Header dividing>
                            <Icon name="user outline" />
                            매수자 정보
                          </Header>
                          <Table unstackable definition compact fixed>
                            <Table.Body>
                              <Table.Row>
                                {/*<Table.Cell width={5}>{reservation.buyerType === '1' ? '성명' : '법인명'}</Table.Cell>*/}
                                <Table.Cell width={5}>성명(법인명)</Table.Cell>
                                <Table.Cell>{reservation.buyerName}</Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                {/*<Table.Cell>{reservation.buyerType === '1' ? '주민등록번호' : '법인등록번호'}</Table.Cell>*/}
                                <Table.Cell>주민등록번호<br />(법인등록번호)</Table.Cell>
                                <Table.Cell>{reservation.buyerRegistrationNumber}</Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell>주소<br />(법인소재지)</Table.Cell>
                                <Table.Cell>{reservation.buyerAddress} {reservation.buyerAddressDetail}</Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell>발송일시</Table.Cell>
                                <Table.Cell>{moment(reservation.sendTime).format('YYYY-MM-DD HH:mm')}</Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell>최종확인일시</Table.Cell>
                                <Table.Cell>{moment(reservation.latestSellerCheckTime).format('YYYY-MM-DD HH:mm')}</Table.Cell>
                              </Table.Row>
                            </Table.Body>
                          </Table>
                          <Header dividing>
                            <Icon name="credit card outline" />
                            입금 및 탁송정보
                          </Header>
                          <Table unstackable definition compact fixed>
                            <Table.Body>
                              <Table.Row>
                                <Table.Cell width={5}>입금은행</Table.Cell>
                                <Table.Cell>
                                  {
                                    bankData.current.filter(v => v.value === reservation.sellerBankName)[0].text
                                  }
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell>입금계좌</Table.Cell>
                                <Table.Cell>{reservation.sellerBankAccount}</Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell>예금주</Table.Cell>
                                <Table.Cell>{reservation.sellerBankAccountName}</Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell>탁송요청일시</Table.Cell>
                                <Table.Cell>{moment(reservation.sellerContactTime).format('YYYY년 MM월 DD일 HH:mm')}</Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell>탁송주소</Table.Cell>
                                <Table.Cell>{reservation.sellerAddress}</Table.Cell>
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        </Segment>
                      </>
                    )
                }

                <Message warning>
                  <Message.Content>
                    <Icon name={'exclamation triangle'} /> 탁송기사 방문 요청에 응하지 않으시거나 계좌 정보에 문제가 있을 경우 차량 판매가 원활히 진행되지 않을 수
                    있습니다.
                  </Message.Content>
                </Message>
              </>
            )}
      </div>
    </>
  );
}
