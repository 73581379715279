import React, {useCallback, useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  Header,
  Icon, Input,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react';
import _ from 'lodash';
import axios from 'axios';
import {toast} from 'react-semantic-toasts';
import queryString from 'query-string';
import ImageControl from '../components/ImageControl';

const WIRE_FRAME_URL = '/images/wireframe/image.png';

function DealerCertification({match, location, history}) {
  const query = queryString.parse(location.search);

  const [loading, setLoading] = useState(false);
  const [certificationInfo, setCertificationInfo] = useState({});
  const [images, setImages] = useState({});

  const getImageUrl = () => WIRE_FRAME_URL;
  const removeImage = (position) => {
    setImages(prevState => {
      delete prevState[position];

      return {
        ...prevState,
      };
    });
  };

  const getDealerCertification = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`/apis/dealer/certification?rdk=${query.rdk}`);
      if (response.status === 200) {
        setCertificationInfo(response.data);
        setImages({});
      }
    } catch (ex) {
      toast({
        type: 'error',
        title: 'ERROR',
        time: 2000,
        description: ex.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const imageSelectCallback = (position, image) => {
    setImages(prevState => ({...prevState, [position]: image}));
  };

  const uploadImages = async () => {
    //if (!checkValidation()) return false;
    setLoading(true);

    const formData = new FormData();
    formData.append('certificationId', certificationInfo['certificationId']);
    formData.append('certificationCode', certificationInfo['certificationCode']);
    _.forEach(images, (image, position) => formData.append(position, image));

    try {
      const response = await axios.post('/apis/dealer/certification/submission',
        formData,
        {
          headers: {'content-type': 'multipart/form-data'},
          timeout: 5 * 60 * 1000,
        });
      if (response.status === 200) {
        await getDealerCertification();

        toast({
          type: 'success',
          title: '이미지등록완료',
          time: 2000,
          description: '이미지가 정상적으로 등록되었습니다.',
        });
      }
    } catch (ex) {
      console.error(ex.response.data);
      toast({
        type: 'error',
        title: '이미지등록실패',
        time: 2000,
        description: '이미지 업로드에 실패했습니다.',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => getDealerCertification())();
  }, []);

  return (
    <Segment basic loading={loading} style={{padding: '0'}} size={'large'}>
      <Container>
        <Segment.Group>
          <Segment color="teal">
            <Table definition>
              <Table.Body>
                <Table.Row verticalAlign={'middle'}>
                  <Table.Cell width={3}>소속 | 직책 | 이름</Table.Cell>
                  <Table.Cell>
                    <Header as="h3">
                      {certificationInfo['companyName']} | {certificationInfo['positionTitle']} | {certificationInfo['name']}
                    </Header>
                  </Table.Cell>
                </Table.Row>
                <Table.Row verticalAlign={'middle'}>
                  <Table.Cell>연락처 | 종사원번호</Table.Cell>
                  <Table.Cell>
                    <Header as="h3">
                      {certificationInfo['contactNumber']} | {certificationInfo['employeeNumber']}
                    </Header>
                  </Table.Cell>
                </Table.Row>
                <Table.Row verticalAlign={'middle'}>
                  <Table.Cell>인증요청번호</Table.Cell>
                  <Table.Cell>
                    <Header as="h3">{certificationInfo['certificationCode']}</Header>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
          <Segment>
            <Grid stackable={true}>
              <Grid.Row columns={3} textAlign={'center'}>
                <Grid.Column>
                  <Header as={'h5'}>종사원증</Header>
                  <ImageControl position={'employeeIdCard'}
                                currentImageUrl={getImageUrl('employeeIdCard')}
                                removeImage={removeImage}
                                callback={imageSelectCallback} />
                </Grid.Column>
                <Grid.Column>
                  <Header as={'h5'}>사업자등록증</Header>
                  <ImageControl position={'businessRegistration'}
                                currentImageUrl={getImageUrl('businessRegistration')}
                                removeImage={removeImage}
                                callback={imageSelectCallback} />
                </Grid.Column>
                <Grid.Column>
                  <Header as={'h5'}>매수자정보(매도용인감증명서용)</Header>
                  <ImageControl position={'buyerCertificateInfo'}
                                currentImageUrl={getImageUrl('buyerCertificateInfo')}
                                removeImage={removeImage}
                                callback={imageSelectCallback} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment>
            <Button fluid size="huge" color="teal" onClick={uploadImages}>
              <Icon name="camera" /> 사진 업로드
            </Button>
          </Segment>
        </Segment.Group>
      </Container>
    </Segment>
  );
}

export default DealerCertification;
