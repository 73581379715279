import React, {useEffect, useState} from 'react';
import {
  Card,
  Container,
  Dimmer,
  Divider,
  Grid,
  Header,
  Icon, Image,
  Label, List,
  Loader,
  Segment, SegmentGroup,
  Statistic,
} from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import axios from 'axios';
import {toast} from 'react-semantic-toasts';
import {useParams} from 'react-router-dom';

const CarmonPerformanceReport = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [reservation, setReservation] = useState({});

  useEffect(() => {
    retrieveReservation();
  }, []);

  const retrieveReservation = async () => {
    try {
      const response = await axios.get(`/apis/external/pcr-report/${params.reservationNumber}`);
      if (response.status === 200) {
        setReservation(response.data);
        setLoading(false);
      }
    } catch (error) {
      toast({
        type: 'error',
        title: 'ERROR',
        time: 5000,
        description: error.response.data.message,
      });
    }
  };

  const getSpecialStateDetail = () => {
    const render = [];

    if (reservation.isFlood) {
      render.push(<Label size='large' color='blue'><Icon name='theme' size='small' />침수</Label>);
    }


    if (reservation.isFire) {
      render.push(<Label size='large' color='red'><Icon name='hotjar' size='small' />화재</Label>);
    }

    return render;
  }

  return (
    <>
      <Segment fluid basic style={{margin:0, background: 'rgb(0, 174, 217)'}}>
        <Container text>
          <Dimmer page active={loading}>
            <Loader size="massive" />
          </Dimmer>
          <div style={{color:'#fff', fontSize:'4rem', lineHeight:'3.5rem', fontFamily: ['Pretendard-Black'], margin: '4vh 0'}}>
            <span style={{color:'rgb(251, 182, 73)', fontFamily: ['Pretendard-Black']}}>카몬</span><br/>
            REPORT
          </div>
          <Segment basic style={{background:'#fff', boxShadow: '1px 1px 5px #00000066'}}>
            <Grid divided='vertically'>
              <Grid.Row verticalAlign='middle'>
                <Grid.Column width={16} style={{margin:0}}>
                  <Header as='h2' style={{fontFamily:['Pretendard-Regular'], color:'rgb(0, 174, 217)', marginBottom:0, textAlign:'center'}}>{reservation.makerName} {reservation.name}</Header>
                  <Header as='h5' style={{fontFamily:['Pretendard-Regular'], color:"#888", marginTop:0, textAlign:'center'}}>{reservation.fuel} | {reservation.gearBox} | {reservation.carGubun} | {reservation.color}</Header>
                </Grid.Column>
                <Grid.Column width={16} style={{margin:0}} textAlign='center'>
                  <Header as='h5' style={{fontFamily:['Pretendard-Regular']}}>점검일자: {moment(reservation.checkDate).format('YYYY년 MM월 DD일')}</Header>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row divided style={{padding:0}}>
                {/*<Grid.Column width={8}>
                  <Header as='h4' style={{color:'#888', marginBottom:0}}><strong>구매시세</strong></Header>
                  <div style={{fontSize:'1.5rem'}}>{_.toNumber(reservation.usedPrice / 10000).toLocaleString()}만원</div>
                </Grid.Column>*/}
                <Grid.Column width={8}>
                  <Header as='h4' style={{color:'#888', marginBottom:0}}><strong>세부 모델명</strong></Header>
                  <div style={{fontSize:'1.2rem'}}>
                    {reservation.seriesName}<br/>
                    <Header as='h5' color='grey' style={{margin:0}}>{reservation.seriesName1} | {reservation.seriesName2}</Header>
                  </div>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as='h4' style={{color:'#888', marginBottom:0}}><strong>연식</strong></Header>
                  <div style={{fontSize:'1.5rem'}}>{reservation.year} 년</div>
                  <Header as='h5' color='grey' style={{margin:0}}>
                    최초등록: {moment(reservation.firstDate).format('YYYY.MM.DD')}
                  </Header>

                </Grid.Column>
              </Grid.Row>

              <Grid.Row divided style={{padding:0}}>
                <Grid.Column width={8}>
                  <Header as='h4' style={{color:'#888', marginBottom:0}}><strong>총 주행거리</strong></Header>
                  <div style={{fontSize:'1.5rem'}}>{(reservation.mileage / 10000).toFixed(1)}만km</div>

                  <Header as='h5' color='grey' style={{margin:0}}>
                    연 {(reservation.avgMileage * 12 / 10000).toFixed(1)}만km 주행
                  </Header>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as='h4' style={{color:'#888', marginBottom:0}}><strong>주행거리 평가</strong></Header>
                  {
                    reservation.avgMileage * 12 <= 15000 ?
                      <div style={{margin:0, color:'#2185d0', fontSize:'1.5rem'}}>평균 이하 주행</div>
                      :
                      reservation.avgMileage * 12 < 20000 ?
                        <div style={{margin:0, color:'#21ba45', fontSize:'1.5rem'}}>평균 주행</div>
                        :
                        <div style={{margin:0, color:'#f2711c', fontSize:'1.5rem'}}>평균 이상 주행</div>
                  }
                </Grid.Column>
              </Grid.Row>

              <Grid.Row verticalAlign='middle' style={{padding:0}}>
                <Grid.Column width={10}>
                  <Header as='h4' style={{color:'#888', marginBottom:0}}><strong>성능점검장</strong></Header>
                  <div style={{fontSize:'1.5rem'}}>
                    {reservation.centerName}
                  </div>
                </Grid.Column>
                <Grid.Column width={6}>
                  <div style={{position:'relative', float:'right'}}>
                    (사)한국자동차기술인협회
                    <Image src='/images/kaat-stamp.png' size='tiny' style={{width:50, position:'absolute', top:-15,right:5}}/>
                  </div>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row style={{padding:0}}>
                <Grid.Column>
                  <Header as='h4' style={{color:'#888', marginBottom:0}}><strong>점검자 종합의견</strong></Header>
                  <div style={{fontSize:'1.5rem'}}>{_.isEmpty(reservation.description) ? '의견 없음' : reservation.description}</div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </Segment>

      <Segment fluid basic style={{margin:0, paddingTop:0, minHeight:'100vh', background: 'rgb(0, 174, 217)'}}>
        <Container text>
          <Segment.Group style={{borderRadius:0, boxShadow: '1px 1px 5px #00000066'}}>
            <Segment style={{borderRadius:0, background:'#fff'}}>
              <Header color='grey' as='h2' style={{fontFamily:['Pretendard-Regular']}}>상세 리포트</Header>
            </Segment>
            <Segment style={{borderRadius:0, background:'#fff'}}>
              <Grid divided='vertically'>
                <Grid.Row divided verticalAlign='middle' style={{padding:0, marginTop:14}}>
                  <Grid.Column width={8}>
                    <Header as='h4' style={{color: '#888', marginBottom:10}}><strong>튜닝여부</strong></Header>
                    <div style={{fontSize:'1.5rem'}}>
                      {
                        reservation.tuningState === '1' ?
                          '해당없음' :
                          reservation.tuningLevel === '1' ? <Label size='large' color='blue'>적법</Label> : <Label size='large' color='red'>불법</Label>
                      }
                    </div>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Header as='h4' style={{color: '#888', marginBottom:10}}><strong>특별이력</strong></Header>
                    <div style={{fontSize:'1.5rem'}}>
                      {
                        reservation.specialState === '1' ?
                          '해당없음' : getSpecialStateDetail()
                      }
                    </div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row divided verticalAlign='middle' style={{padding:0}}>
                  <Grid.Column width={16}>
                    <Header as='h4' style={{color: '#888', marginBottom:0}}><strong>점검결과</strong></Header>
                    <List verticalAlign='middle' style={{fontSize:'1.5rem'}}>
                      <List.Item>
                        <List.Content floated='right'>
                          <span style={{color: reservation.leakageOilCount > 0 ? "#f2711c" : "#21ba45"}}>{reservation.leakageOilCount}</span> 건
                        </List.Content>
                        <List.Content>누유</List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content floated='right'>
                          <span style={{color: reservation.badConditionCount > 0 ? "#f2711c" : "#21ba45"}}>{reservation.badConditionCount}</span> 건
                          </List.Content>
                        <List.Content>불량</List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content floated='right'>
                          <span style={{color: reservation.engineOilLevel === "1" ? "#21ba45" : "#f2711c"}}>
                            {reservation.engineOilLevel === "1" ? "적정" : reservation.engineOilLevel === "2" ? "부족" : "과다" }
                          </span>
                        </List.Content>
                        <List.Content>엔진오일</List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content floated='right'>
                          <span style={{color: reservation.coolingWaterLevel === "1" ? "#21ba45" : "#f2711c"}}>
                            {reservation.coolingWaterLevel === "1" ? "적정" : reservation.coolingWaterLevel === "2" ? "부족" : "과다" }
                          </span>
                        </List.Content>
                        <List.Content>냉각수</List.Content>
                      </List.Item>
                    </List>
                 </Grid.Column>
                </Grid.Row>
                <Grid.Row divided verticalAlign='middle' style={{padding:0}}>
                  <Grid.Column width={16}>
                    <Header as='h4' style={{color: '#888', marginBottom:0}}><strong><Icon name='warning sign' color='red' />긴급조치필요</strong></Header>
                    {reservation.hasEmergency ?
                      <List verticalAlign='middle' style={{fontSize: '1.5rem', color: '#db2828'}}>
                        {reservation.missionOilLevel !== "1" ?
                          <List.Item>
                            <List.Content><span style={{color:'#000000de'}}>미션 오일</span> {reservation.missionOilLevel === "2" ? "부족" : "과다"}</List.Content>
                          </List.Item> : ''
                        }
                        {reservation.leakOilFromCylinderHead ?
                          <List.Item>
                            <List.Content><span style={{color:'#000000de'}}>실린더 헤드/가스켓</span> 누유</List.Content>
                          </List.Item> : ''
                        }
                        {reservation.leakOilFromOilFan ?
                          <List.Item>
                            <List.Content><span style={{color:'#000000de'}}>오일팬</span> 누유</List.Content>
                          </List.Item> : ''
                        }
                        {reservation.leakCoolingWaterFromCylinderHead ?
                          <List.Item>
                            <List.Content><span style={{color:'#000000de'}}>실린더 헤드/가스켓 냉각수</span> 누수</List.Content>
                          </List.Item> : ''
                        }

                        {reservation.leakCoolingWaterFromPump ?
                          <List.Item>
                            <List.Content><span style={{color:'#000000de'}}>워터펌프 냉각수</span> 누수</List.Content>
                          </List.Item> : ''
                        }

                        {reservation.leakCoolingWaterFromRadiator ?
                          <List.Item>
                            <List.Content><span style={{color:'#000000de'}}>라디에이터 냉각수</span> 누수</List.Content>
                          </List.Item> : ''
                        }

                        {reservation.isBadIdleMission ?
                          <List.Item>
                            <List.Content><span style={{color:'#000000de'}}>미션 공회전</span> 불량</List.Content>
                          </List.Item> : ''
                        }
                      </List> : <div style={{fontSize:'1.5rem'}}>해당없음</div>
                    }
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={16}>
                    <Header as='h4' style={{color:'#888', marginBottom:0}}><strong>사고이력</strong></Header>
                    {reservation.hasAccident ?
                      <div style={{fontSize:'2rem', color: '#f2711c'}}>유사고</div>
                      :
                      <div style={{fontSize:'2rem', color: '#2185d0'}}>무사고</div>
                    }
                  </Grid.Column>
                  <Image src='/images/carmon-stamp.png' size='tiny' style={{position: 'absolute', bottom: '1vh', right:'2vh'}}/>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column color='grey'>
                    위 리포트는 카몬 제휴 정비소에서 실시한 차량 성능점검 데이터를 기반으로 작성되었습니다.<br />
                    따라서, 카몬은 리포트에 명시된 내용이 사실과 다름이 없음을 인증합니다.
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Segment.Group>
        </Container>
      </Segment>
    </>
  );

};

export default CarmonPerformanceReport;