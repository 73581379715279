import React, {useEffect, useState} from 'react';
import {
  Button,
  Container,
  Dimmer,
  Grid,
  Header,
  Icon,
  List,
  Loader,
  Message, Modal,
  Segment,
  Table,
} from 'semantic-ui-react';
import axios from 'axios';
import {toast} from 'react-semantic-toasts';
import {useParams} from 'react-router-dom';

const CarmonAuctionRetry = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [reservation, setReservation] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    setLoading(false);
    retrieveReservation();
  }, []);

  const retrieveReservation = async () => {
    try {
      const response = await axios.get(`/apis/external/auction/retry/${params.reservationNumber}`);
      if (response.status === 200) {
        setReservation(response.data);
        setLoading(false);
      }
    } catch (error) {
      toast({
        type: 'error',
        title: 'ERROR',
        time: 5000,
        description: error.response.data.message,
      });
    }
  };

  const close = () => {
    window.location.href = "kakaotalk://inappbrowser/close";
  }

  const auctionRetry = async () => {
    try {
      const response = await axios.post(`/apis/external/auction/retry/${params.reservationNumber}`);
      if (response.status === 200) {
        setReservation(response.data);
        setLoading(false);
        setAlertOpen(true);
      }
    } catch (error) {
      toast({
        type: 'error',
        title: 'ERROR',
        time: 5000,
        description: error.response.data.message,
      });
    }
  }

  const eventHandler = {
    onClickAuctionRetry: () => {
      auctionRetry();
    }
  }

  return (
    <>
      <Dimmer active={loading} page>
        <Loader size="massive">Loading</Loader>
      </Dimmer>

      <Container>
        <Segment.Group>
          <Segment>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={2}>예약번호</Table.Cell>
                  <Table.Cell><Header as="h2">{reservation.reservationNumber}</Header></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>차량번호</Table.Cell>
                  <Table.Cell><Header as="h2">{reservation.licensePlateNumber}</Header></Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            <Message positive>
              <Message.Header>아쉽게도 고객님의 차량이 많은 관심을 받지 못했습니다.</Message.Header>
              <p>하지만 한번 더 판매 해볼 수 있습니다!</p>
              <Header as='h3'>경매장에 다시 등록해보시겠습니까?</Header>
              <List.List style={{listStyle: 'none'}}>
                <List.Item><Icon name="right triangle" />경매는 오전 10시, 오후 1시 두차례 등록되며 오후 10시에 일괄 종료됩니다.</List.Item>
                <List.Item><Icon name="right triangle" />오후 1시 이후 등록된 차량은 다음 경매에 등록됩니다.</List.Item>
              </List.List>
            </Message>
          </Segment>

          <Segment>
            <Grid stackable>
              <Grid.Column width={16}>
                <Button fluid
                        size="massive"
                        color="teal"
                        icon={
                          <Icon.Group size='large'>
                            <Icon name='car' />
                            <Icon corner='top right' name='repeat' color='green' />
                          </Icon.Group>
                        }
                        content=' 경매 등록하기'
                        onClick={eventHandler.onClickAuctionRetry} />
              </Grid.Column>
            </Grid>
          </Segment>
        </Segment.Group>
      </Container>
      <Modal centered={false} size='small' open={alertOpen}>
        <Modal.Header>경매 등록 완료!</Modal.Header>
        <Modal.Content>
          <p>오전 9시 이후에 등록된 건은 다음 경매에 등록됩니다.</p>
          <p>경매 진행 상황은 카카오톡 알림톡으로 알려드립니다.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color='blue' content='확인' icon='check' onClick={() => {setAlertOpen(false); close();}}/>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default CarmonAuctionRetry;