import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Container, Dimmer,
  Grid,
  Header,
  Icon, Image, Loader, Message,
  Segment,
  Table,
  Transition,
} from 'semantic-ui-react';
import axios from 'axios';
import {toast} from 'react-semantic-toasts';
import {useParams} from 'react-router-dom';
import SignaturePad from 'signature_pad';

const WIRE_FRAME_URL = '/images/wireframe/image.png';

let sigPad = null;

const CarmonSellerContract = () => {
  const params = useParams();
  const [reservation, setReservation] = useState({});
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState(false);
  const [imageUrl, setImageUrl] = useState(WIRE_FRAME_URL);
  const [idCard, setIdCard] = useState(null);
  const [sigPadData, setSigPadData] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);
  const [complete, setComplete] = useState(false);
  const fileControl = useRef();

  useEffect(() => {
    setCanSubmit(idCard && sigPadData);
  }, [idCard, sigPadData]);

  useEffect(() => {
    retrieveReservation();

    let canvas = document.querySelector('canvas');

    sigPad = new SignaturePad(canvas, {
      minWidth: 3,
      maxWidth: 6,
      onBegin: () => {
      },
      onEnd: () => setSigPadData(sigPad.toDataURL()),
    });

    resizeCanvas(canvas);

    window.addEventListener('resize', () => resizeCanvas(canvas));
    return () => window.removeEventListener('resize', () => resizeCanvas(canvas));
  }, []);

  const resizeCanvas = (canvas) => {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    sigPad.clear();
  }

  const retrieveReservation = async () => {
    try {
      const response = await axios.get(`/apis/external/contract/${params.reservationNumber}`);
      if (response.status === 200) {
        setReservation(response.data);

        if (response.data['idCardFileName'] && response.data['signatureFileName']) {
          setComplete(true);
        }

        setLoading(false);
      }
    } catch (error) {
      toast({
        type: 'error',
        title: 'ERROR',
        time: 5000,
        description: error.response.data.message,
      });
    }
  };

  const eventHandler = {
    dimerShow: (visible) => {
      setActive(visible);
    },
    onClickImageSelector: () => {
      fileControl.current.click();
    },
    onSelectedFile: (event) => {
      let file = event.target.files[0];
      let fileReader = new FileReader();
      fileReader.onload = () => {
        setImageUrl(fileReader.result);
      };
      fileReader.readAsDataURL(file);
      setActive(false);

      setIdCard(file);

    },
    onClickSubmit: async () => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('reservationNumber', params.reservationNumber);
        formData.append('signature', sigPadData);
        formData.append('files', idCard);

        const response = await axios.post(`/apis/external/contract`,
          formData,
          {
            headers: {'content-type': 'multipart/form-data'},
            timeout: 5 * 60 * 1000,
          });

        if (response.status === 200) {
          setLoading(false);
          retrieveReservation();
          toast({
            type: 'success',
            title: '등록완료',
            time: 2000,
            description: '계약정보가 정상적으로 등록되었습니다.',
          });
        }
      } catch (error) {
        setLoading(false);
        toast({
          type: 'error',
          title: 'ERROR',
          time: 5000,
          description: error.response.data.message,
        });
      }
    },
  };

  const content = (
    <div>
      <input type="file" accept="image/*" ref={fileControl} onChange={eventHandler.onSelectedFile} hidden></input>
      <Button primary onClick={eventHandler.onClickImageSelector} disabled={complete}>
        <Icon name="image outline" /> 불러오기
      </Button>
    </div>
  );

  return (
    <Container>
      <Dimmer active={loading} page>
        <Loader size="massive">Loading</Loader>
      </Dimmer>

      <Dimmer active={complete} page>
        <Header as="h2" icon inverted>
          <Icon name="handshake" />
          등록완료!
          <Header.Subheader>계약정보가 등록 완료되었습니다.</Header.Subheader>
        </Header>
      </Dimmer>

      <Segment.Group>
        <Segment><Header as="h1">계약 정보 등록</Header></Segment>
        <Segment>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={2}>예약번호</Table.Cell>
                <Table.Cell><Header as="h2">{reservation.reservationNumber}</Header></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>차량번호</Table.Cell>
                <Table.Cell><Header as="h2">{reservation.licensePlateNumber}</Header></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>판매자이름</Table.Cell>
                <Table.Cell><Header as="h2">{reservation.name}</Header></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>연락처</Table.Cell>
                <Table.Cell><Header as="h2">{reservation.contactNumber}</Header></Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>

        <Transition animation="slide down" duration={0}>
          <Segment>
            <Grid stackable centered>
              <Grid.Column width={16}>
                <Header as="h3">신분증촬영</Header>
                <Dimmer.Dimmable
                  as={Image}
                  dimmed={active}
                  dimmer={{active, content}}
                  onMouseEnter={() => eventHandler.dimerShow(true)}
                  onMouseLeave={() => eventHandler.dimerShow(false)}
                  fluid
                  src={imageUrl}
                />
              </Grid.Column>
              <Grid.Column width={16}>
                <Header as="h3">판매자 서명</Header>
                <canvas style={{border: '1px solid #cdcdcd', width: '100%', height: '30vh'}} />
                <Button fluid color="orange" onClick={() => {
                  sigPad.clear();
                  setSigPadData(null);
                }} disabled={complete}><Icon name="eraser" />지우기</Button>
              </Grid.Column>
            </Grid>
          </Segment>
        </Transition>

        <Transition animation="slide down" duration={0}>
          <Segment>
            <Grid stackable>
              <Grid.Column width={16}>
                <Button fluid
                        size="massive"
                        color="teal"
                        icon="paper plane"
                        form="form"
                        type="submit"
                        content="탁송시작"
                        disabled={!canSubmit || complete}
                        onClick={eventHandler.onClickSubmit} />
                <Message warning>탁송을 시작하면 딜러에게 출발 알림톡이 발송됩니다.</Message>
              </Grid.Column>
            </Grid>
          </Segment>
        </Transition>
      </Segment.Group>
    </Container>
  );
};

export default CarmonSellerContract;
