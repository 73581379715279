import React, {useEffect, useRef, useState} from 'react';
import {Button, Divider, Header, Icon, Modal, ModalContent, Segment, Transition} from 'semantic-ui-react';
import {PARTS} from '../Consts';
import {toast} from 'react-semantic-toasts';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CameraControl = ({open, setOpen, images, setImages, callback}) => {
  const [currentPoint, setCurrentPoint] = useState(PARTS[0][0]['position']);
  const [onCamera, setOnCamera] = useState(false);
  const slider = useRef(0);

  let constraints = {
    video: {
      facingMode: 'environment',
      width: {
        min: 1920,
      },
      height:{
        min: 1080,
      }
    },
    audio: false,
  };

  let cameraView, cameraSensor;

  useEffect(() => {
    if (open) cameraStart();
  }, [open]);

  const cameraStart = () => {
    console.log(1);
    cameraView = document.querySelector("#camera--view");
    navigator.mediaDevices.getUserMedia(constraints)
      .then(function (stream) {
        console.log(stream.getTracks());
        console.log(stream.getVideoTracks());
        const track = stream.getTracks()[0];
        cameraView.srcObject = stream;
        setOnCamera(true);
      })
      .catch(function (error) {
        console.error("카메라에 문제가 있습니다.", error);
      });
  };

  const takePicture = () => {
    if (!currentPoint) {
      toast({
        type: 'warning',
        title: '',
        time: 2000,
        description: '촬영 부위를 선택해주세요.'
      });

      return;
    }

    cameraSensor = document.querySelector("#camera--sensor");
    cameraView = document.querySelector("#camera--view");

    cameraSensor.width = cameraView.videoWidth;
    cameraSensor.height = cameraView.videoHeight;
    cameraSensor.getContext("2d").drawImage(cameraView, 0, 0);

    setOnCamera(false);
  };

  const retry = () => {
    setOnCamera(true);
  };

  const acceptPicture = () => {
    cameraSensor = document.querySelector("#camera--sensor");
    cameraSensor.toBlob((blob) => {
      setImages(prevState => {
        return {
          ...prevState,
          [currentPoint]: new File([blob], 'car-image.png', { type: 'image/png' })
        }
      });
    }, 'image/png');

    setOnCamera(true);
    nextParts();
  }

  const nextParts = () => {
    let findCurrent = false;

    PARTS.map(group => {
      group.map(part => {
        if (findCurrent) {
          setCurrentPoint(part['position']);
          findCurrent = false;
          //console.log(slider.current);
          slider.current.slickGoTo(part['idx']);
        } else if (currentPoint === part['position']) findCurrent = true;
      });
    });
  };

  const renderParts = () => {
    const renderer = [];
    let idx = 0;
    PARTS.map(group => {
      idx++;
      group.map(part => {
        renderer.push(
          <div>
            <Button key={idx}
                    color={currentPoint === part['position'] ? 'red' : 'grey'}
                    content={part['position_name']}
                    style={{margin: '3px'}}
                    onClick={() => setCurrentPoint(part['position'])} />
          </div>
        );
      });
      //renderer.push(<br key={`partsGroup${idx}`} />);
    });
    return renderer;
  };

  const SlideCustomComponent = {
    NextArrow: (props) => {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style}}
          onClick={onClick}
        />
      );
    },
    PrevArrow: (props) => {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style}}
          onClick={onClick}
        />
      );
    }
  }


  const SLIDER_SETTING = {
    dots: false,
    arrow: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SlideCustomComponent.NextArrow />,
    prevArrow: <SlideCustomComponent.PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div
      style={{
        position:'fixed',
        top:0,
        bottom:0,
        left:0,
        right:0,
        display: open ? 'block' : 'none',
        background:'#ddd'
      }}
      // style={{width: '90vw', maxHeight:'80vh'}}
      // onMount={() => cameraStart()}
      // onUnmount={() => callback && callback()}
      // onClose={() => setOpen(false)}
    >
        <Segment basic
                 textAlign='center'
                 style={{
                   width: '100vw',
                   position:'absolute',
                   top: 0,
                   zIndex: 1000
                 }}>
          <div style={{ display:'inline-block', width:'70vw', marginBottom:20 }}>
            <Slider {...SLIDER_SETTING}
                    ref={slider}>
              {renderParts()}
            </Slider>
          </div>
        </Segment>

        <Segment basic
                 textAlign='center'
                 style={{
                   width: '100vw',
                   position:'absolute',
                   bottom: 0,
                   zIndex: 1000
                 }}>
          <div>
            <Transition visible={onCamera} animation='scale' duration={100}>
              <Segment basic textAlign='center' >
                <Button onClick={takePicture} color='blue'><Icon name='camera' />촬영</Button>
                <Button onClick={cameraStart} color='teal'><Icon name='redo' />카메라 로드</Button>
                <Button onClick={() => {
                  callback && callback();
                  setOpen(false);
                }} color='red'><Icon name='check' />닫기</Button>
              </Segment>
            </Transition>

            <Transition visible={!onCamera} animation='scale' duration={100}>
              <Segment basic textAlign='center' >
                <Button onClick={retry} color='orange'><Icon name='redo' /> 다시 촬영</Button>
                <Button onClick={acceptPicture} color='green'><Icon name='check' /> 사진 확정</Button>
              </Segment>
            </Transition>
          </div>
        </Segment>

        <video id="camera--view"
               autoPlay
               playsInline
               style={{
                 width:'100vw',
                 position:'absolute',
                 zIndex:999,
                 left: 0,
                 top: 0,
                 display: onCamera ? 'block' : 'none'
               }} />

        <canvas id="camera--sensor"
                style={{
                  width:'100vw',
                  position:'absolute',
                  zIndex:999,
                  left: 0,
                  top: 0,
                  display: onCamera ? 'none' : 'block'
                }}/>
        {/*<Segment basic textAlign='center' style={{position:'relative'}}>*/}
          {/*<Segment textAlign='center'>*/}
          {/*  <video id="camera--view" autoPlay playsInline style={{width:'80vw', height:'45vw', display: onCamera ? 'inline-block' : 'none'}} />*/}
          {/*  <canvas id="camera--sensor" style={{width:'80vw', height:'45vw', display: onCamera ? 'none' : 'inline-block'}}/>*/}
          {/*</Segment>*/}
        {/*</Segment>*/}
    </div>
  );
}

export default CameraControl;