import React, {createRef, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  Button,
  Container, Dimmer, Divider,
  Grid,
  Header,
  Icon, Loader,
  Message, Ref,
  Segment, Sticky,
  Table,
} from 'semantic-ui-react';
import CarmonImageControl from '../components/ImageControl';
import _ from 'lodash';
import axios from 'axios';
import {toast} from 'react-semantic-toasts';
import CameraControl from '../components/CameraControl';
import {PARTS} from '../Consts';
import {Beforeunload, useBeforeunload} from 'react-beforeunload';

const CarmonUploadPicture = (props) => {
  const params = useParams();
  const [reservation, setReservation] = useState({});
  const [images, setImages] = useState({});
  const [loader, setLoader] = useState({active: true, message: '초기화중...'});
  const [cameraModalOpen, setCameraModalOpen] = useState(false);

  useBeforeunload((event) => {
    event.preventDefault();
  });

  const imageSelectCallback = (position, image) => {
    setImages(prevState => {
      return {
        ...prevState,
        [position]: image,
      };
    });
  };

  useEffect(() => {
    retrieveReservation();
  }, []);

  const retrieveReservation = async () => {
    try {
      const response = await axios.get(`/apis/external/reservation/${params.reservationNumber}`);
      if (response.status === 200) {
        setReservation(response.data);
        setLoader(prevState => {
          return {
            ...prevState,
            active: false,
          };
        });

        setImages({});
      }
    } catch (ex) {
      toast({
        type: 'error',
        title: 'ERROR',
        time: 2000,
        description: ex.response.data.message,
      });
    }
  };

  const checkValidation = () => {
    let hasError = false;

    for (let part of PARTS.flat()) {
      if (part.required && !images.hasOwnProperty(part.position)) {
        toast({
          type: 'error',
          title: 'ERROR',
          time: 2000,
          description: '필수 이미지가 누락되었습니다.',
        });

        hasError = true;
        break;
      }
    }

    return !hasError;
  };

  const uploadImages = async () => {
    if (!checkValidation()) return false;

    const formData = new FormData();
    formData.append('reservationNumber', params.reservationNumber);
    formData.append('status', 'new');

    _.keys(images).forEach(key => {
      let file = images[key];
      formData.append(key, file);
    });

    try {
      setLoader(prevState => {
        return {
          ...prevState,
          active: true,
          message: '업로드중...',
        };
      });

      const response = await axios.post('/apis/external/image/upload',
        formData,
        {
          headers: {'content-type': 'multipart/form-data'},
          timeout: 5 * 60 * 1000,
        });
      if (response.status === 200) {
        setLoader(prevState => {
          return {
            ...prevState,
            active: false,
          };
        });

        retrieveReservation();

        toast({
          type: 'success',
          title: '등록완료',
          time: 3000,
          size: 'large',
          description: '이미지가 정상적으로 등록되었습니다.',
        });
      }
    } catch (ex) {
      setLoader(prevState => {
        return {
          ...prevState,
          active: false,
        };
      });
      console.error(ex.response.data);
      toast({
        type: 'error',
        title: '등록실패',
        time: 2000,
        description: '이미지 업로드에 실패했습니다.',
      });
    }
  };

  const getImageUrl = (position) => {
    let image = [];
    let currentIdx = null;

    if (reservation.images && reservation.images.length > 0) {
      image = reservation.images.filter((o, idx) => {
        if (o.position === position) {
          currentIdx = idx;
          return o;
        }
      });
    }
    return image.length === 0 ? null : image[0];
  };

  const removeImage = (position) => {
    setImages(prevState => {
      delete prevState[position];

      return {
        ...prevState,
      };
    });
  };

  const renderControl = () => {
    const render = [];

    PARTS.map((row, idx) => {
      render.push(
        <Grid.Row key={'row'.concat(String(idx))}>
          {
            row.map((col, colIdx) => {
              return (
                <Grid.Column key={'row'.concat(String(idx)).concat('col'.concat(String(colIdx)))}>
                  <Header content={<div>{col.position_name} {col.required ?
                    <span style={{color: 'red'}}>*</span> : <></>}</div>}
                          subheader={col.description ? col.description : col.position_name} />
                  <CarmonImageControl position={col.position}
                                      currentImageUrl={getImageUrl(col.position)}
                                      removeImage={removeImage}
                                      callback={imageSelectCallback} />
                </Grid.Column>
              );
            })
          }
        </Grid.Row>,
      );
    });

    return render;
  };

  const eventHandler = {
    onClickOpenCamera: () => {
      setCameraModalOpen(true);
    },
  };

  const applyImage = () => {
    let newImages = [];

    for (const [key, value] of Object.entries(images)) {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        setReservation(prevState => {
          const filtered = prevState.images.filter(v => v.position === key);

          if (filtered.length > 0) {
            let imageObject = _.cloneDeep(filtered[0]);
            imageObject.url = fileReader.result;
            newImages.push(imageObject);
          } else {
            newImages.push({position: key, url: fileReader.result});
          }

          return {
            ...prevState,
            images: newImages,
          };
        });
      };
      fileReader.readAsDataURL(images[key]);
    }
  };

  return (
    <Beforeunload onBeforeunload={() => 'You’ll lose your data!'}>
      <Segment basic style={{padding: '0'}}>
        <Dimmer active={loader.active} inverted>
          <Loader inverted style={{position: 'fixed', top: '50vh'}} size="large">
            <Header as="h2" color="grey">{loader.message}</Header>
          </Loader>
        </Dimmer>
        <Container>
          <Segment.Group>
            <Segment color="teal">
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={2}>예약번호</Table.Cell>
                    <Table.Cell><Header as="h3">{reservation.reservationNumber}</Header></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>차량번호</Table.Cell>
                    <Table.Cell><Header as="h3">{reservation.licensePlateNumber}</Header></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>정비소</Table.Cell>
                    <Table.Cell><Header as="h3">{reservation.centerName}</Header></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Message warning><Icon name="warning sign" /> 예약 정보를 확인 후 진행해주세요!</Message>
            </Segment>
            <Segment>
              <Button fluid color="orange" size="huge" onClick={eventHandler.onClickOpenCamera}>
                <Icon name="camera" /> 연속촬영
              </Button>
              <Divider />
              <Grid columns={3} relaxed="very" celled="internally" stackable={true} style={{marginBottom:30}}>
                {renderControl()}
              </Grid>
            </Segment>

            <Button fluid size="huge" color="teal" onClick={uploadImages} style={{
              position: 'fixed',
              borderRadius: 0,
              left: 0,
              bottom: 0,
              zIndex: 999}}>
              <Icon name="upload" /> 사진 업로드
            </Button>

          </Segment.Group>
        </Container>
        <CameraControl
          open={cameraModalOpen}
          setOpen={setCameraModalOpen}
          images={images}
          setImages={setImages}
          callback={applyImage} />
        {/*<NewCameraControl
          open={cameraModalOpen}
          setOpen={setCameraModalOpen} />*/}
      </Segment>
    </Beforeunload>
  );
};

export default CarmonUploadPicture;
