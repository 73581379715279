import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {
  Button,
  Checkbox,
  Container, Dimmer,
  Divider,
  Form,
  Grid,
  Header,
  Icon, Input,
  List,
  Message, Modal,
  Segment,
  Table,
  TextArea,
} from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import axios from 'axios';
import {toast} from 'react-semantic-toasts';
import queryString from 'query-string';

export const BASE_STYLES = {
  THEME_COLOR: '#00aed9',
  THEME_SUB_COLOR: '#feb948',
  THEME_ITEM_COLOR: '#f0f0f0',
  THEME_BG_COLOR: '#696969',
  FONT_COLOR: 'rgb(27, 28, 29)',
  BORDER_COLOR: 'rgba(34,36,38,.1)',
  FONT_SIZE: {
    HEADER: {
      MAIN: 36,
      SUB: 26,
    },
    MAX: 22,
    BASE: 18,
    SUB: 14,
    MIN: 12,
  },
};

const _setWarningTextColor = (dataRaw, dataKey, value) => {
  return dataRaw?.[dataKey] === value
    ? {color: 'rgba(204,51,0,1)', fontWeight: 'bold'}
    : {color: BASE_STYLES.FONT_COLOR, fontWeight: 'normal'};
};

const _setChecked = (dataRaw, dataKey, value) => {
  return dataRaw?.[dataKey] === value;
};

const DEFAULT_DATA = {
  BAS_000001: '', //성능점검장코드
  BAS_000002: '', //성능지관리번호
  BAS_000003: moment().format('YYYYMMDD'), //성능점검일
  BAS_000004: '', //성능점검장명
  BAS_000005: '', //성능점검자
  BAS_000006: '카몬', //고객명
  BAS_010000: '', //차명
  BAS_010100: '', //세부모델
  BAS_020000: '', //자동차등록번호
  BAS_030000: '', //연식
  BAS_040100: '', //검사유효기간 시작일
  BAS_040200: '', //검사유효기간 종료일
  BAS_050000: '', //최초등록일
  BAS_060000: '', //차대번호
  BAS_070000: '1', //변속기종류
  BAS_070100: '', //변속기종류기타
  BAS_080000: '1', //사용연료
  BAS_090000: '', //원동기형식
  BAS_990000: '', //특기사항

  TOT_110101: '1', //계기상태
  TOT_110201: '', //주행거리상태
  TOT_110202: '', //주행거리항목
  TOT_110301: '1', //차대번호표기
  TOT_110401: '2', //일산화탄소상태
  TOT_110402: '', //일산화탄소항목
  TOT_110411: '2', //탄화수소상태
  TOT_110412: '', //탄화수소항목
  TOT_110421: '', //매연상태
  TOT_110422: '', //매연항목
  TOT_110501: '1', //튜닝상태
  TOT_110511: '', //튜닝
  TOT_110521: '', //튜닝-구조
  TOT_110522: '', //튜닝-장치
  TOT_110601: '1', //특별이력상태
  TOT_110611: '', //특별이력-침수
  TOT_110612: '', //특별이력-화재
  TOT_110701: '1', //용도변경상태
  TOT_110711: '', //용도변경-렌트
  TOT_110713: '', //용도변경-영업용
  TOT_110801: '', //리콜대상
  TOT_110811: '', //리콜이행

  IMS_120001: '', //후드
  IMS_120004: '', //트렁크리드
  IMS_120005: '', //라디에이터서포트
  IMS_120007: '', //루프패널
  IMS_120009: '', //프론트패널
  IMS_120010: '', //크로스멤버
  IMS_120015: '', //대쉬패널
  IMS_120016: '', //플로어패널
  IMS_120017: '', //트렁크플로어
  IMS_120018: '', //리어패널
  IMS_120019: '', //패키지트레이
  IMS_121002: '', //프론트휀더(좌)
  IMS_121006: '', //쿼터패털(좌)
  IMS_121008: '', //사이드실패널(좌)
  IMS_121011: '', //인사이드패널(좌)
  IMS_121103: '', //도어(좌전)
  IMS_121112: '', //사이드멤버(좌전)
  IMS_121113: '', //휠하우스(좌전)
  IMS_121114: '', //필러패널(좌전)
  IMS_121214: '', //필러패널(좌중)
  IMS_121303: '', //도어(좌후)
  IMS_121312: '', //사이드멤버(좌후)
  IMS_121313: '', //휠하우스(좌후)
  IMS_121314: '', //필러패널(좌후)
  IMS_122002: '', //프론트휀더(우)
  IMS_122006: '', //쿼터패털(우)
  IMS_122008: '', //사이드실패널(우)
  IMS_122011: '', //인사이드패널(우)
  IMS_122103: '', //도어(우전)
  IMS_122112: '', //사이드멤버(우전)
  IMS_122113: '', //휠하우스(우전)
  IMS_122114: '', //필러패널(우전)
  IMS_122214: '', //필러패널(우중)
  IMS_122303: '', //도어(우후)
  IMS_122312: '', //사이드멤버(우후)
  IMS_122313: '', //휠하우스(우후)
  IMS_122314: '', //필러패널(우후)

  HST_12A001: '1', //사고이력
  HST_12A002: '1', //단순수리
  HST_140101: '', //외판부위1랭크-1후드
  HST_140102: '', //외판부위1랭크-2프론트휀더
  HST_140103: '', //외판부위1랭크-3도어
  HST_140104: '', //외판부위1랭크-4트렁크리드
  HST_140105: '', //외판부위1랭크-5라디에이터서포트
  HST_140201: '', //외판부위2랭크-6쿼터패널
  HST_140202: '', //외판부위2랭크-7루프패널
  HST_140203: '', //외판부위2랭크-8사이드실패널
  HST_140301: '', //주요골격A랭크-9프론트패널
  HST_140302: '', //주요골격A랭크-10크로스멤버
  HST_140303: '', //주요골격A랭크-11인사이드패널
  HST_140304: '', //주요골격A랭크-17트렁크플로어
  HST_140305: '', //주요골격A랭크-18리어패널
  HST_140401: '', //주요골격B랭크-12사이드멤버
  HST_140402: '', //주요골격B랭크-13휠하우스
  HST_140403: '', //주요골격B랭크-14필러패널
  HST_140404: '', //주요골격B랭크-14필러패널A
  HST_140405: '', //주요골격B랭크-14필러패널B
  HST_140406: '', //주요골격B랭크-14필러패널C
  HST_140407: '', //주요골격B랭크-19패키지트레이
  HST_140501: '', //주요골격C랭크-15대쉬패널
  HST_140502: '', //주요골격C랭크-16플로어패널

  DTS_151101: '1', //자기진단-원동기
  DTS_151201: '1', //자기진단-변속기
  DTS_152101: '1', //원동기-작동상태(공회전)
  DTS_152201: '1', //원동기-오일누유-로커암커버
  DTS_152202: '1', //원동기-오일누유-실린더헤드/가스켓
  DTS_152203: '1', //원동기-오일누유-오일팬
  DTS_152301: '1', //원동기-오일유량
  DTS_152401: '1', //원동기-냉각수누수-실린더헤드/가스켓
  DTS_152402: '1', //원동기-냉각수누수-워터펌프
  DTS_152403: '1', //원동기-냉각수누수-라디에이터
  DTS_152404: '1', //원동기-냉각수누수-냉각수수량
  DTS_152501: '', //원동기-고압펌프(커먼레일)-디젤엔진
  DTS_153101: '1', //변속기-자동변속기-오일누유
  DTS_153102: '1', //변속기-자동변속기-오일유량및상태
  DTS_153103: '1', //변속기-자동변속기-작동상태(공회전)
  DTS_153201: '', //변속기-수동변속기-오일누유
  DTS_153202: '', //변속기-수동변속기-기어변속장치
  DTS_153203: '', //변속기-수동변속기-오일유량및상태
  DTS_153204: '', //변속기-수동변속기-작동상태(공회전)
  DTS_154101: '', //동력전달-클러치어셈블리
  DTS_154201: '1', //동력전달-등속죠인트
  DTS_154301: '1', //동력전달-추진축및베어링
  DTS_154401: '1', //동력전달-디퍼렌셜기어
  DTS_155101: '1', //조향-동력조향작동오일누유
  DTS_155201: '1', //조향-작동상태-스티어링기어
  DTS_155202: '1', //조향-작동상태-스티어링펌프
  DTS_155203: '1', //조향-작동상태-타이로드엔드및볼죠인트
  DTS_155204: '1', //조향-작동상태-스티어링죠인트
  DTS_155205: '1', //조향-작동상태-파워고압호스
  DTS_156101: '1', //제동-브레이크마스터실린더오일누유
  DTS_156201: '1', //제동-브레이크오일누유
  DTS_156301: '1', //제동-배력장치상태
  DTS_157101: '1', //전기-발전기출력
  DTS_157201: '1', //전기-시동모터
  DTS_157301: '1', //전기-와이퍼모터기능
  DTS_157401: '1', //전기-실내송풍모터
  DTS_157501: '1', //전기-라디에이터팬모터
  DTS_157601: '1', //전기-윈도우모터
  DTS_158101: '1', //연료-연료누출(LP가스포함)
  DTS_159101: '', //고전원전기장치-충전구절연상태
  DTS_159201: '', //고전원전기장치-구동축전지격리상태
  DTS_159301: '', //고전원전기장치-고전원전기배선상태
}

const DEFAULT_PARTS_DATA = {
  LEFT: {
    parts2: {code: '2', name: '프론트펜더', status: '', top: 26, left: 37},
    parts3_1: {code: '3A', name: '앞도어', status: '', top: 120, left: 50},
    parts3_2: {code: '3B', name: '뒷도어', status: '', top: 165, left: 50},
    parts6: {code: '6', name: '쿼터패널(리어펜더)', status: '', top: 234, left: 57},
    parts8: {code: '8', name: '사이드실패널', status: '', top: 136, left: 17},
    partsA: {code: 'A', name: 'A필러', status: '', top: 112, left: 113},
    partsB: {code: 'B', name: 'B필러', status: '', top: 150, left: 90},
    partsC: {code: 'C', name: 'C필러', status: '', top: 177, left: 113},
  },

  RIGHT: {
    parts2: {code: '2', name: '프론트펜더', status: '', top: 26, left: 84},
    parts3_1: {code: '3A', name: '앞도어', status: '', top: 120, left: 75},
    parts3_2: {code: '3B', name: '뒷도어', status: '', top: 165, left: 75},
    parts6: {code: '6', name: '쿼터패널(리어펜더)', status: '', top: 234, left: 68},
    parts8: {code: '8', name: '사이드실패널', status: '', top: 136, left: 102},
    partsA: {code: 'A', name: 'A필러', status: '', top: 120, left: 10},
    partsB: {code: 'B', name: 'B필러', status: '', top: 150, left: 28},
    partsC: {code: 'C', name: 'C필러', status: '', top: 180, left: 8},
  },

  TOP: {
    parts1: {code: '1', name: '후드', status: '', top: 45, left: 64},
    parts4: {code: '4', name: '트렁크리드', status: '', top: 220, left: 64},
    parts7: {code: '7', name: '루프패널', status: '', top: 140, left: 64},
  },

  BOTTOM: {
    parts5: {code: '5', name: '라디에이터서포트', status: '', top: 50, left: 64},
    parts9: {code: '9', name: '프론트패널', status: '', top: 50, left: 64},
    parts10: {code: '10', name: '크로스맴버', status: '', top: 50, left: 64},
    parts11_1: {code: '11-1', name: '인사이드패널', status: '', top: 50, left: 64},
    parts11_2: {code: '11-2', name: '인사이드패널', status: '', top: 50, left: 64},
    parts12_1: {code: '12-1', name: '사이드맴버', status: '', top: 50, left: 64},
    parts12_2: {code: '12-2', name: '사이드맴버', status: '', top: 50, left: 64},
    parts12_3: {code: '12-3', name: '사이드맴버', status: '', top: 50, left: 64},
    parts12_4: {code: '12-4', name: '사이드맴버', status: '', top: 50, left: 64},
    parts13_1: {code: '13-1', name: '휠하우스', status: '', top: 50, left: 64},
    parts13_2: {code: '13-2', name: '휠하우스', status: '', top: 50, left: 64},
    parts13_3: {code: '13-3', name: '휠하우스', status: '', top: 50, left: 64},
    parts13_4: {code: '13-4', name: '휠하우스', status: '', top: 50, left: 64},
    parts15: {code: '15', name: '대쉬패널', status: '', top: 50, left: 64},
    parts16: {code: '16', name: '플로어패널', status: '', top: 50, left: 64},
    parts17: {code: '17', name: '트렁크플로어', status: '', top: 50, left: 64},
    parts18: {code: '18', name: '리어패널', status: '', top: 50, left: 64},
  },
};

const STATUS_CODES = [
  {code: 'X', title: '교환', color: 'red'},
  {code: 'W', title: '판금 또는 용접', color: 'orange'},
  {code: 'C', title: '부식', color: 'yellow'},
  {code: 'A', title: '흠집', color: 'olive'},
  {code: 'U', title: '요철', color: 'green'},
  {code: 'T', title: '손상', color: 'purple'},
];

const CarPerformanceCheck = () => {
  const GEAR_TYPE = {
    Automatic: {
      code: '1',
      defaultValues: {
        DTS_153101: '1',
        DTS_153102: '1',
        DTS_153103: '1',
        DTS_153201: '',
        DTS_153202: '',
        DTS_153204: '',
        DTS_154101: ''
      },
      disabled: {
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: true, //변속기-수동변속기-오일누유
        DTS_153202: true, //변속기-수동변속기-기어변속장치
        DTS_153203: false, //변속기-수동변속기-오일유량및상태
        DTS_153204: true, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: true
      }
    },
    Manual: {
      code: '2',
      defaultValues: {
        DTS_153101: '',
        DTS_153102: '',
        DTS_153103: '',
        DTS_153201: '1',
        DTS_153202: '1',
        DTS_153204: '1',
        DTS_154101: '1'
      },
      disabled: {
        DTS_153101: true, //변속기-자동변속기-오일누유
        DTS_153102: true, //변속기-자동변속기-오일유량및상태
        DTS_153103: true, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: false, //변속기-수동변속기-오일누유
        DTS_153202: false, //변속기-수동변속기-기어변속장치
        DTS_153203: true, //변속기-수동변속기-오일유량및상태
        DTS_153204: false, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: false
      }
    },
    SemiAuto: {
      code: '3',
      defaultValues: {
        DTS_153101: '1',
        DTS_153102: '1',
        DTS_153103: '1',
        DTS_153201: '',
        DTS_153202: '',
        DTS_153204: '',
        DTS_154101: ''
      },
      disabled: {
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: true, //변속기-수동변속기-오일누유
        DTS_153202: true, //변속기-수동변속기-기어변속장치
        DTS_153203: false, //변속기-수동변속기-오일유량및상태
        DTS_153204: true, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: true
      }
    },
    NoGear: {
      code: '4',
      defaultValues: {
        DTS_153101: '1',
        DTS_153102: '1',
        DTS_153103: '1',
        DTS_153201: '',
        DTS_153202: '',
        DTS_153204: '',
        DTS_154101: ''
      },
      disabled: {
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: true, //변속기-수동변속기-오일누유
        DTS_153202: true, //변속기-수동변속기-기어변속장치
        DTS_153203: false, //변속기-수동변속기-오일유량및상태
        DTS_153204: true, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: true
      }
    },
    ETC: {
      code: '5',
      defaultValues: {
        DTS_153101: '1',
        DTS_153102: '1',
        DTS_153103: '1',
        DTS_153201: '',
        DTS_153202: '',
        DTS_153204: '',
        DTS_154101: ''
      },
      disabled: {
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: true, //변속기-수동변속기-오일누유
        DTS_153202: true, //변속기-수동변속기-기어변속장치
        DTS_153203: false, //변속기-수동변속기-오일유량및상태
        DTS_153204: true, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: true
      }
    },
    fromCode: (code) => {
      let gearType = null;
      for(let key of Object.keys(GEAR_TYPE)) {
        if (GEAR_TYPE[key]['code'] === code) gearType = GEAR_TYPE[key];
      }
      return gearType;
    },
    current: () => {
      let gearType = null;
      for(let key of Object.keys(GEAR_TYPE)) {
        if (GEAR_TYPE[key]['code'] === currentData['BAS_070000']) gearType = GEAR_TYPE[key];
      }
      return gearType;
    },
  }
  const FUEL_TYPE = {
    Gasoline: {
      code: '1',
      defaultValues: {
        TOT_110401: '2', //일산화탄소상태
        TOT_110402: '', //일산화탄소항목
        TOT_110411: '2', //탄화수소상태
        TOT_110412: '', //탄화수소항목
        TOT_110421: '', //매연상태
        TOT_110422: '', //매연항목
        DTS_152101: '1', //원동기-작동상태(공회전)
        DTS_152201: '1', //원동기-오일누유-로커암커버
        DTS_152202: '1', //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: '1', //원동기-오일누유-오일팬
        DTS_152301: '1', //원동기-오일유량
        DTS_152401: '1', //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: '', //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: '1', //변속기-자동변속기-오일누유
        DTS_153102: '1', //변속기-자동변속기-오일유량및상태
        DTS_153103: '1', //변속기-자동변속기-작동상태(공회전)
        DTS_153201: '1', //변속기-수동변속기-오일누유
        DTS_153202: '1', //변속기-수동변속기-기어변속장치
        DTS_153203: '', //변속기-수동변속기-오일유량및상태
        DTS_153204: '1', //변속기-수동변속기-작동상태(공회전)
        DTS_154101: '1', //동력전달-클러치어셈블리
        DTS_155101: '1', //조향-동력조향작동오일누유
        DTS_155202: '1', //조향-작동상태-스티어링펌프
        DTS_157101: '1', //전기-발전기출력
        DTS_157201: '1', //전기-시동모터
        DTS_159101: '', //고전원전기장치-충전구절연상태
        DTS_159201: '', //고전원전기장치-구동축전지격리상태
        DTS_159301: '', //고전원전기장치-고전원전기배선상태
        DTS_158101: '1', //연료-연료누출(LP가스포함)
      },
      disabled: {
        DTS_152101: false, //원동기-작동상태(공회전)
        DTS_152201: false, //원동기-오일누유-로커암커버
        DTS_152202: false, //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: false, //원동기-오일누유-오일팬
        DTS_152301: false, //원동기-오일유량
        DTS_152401: false, //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: true, //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: false, //변속기-수동변속기-오일누유
        DTS_153202: false, //변속기-수동변속기-기어변속장치
        DTS_153203: true, //변속기-수동변속기-오일유량및상태
        DTS_153204: false, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: false, //동력전달-클러치어셈블리
        DTS_155101: false, //조향-동력조향작동오일누유
        DTS_155202: false, //조향-작동상태-스티어링펌프
        DTS_157101: false, //전기-발전기출력
        DTS_157201: false, //전기-시동모터
        DTS_159101: true, //고전원전기장치-충전구절연상태
        DTS_159201: true, //고전원전기장치-구동축전지격리상태
        DTS_159301: true, //고전원전기장치-고전원전기배선상태
        DTS_158101: false, //연료-연료누출(LP가스포함)
      }
    },
    Diesel: {
      code: '2',
      defaultValues: {
        TOT_110401: '', //일산화탄소상태
        TOT_110402: '', //일산화탄소항목
        TOT_110411: '', //탄화수소상태
        TOT_110412: '', //탄화수소항목
        TOT_110421: '2', //매연상태
        TOT_110422: '', //매연항목
        DTS_152101: '1', //원동기-작동상태(공회전)
        DTS_152201: '1', //원동기-오일누유-로커암커버
        DTS_152202: '1', //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: '1', //원동기-오일누유-오일팬
        DTS_152301: '1', //원동기-오일유량
        DTS_152401: '1', //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: '1', //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: '1', //변속기-자동변속기-오일누유
        DTS_153102: '1', //변속기-자동변속기-오일유량및상태
        DTS_153103: '1', //변속기-자동변속기-작동상태(공회전)
        DTS_153201: '1', //변속기-수동변속기-오일누유
        DTS_153202: '1', //변속기-수동변속기-기어변속장치
        DTS_153203: '', //변속기-수동변속기-오일유량및상태
        DTS_153204: '1', //변속기-수동변속기-작동상태(공회전)
        DTS_154101: '1', //동력전달-클러치어셈블리
        DTS_155101: '1', //조향-동력조향작동오일누유
        DTS_155202: '1', //조향-작동상태-스티어링펌프
        DTS_157101: '1', //전기-발전기출력
        DTS_157201: '1', //전기-시동모터
        DTS_159101: '', //고전원전기장치-충전구절연상태
        DTS_159201: '', //고전원전기장치-구동축전지격리상태
        DTS_159301: '', //고전원전기장치-고전원전기배선상태
        DTS_158101: '1', //연료-연료누출(LP가스포함)
      },
      disabled: {
        DTS_152101: false, //원동기-작동상태(공회전)
        DTS_152201: false, //원동기-오일누유-로커암커버
        DTS_152202: false, //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: false, //원동기-오일누유-오일팬
        DTS_152301: false, //원동기-오일유량
        DTS_152401: false, //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: false, //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: false, //변속기-수동변속기-오일누유
        DTS_153202: false, //변속기-수동변속기-기어변속장치
        DTS_153203: true, //변속기-수동변속기-오일유량및상태
        DTS_153204: false, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: false, //동력전달-클러치어셈블리
        DTS_155101: false, //조향-동력조향작동오일누유
        DTS_155202: false, //조향-작동상태-스티어링펌프
        DTS_157101: false, //전기-발전기출력
        DTS_157201: false, //전기-시동모터
        DTS_159101: true, //고전원전기장치-충전구절연상태
        DTS_159201: true, //고전원전기장치-구동축전지격리상태
        DTS_159301: true, //고전원전기장치-고전원전기배선상태
        DTS_158101: false, //연료-연료누출(LP가스포함)
      }
    },
    LPG: {
      code: '3',
      defaultValues: {
        TOT_110401: '2', //일산화탄소상태
        TOT_110402: '', //일산화탄소항목
        TOT_110411: '2', //탄화수소상태
        TOT_110412: '', //탄화수소항목
        TOT_110421: '', //매연상태
        TOT_110422: '', //매연항목
        DTS_152101: '1', //원동기-작동상태(공회전)
        DTS_152201: '1', //원동기-오일누유-로커암커버
        DTS_152202: '1', //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: '1', //원동기-오일누유-오일팬
        DTS_152301: '1', //원동기-오일유량
        DTS_152401: '1', //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: '', //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: '1', //변속기-자동변속기-오일누유
        DTS_153102: '1', //변속기-자동변속기-오일유량및상태
        DTS_153103: '1', //변속기-자동변속기-작동상태(공회전)
        DTS_153201: '1', //변속기-수동변속기-오일누유
        DTS_153202: '1', //변속기-수동변속기-기어변속장치
        DTS_153203: '', //변속기-수동변속기-오일유량및상태
        DTS_153204: '1', //변속기-수동변속기-작동상태(공회전)
        DTS_154101: '1', //동력전달-클러치어셈블리
        DTS_155101: '1', //조향-동력조향작동오일누유
        DTS_155202: '1', //조향-작동상태-스티어링펌프
        DTS_157101: '1', //전기-발전기출력
        DTS_157201: '1', //전기-시동모터
        DTS_159101: '', //고전원전기장치-충전구절연상태
        DTS_159201: '', //고전원전기장치-구동축전지격리상태
        DTS_159301: '', //고전원전기장치-고전원전기배선상태
        DTS_158101: '1', //연료-연료누출(LP가스포함)
      },
      disabled: {
        DTS_152101: false, //원동기-작동상태(공회전)
        DTS_152201: false, //원동기-오일누유-로커암커버
        DTS_152202: false, //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: false, //원동기-오일누유-오일팬
        DTS_152301: false, //원동기-오일유량
        DTS_152401: false, //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: true, //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: false, //변속기-수동변속기-오일누유
        DTS_153202: false, //변속기-수동변속기-기어변속장치
        DTS_153203: true, //변속기-수동변속기-오일유량및상태
        DTS_153204: false, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: false, //동력전달-클러치어셈블리
        DTS_155101: false, //조향-동력조향작동오일누유
        DTS_155202: false, //조향-작동상태-스티어링펌프
        DTS_157101: false, //전기-발전기출력
        DTS_157201: false, //전기-시동모터
        DTS_159101: true, //고전원전기장치-충전구절연상태
        DTS_159201: true, //고전원전기장치-구동축전지격리상태
        DTS_159301: true, //고전원전기장치-고전원전기배선상태
        DTS_158101: false, //연료-연료누출(LP가스포함)
      }
    },
    Hybrid: {
      code: '4',
      defaultValues: {
        TOT_110401: '2', //일산화탄소상태
        TOT_110402: '', //일산화탄소항목
        TOT_110411: '2', //탄화수소상태
        TOT_110412: '', //탄화수소항목
        TOT_110421: '', //매연상태
        TOT_110422: '', //매연항목
        DTS_152101: '1', //원동기-작동상태(공회전)
        DTS_152201: '1', //원동기-오일누유-로커암커버
        DTS_152202: '1', //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: '1', //원동기-오일누유-오일팬
        DTS_152301: '1', //원동기-오일유량
        DTS_152401: '1', //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: '', //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: '1', //변속기-자동변속기-오일누유
        DTS_153102: '1', //변속기-자동변속기-오일유량및상태
        DTS_153103: '1', //변속기-자동변속기-작동상태(공회전)
        DTS_153201: '1', //변속기-수동변속기-오일누유
        DTS_153202: '1', //변속기-수동변속기-기어변속장치
        DTS_153203: '', //변속기-수동변속기-오일유량및상태
        DTS_153204: '1', //변속기-수동변속기-작동상태(공회전)
        DTS_154101: '1', //동력전달-클러치어셈블리
        DTS_155101: '1', //조향-동력조향작동오일누유
        DTS_155202: '1', //조향-작동상태-스티어링펌프
        DTS_157101: '1', //전기-발전기출력
        DTS_157201: '1', //전기-시동모터
        DTS_159101: '1', //고전원전기장치-충전구절연상태
        DTS_159201: '1', //고전원전기장치-구동축전지격리상태
        DTS_159301: '1', //고전원전기장치-고전원전기배선상태
        DTS_158101: '1', //연료-연료누출(LP가스포함)
      },
      disabled: {
        DTS_152101: false, //원동기-작동상태(공회전)
        DTS_152201: false, //원동기-오일누유-로커암커버
        DTS_152202: false, //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: false, //원동기-오일누유-오일팬
        DTS_152301: false, //원동기-오일유량
        DTS_152401: false, //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: true, //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: false, //변속기-수동변속기-오일누유
        DTS_153202: false, //변속기-수동변속기-기어변속장치
        DTS_153203: true, //변속기-수동변속기-오일유량및상태
        DTS_153204: false, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: false, //동력전달-클러치어셈블리
        DTS_155101: false, //조향-동력조향작동오일누유
        DTS_155202: false, //조향-작동상태-스티어링펌프
        DTS_157101: false, //전기-발전기출력
        DTS_157201: false, //전기-시동모터
        DTS_159101: false, //고전원전기장치-충전구절연상태
        DTS_159201: false, //고전원전기장치-구동축전지격리상태
        DTS_159301: false, //고전원전기장치-고전원전기배선상태
        DTS_158101: false, //연료-연료누출(LP가스포함)
      }
    },
    Electric: {
      code: '5',
      defaultValues: {
        TOT_110401: '', //일산화탄소상태
        TOT_110402: '', //일산화탄소항목
        TOT_110411: '', //탄화수소상태
        TOT_110412: '', //탄화수소항목
        TOT_110421: '', //매연상태
        TOT_110422: '', //매연항목
        DTS_152101: '', //원동기-작동상태(공회전)
        DTS_152201: '', //원동기-오일누유-로커암커버
        DTS_152202: '', //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: '', //원동기-오일누유-오일팬
        DTS_152301: '', //원동기-오일유량
        DTS_152401: '', //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: '', //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: '', //변속기-자동변속기-오일누유
        DTS_153102: '', //변속기-자동변속기-오일유량및상태
        DTS_153103: '1', //변속기-자동변속기-작동상태(공회전)
        DTS_153201: '1', //변속기-수동변속기-오일누유
        DTS_153202: '1', //변속기-수동변속기-기어변속장치
        DTS_153203: '', //변속기-수동변속기-오일유량및상태
        DTS_153204: '1', //변속기-수동변속기-작동상태(공회전)
        DTS_154101: '1', //동력전달-클러치어셈블리
        DTS_155101: '', //조향-동력조향작동오일누유
        DTS_155202: '', //조향-작동상태-스티어링펌프
        DTS_157101: '', //전기-발전기출력
        DTS_157201: '', //전기-시동모터
        DTS_159101: '1', //고전원전기장치-충전구절연상태
        DTS_159201: '1', //고전원전기장치-구동축전지격리상태
        DTS_159301: '1', //고전원전기장치-고전원전기배선상태
        DTS_158101: '', //연료-연료누출(LP가스포함)
      },
      disabled: {
        DTS_152101: true, //원동기-작동상태(공회전)
        DTS_152201: true, //원동기-오일누유-로커암커버
        DTS_152202: true, //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: true, //원동기-오일누유-오일팬
        DTS_152301: true, //원동기-오일유량
        DTS_152401: true, //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: true, //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: true, //변속기-자동변속기-오일누유
        DTS_153102: true, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: false, //변속기-수동변속기-오일누유
        DTS_153202: false, //변속기-수동변속기-기어변속장치
        DTS_153203: true, //변속기-수동변속기-오일유량및상태
        DTS_153204: false, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: false, //동력전달-클러치어셈블리
        DTS_155101: true, //조향-동력조향작동오일누유
        DTS_155202: true, //조향-작동상태-스티어링펌프
        DTS_157101: true, //전기-발전기출력
        DTS_157201: true, //전기-시동모터
        DTS_159101: false, //고전원전기장치-충전구절연상태
        DTS_159201: false, //고전원전기장치-구동축전지격리상태
        DTS_159301: false, //고전원전기장치-고전원전기배선상태
        DTS_158101: true, //연료-연료누출(LP가스포함)
      }
    },
    Hydrogen: {
      code: '6',
      defaultValues: {
        TOT_110401: '', //일산화탄소상태
        TOT_110402: '', //일산화탄소항목
        TOT_110411: '', //탄화수소상태
        TOT_110412: '', //탄화수소항목
        TOT_110421: '', //매연상태
        TOT_110422: '', //매연항목
        DTS_152101: '1', //원동기-작동상태(공회전)
        DTS_152201: '1', //원동기-오일누유-로커암커버
        DTS_152202: '1', //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: '1', //원동기-오일누유-오일팬
        DTS_152301: '1', //원동기-오일유량
        DTS_152401: '1', //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: '', //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: '1', //변속기-자동변속기-오일누유
        DTS_153102: '1', //변속기-자동변속기-오일유량및상태
        DTS_153103: '1', //변속기-자동변속기-작동상태(공회전)
        DTS_153201: '1', //변속기-수동변속기-오일누유
        DTS_153202: '1', //변속기-수동변속기-기어변속장치
        DTS_153203: '', //변속기-수동변속기-오일유량및상태
        DTS_153204: '1', //변속기-수동변속기-작동상태(공회전)
        DTS_154101: '1', //동력전달-클러치어셈블리
        DTS_155101: '1', //조향-동력조향작동오일누유
        DTS_155202: '1', //조향-작동상태-스티어링펌프
        DTS_157101: '1', //전기-발전기출력
        DTS_157201: '1', //전기-시동모터
        DTS_159101: '1', //고전원전기장치-충전구절연상태
        DTS_159201: '1', //고전원전기장치-구동축전지격리상태
        DTS_159301: '1', //고전원전기장치-고전원전기배선상태
        DTS_158101: '1', //연료-연료누출(LP가스포함)
      },
      disabled: {
        DTS_152101: false, //원동기-작동상태(공회전)
        DTS_152201: false, //원동기-오일누유-로커암커버
        DTS_152202: false, //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: false, //원동기-오일누유-오일팬
        DTS_152301: false, //원동기-오일유량
        DTS_152401: false, //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: true, //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: false, //변속기-수동변속기-오일누유
        DTS_153202: false, //변속기-수동변속기-기어변속장치
        DTS_153203: true, //변속기-수동변속기-오일유량및상태
        DTS_153204: false, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: false, //동력전달-클러치어셈블리
        DTS_155101: false, //조향-동력조향작동오일누유
        DTS_155202: false, //조향-작동상태-스티어링펌프
        DTS_157101: false, //전기-발전기출력
        DTS_157201: false, //전기-시동모터
        DTS_159101: false, //고전원전기장치-충전구절연상태
        DTS_159201: false, //고전원전기장치-구동축전지격리상태
        DTS_159301: false, //고전원전기장치-고전원전기배선상태
        DTS_158101: false, //연료-연료누출(LP가스포함)
      }
    },
    ETC: {
      code: '7',
      defaultValues: {
        TOT_110401: '', //일산화탄소상태
        TOT_110402: '', //일산화탄소항목
        TOT_110411: '', //탄화수소상태
        TOT_110412: '', //탄화수소항목
        TOT_110421: '', //매연상태
        TOT_110422: '', //매연항목
        DTS_152101: '1', //원동기-작동상태(공회전)
        DTS_152201: '1', //원동기-오일누유-로커암커버
        DTS_152202: '1', //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: '1', //원동기-오일누유-오일팬
        DTS_152301: '1', //원동기-오일유량
        DTS_152401: '1', //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: '', //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: '1', //변속기-자동변속기-오일누유
        DTS_153102: '1', //변속기-자동변속기-오일유량및상태
        DTS_153103: '1', //변속기-자동변속기-작동상태(공회전)
        DTS_153201: '1', //변속기-수동변속기-오일누유
        DTS_153202: '1', //변속기-수동변속기-기어변속장치
        DTS_153203: '', //변속기-수동변속기-오일유량및상태
        DTS_153204: '1', //변속기-수동변속기-작동상태(공회전)
        DTS_154101: '1', //동력전달-클러치어셈블리
        DTS_155101: '1', //조향-동력조향작동오일누유
        DTS_155202: '1', //조향-작동상태-스티어링펌프
        DTS_157101: '1', //전기-발전기출력
        DTS_157201: '1', //전기-시동모터
        DTS_159101: '1', //고전원전기장치-충전구절연상태
        DTS_159201: '1', //고전원전기장치-구동축전지격리상태
        DTS_159301: '1', //고전원전기장치-고전원전기배선상태
        DTS_158101: '1', //연료-연료누출(LP가스포함)
      },
      disabled: {
        DTS_152101: false, //원동기-작동상태(공회전)
        DTS_152201: false, //원동기-오일누유-로커암커버
        DTS_152202: false, //원동기-오일누유-실린더헤드/가스켓
        DTS_152203: false, //원동기-오일누유-오일팬
        DTS_152301: false, //원동기-오일유량
        DTS_152401: false, //원동기-냉각수누수-실린더헤드/가스켓
        DTS_152501: false, //원동기-고압펌프(커먼레일)-디젤엔진
        DTS_153101: false, //변속기-자동변속기-오일누유
        DTS_153102: false, //변속기-자동변속기-오일유량및상태
        DTS_153103: false, //변속기-자동변속기-작동상태(공회전)
        DTS_153201: false, //변속기-수동변속기-오일누유
        DTS_153202: false, //변속기-수동변속기-기어변속장치
        DTS_153203: true, //변속기-수동변속기-오일유량및상태
        DTS_153204: false, //변속기-수동변속기-작동상태(공회전)
        DTS_154101: false, //동력전달-클러치어셈블리
        DTS_155101: false, //조향-동력조향작동오일누유
        DTS_155202: false, //조향-작동상태-스티어링펌프
        DTS_157101: false, //전기-발전기출력
        DTS_157201: false, //전기-시동모터
        DTS_159101: false, //고전원전기장치-충전구절연상태
        DTS_159201: false, //고전원전기장치-구동축전지격리상태
        DTS_159301: false, //고전원전기장치-고전원전기배선상태
        DTS_158101: false, //연료-연료누출(LP가스포함)
      }
    },
    fromCode: (code) => {
      let fuelType = null;
      for(let key of Object.keys(FUEL_TYPE)) {
        if (FUEL_TYPE[key]['code'] === code) fuelType = FUEL_TYPE[key];
      }
      return fuelType;
    },
    current: () => {
      let fuelType = null;
      for(let key of Object.keys(FUEL_TYPE)) {
        if (FUEL_TYPE[key]['code'] === currentData['BAS_080000']) fuelType = FUEL_TYPE[key];
      }
      return fuelType;
    }
  }

  const params = useParams();
  const location = useLocation();

  const [editable, setEditable] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [reservation, setReservation] = useState({});
  const [currentData, setCurrentData] = useState(DEFAULT_DATA);
  const [parts, setParts] = useState(DEFAULT_PARTS_DATA);
  const [loading, setLoading] = useState(false);
  const [carInformation, setCarInformation] = useState(null);
  const [selectState, setSelectState] = useState({
    open: false,
    close: () => {
      setSelectState(prevState => ({...prevState, open: false}));
    },
    items: STATUS_CODES
  });

  const [storageModalOptions, setStorageModalOptions] = useState({
    open: false,
    close: () => {
      setStorageModalOptions(prevState => {
        return {
          ...prevState,
          open: false,
        }
      });
    },
    header: '알림',
    content: '임시저장된 데이터가 있습니다. 불러오시겠습니까?',
    callback: () => {
      const storageData = localStorage.getItem(`pcr_${params.reservationNumber}`);
      const parsedData = JSON.parse(storageData);
      const molitData = _.get(parsedData, 'molit');
      const partsData = _.get(parsedData, 'parts');

      setCurrentData(prevState => {
        return {
          ...prevState,
          ...molitData,
        }
      });

      setParts(prevState => {
        return {
          ...prevState,
          ...partsData,
        }
      });

      storageModalOptions.close();
    }
  })

  useEffect(() => {
    const query = queryString.parse(location.search);
    //console.log(query && query.editable && JSON.parse(query.editable), !!query.editable);
    if (query && query.editable) {
      setEditable(JSON.parse(query.editable));
    }

    retrieveReservation();
  }, []);

  window.onunload = () => {
    if (isSaved) {
      localStorage.removeItem(`pcr_${params.reservationNumber}`);
    } else {
      localStorage.setItem(`pcr_${params.reservationNumber}`, JSON.stringify({
        parts: parts,
        molit: currentData,
      }));
    }
  }

  useEffect(() => {
    changeValue("BAS_070000", _.get(carInformation, 'gear'));
    changeValue("BAS_080000", _.get(carInformation, 'fuel'));
  }, [carInformation])

  const retrieveReservation = async () => {
    try {
      const response = await axios.get(`/apis/external/reservation/${params.reservationNumber}?check-state=N`);
      if (response.status !== 200) throw response

      setReservation(response.data);
      await retrieveCarInformation();
      await retrievePcrTemp();
    } catch (ex) {
      toast({
        type: 'error',
        title: 'ERROR',
        time: 2000,
        description: (_.get(ex, 'response.data.message') || _.get(ex, 'data.message') || "오류가 발생했습니다."),
      });
    }
  };

  const retrieveCarInformation = async () => {
    try {
      const response = await axios.get(`/apis/external/car-information/${params.reservationNumber}`);
      if (response.status !== 200) throw response;

      setCarInformation(response.data);
    } catch (ex) {
      toast({
        type: 'error',
        title: 'ERROR',
        time: 2000,
        description: (_.get(ex, 'response.data.message') || _.get(ex, 'data.message') || "오류가 발생했습니다."),
      });
    }
  };

  const retrievePcrTemp = async () => {
    try {
      const response = await axios.get(`/apis/external/pcr-temporary/${params.reservationNumber}`);

      if (response.status !== 200) throw response;

      let rawData = {};

      if( !_.isEmpty(response.data)) {
        rawData = JSON.parse(response.data['rawData']);
      }

      setCurrentData(prevState => {
        return {
          ...prevState,
          ..._.assign(DEFAULT_DATA, _.get(rawData, 'molit'))
        }
      });

      setParts(prevState => {
        return {
          ...prevState,
          ..._.assign(DEFAULT_PARTS_DATA, _.get(rawData, 'parts'))
        }
      });

      if(_.isEmpty(rawData)) {
        const storageData = localStorage.getItem(`pcr_${params.reservationNumber}`);
        if (storageData != null) {
          setStorageModalOptions(prevState => {
            return {
              ...prevState,
              open: true,
            }
          })
        }
      }

    } catch (ex) {
      toast({
        type: 'error',
        title: 'ERROR',
        time: 2000,
        description: (_.get(ex, 'response.data.message') || _.get(ex, 'data.message') || "오류가 발생했습니다."),
      });
    }
  };

  const savePerformanceCheckRecord = async () => {
    setLoading(true);
    const requestBody = {
      reservationNumber: params.reservationNumber,
      data: {
        parts: parts,
        molit: currentData
      }
    }

    try {
      const response = await axios.post('/apis/external/pcr-temporary', requestBody);

      if (response.status !== 200) throw response;

      toast({
        size: 'large',
        type: 'success',
        title: '완료',
        time: 2000,
        description: '점검 내용이 저장되었습니다.',
      }, () => setLoading(false));

      setIsSaved(true);
    } catch (ex) {
      toast({
        type: 'error',
        title: 'ERROR',
        time: 2000,
        description: (_.get(ex, 'response.data.message') || _.get(ex, 'data.message') || "오류가 발생했습니다."),
      }, () => setLoading(false));
    }
  }

  const changeControlEnable = (code) => {
    return GEAR_TYPE.current()?.disabled[code]
      || FUEL_TYPE.current()?.disabled[code];
  }

  const changeValue = (code, value) => {
    if (!editable) return false;

    setCurrentData(prevState => {
      let currentState = {
        ...prevState,
        [code]: (prevState?.code === value) ? null : value,
      }

      if (code ==='BAS_070000' && prevState[code] !== value) {
        let gearType = GEAR_TYPE.fromCode(value);

        for(let key in gearType.defaultValues) {
          let enable = !(gearType.disabled[key] || FUEL_TYPE.fromCode(prevState['BAS_080000'])?.disabled[key]);
          currentState[key] = enable ? gearType.defaultValues[key] : '';
        }
      }

      if (code ==='BAS_080000' && prevState[code] !== value) {
        let fuelType = FUEL_TYPE.fromCode(value);

        for(let key in fuelType.defaultValues) {
          let enable = !(GEAR_TYPE.fromCode(prevState['BAS_070000'])?.disabled[key] || fuelType.disabled[key]);
          currentState[key] = enable ? fuelType.defaultValues[key] : '';
        }
      }

      return currentState;
    });
  }

  const getColor= (code, value) => {
    return _.get(currentData, code, undefined) === value ? '#db2828': '#000'
  }

  const eventHandler = {
    onClickSave: () => {
      savePerformanceCheckRecord();
    }
  }

  return (
    <Container text>
      <Dimmer active={loading} page/>
      <Segment color="teal" style={{marginTop: '10px'}}>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={2}>예약번호</Table.Cell>
              <Table.Cell><Header as="h3">{reservation.reservationNumber}</Header></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>차량번호</Table.Cell>
              <Table.Cell><Header as="h3">{reservation.licensePlateNumber}</Header></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>정비소</Table.Cell>
              <Table.Cell><Header as="h3">{reservation.centerName}</Header></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Message warning><Icon name="warning sign" /> 예약 정보를 확인 후 진행해주세요!</Message>
      </Segment>

      <Divider horizontal>
        <Header as='h3'>기본 사양</Header>
      </Divider>

      <Grid as={Segment}>
        <Grid.Row>
          <Grid.Column>
            <Header as={'h4'}>주행거리</Header>
            <div style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 12,
            }}>
              <span>
                <Input
                  label={{ basic: true, content: 'km' }}
                  labelPosition='right'
                  readOnly={!editable}
                  value={_.get(currentData, 'TOT_110202')}
                  onChange={(e, {value}) => {changeValue("TOT_110202", value)}}
                  placeholder='주행거리'
                />
              </span>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider horizontal>
        <Header as='h3'>차량 외판</Header>
      </Divider>

      <Message style={{background: '#fff'}}>
        차량의 외판에 특이사항이 있는 경우 체크해주세요.
      </Message>
      <Grid as={Segment} celled={'internally'}>
        <Grid.Row>
          <Grid.Column width={8} textAlign={'center'}>
            {/*<Image src={'/images/car_parts/left.png'} size={'small'} centered />*/}
            <div style={{
              display: 'inline-block',
              position:'relative',
              width:'150px',
              height:'300px',
              background:'url(\'/images/car_parts/left.png\')',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}>
              {
                _.map(parts.LEFT, (o, key) => {
                  return (
                    <Button key={`left-${key}`}
                           circular
                           style={{
                             ...o,
                             padding: 0,
                             position:'absolute',
                             color: '#000',
                             background: '#fff',
                             border: '1px solid #999',
                             width: '30px',
                             height: '30px',
                             opacity: '1.0!important',
                           }}
                           onClick={() => {
                             if (!editable) return false;

                             setParts(prevState => {
                               return {
                                 ...prevState,
                                 LEFT: {
                                   ...prevState.LEFT,
                                   [key]: {
                                     ...prevState.LEFT[key],
                                     status: o.status === '' ? 'X' : ''
                                   }
                                 }
                               }
                             });
                    }}>{
                      o.status !== '' ? <Icon name={'check'} color={'red'} style={{marginLeft:'5px'}}/> : o.code
                    }</Button>
                  )
                })
              }
            </div>
          </Grid.Column>

          <Grid.Column width={8} textAlign={'left'}>
            <Header>
              좌측
            </Header>
            <List>
            {
              _.map(parts.LEFT, (o, key) => {
                return (
                    <List.Item key={`left-button-${key}`} style={{
                      color: o.status === '' ? '#000' : '#db2828',
                      marginBottom: '5px',
                      fontWeight: o.status === '' ? 'normal' : 'bold',
                    }}>{`${o.code}. ${o.name}`}</List.Item>
                )
              })
            }
            </List>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8} textAlign={'center'}>
            {/*<Image src={'/images/car_parts/left.png'} size={'small'} centered />*/}
            <div style={{
              display: 'inline-block',
              position:'relative',
              width:'150px',
              height:'300px',
              background:'url(\'/images/car_parts/right.png\')',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}>
              {
                _.map(parts.RIGHT, (o, key) => {
                  return (
                    <Button key={`right-${key}`}
                            circular
                            color='twitter'
                            style={{
                              padding: 0,
                              position:'absolute',
                              color: '#000',
                              background: '#fff',
                              border: '1px solid #999',
                              width: '30px',
                              height: '30px',
                              ...o,
                            }}
                            onClick={() => {
                              setParts(prevState => {
                                return {
                                  ...prevState,
                                  RIGHT: {
                                    ...prevState.RIGHT,
                                    [key]: {
                                      ...prevState.RIGHT[key],
                                      status: o.status === '' ? 'X' : ''
                                    }
                                  }
                                }
                              });
                            }}>{
                      o.status !== '' ? <Icon name={'check'} color={'red'} style={{marginLeft:'5px'}}/> : o.code
                    }</Button>
                  )
                })
              }
            </div>
          </Grid.Column>

          <Grid.Column width={8} textAlign={'left'}>
            <Header>
              우측
            </Header>
            <List>
              {
                _.map(parts.RIGHT, (o, key) => {
                  return (
                    <List.Item key={`right-button-${key}`} style={{
                      color: o.status === '' ? '#000' : '#db2828',
                      marginBottom: '5px',
                      fontWeight: o.status === '' ? 'normal' : 'bold',
                    }}>{`${o.code}. ${o.name}`}</List.Item>
                  )
                })
              }
            </List>
          </Grid.Column>
        </Grid.Row>


        <Grid.Row>
          <Grid.Column width={8} textAlign={'center'}>
            {/*<Image src={'/images/car_parts/left.png'} size={'small'} centered />*/}
            <div style={{
              display: 'inline-block',
              position:'relative',
              width:'150px',
              height:'300px',
              background:'url(\'/images/car_parts/top.png\')',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}>
              {
                _.map(parts.TOP, (o, key) => {
                  return (
                    <Button key={`top-${key}`}
                            circular
                            color='twitter'
                            style={{
                              padding: 0,
                              position:'absolute',
                              color: '#000',
                              background: '#fff',
                              border: '1px solid #999',
                              width: '30px',
                              height: '30px',
                              ...o,
                            }}
                            onClick={() => {
                              setParts(prevState => {
                                return {
                                  ...prevState,
                                  TOP: {
                                    ...prevState.TOP,
                                    [key]: {
                                      ...prevState.TOP[key],
                                      status: o.status === '' ? 'X' : ''
                                    }
                                  }
                                }
                              });
                            }}>{
                      o.status !== '' ? <Icon name={'check'} color={'red'} style={{marginLeft:'5px'}}/> : o.code
                    }</Button>
                  )
                })
              }
            </div>
          </Grid.Column>

          <Grid.Column width={8} textAlign={'left'}>
            <Header>
              상판
            </Header>
            <List>
              {
                _.map(parts.TOP, (o, key) => {
                  return (
                    <List.Item key={`top-button-${key}`} style={{
                      color: o.status === '' ? '#000' : '#db2828',
                      marginBottom: '5px',
                      fontWeight: o.status === '' ? 'normal' : 'bold',
                    }}>{`${o.code}. ${o.name}`}</List.Item>
                  )
                })
              }
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider horizontal>
        <Header as='h3'>원동기</Header>
      </Divider>

      <Grid as={Segment} style={{background:'#fff'}}>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as={'h4'}>작동상태(공회전)</Header>
            <div style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 12,
            }}>
              <span>
                <Checkbox label={'양호'}
                          checked={_setChecked(currentData, 'DTS_152101', '1')}
                          disabled={changeControlEnable('DTS_152101')}
                          onChange={() => changeValue('DTS_152101', '1')} />
              </span>
              <span style={_setWarningTextColor(currentData, 'DTS_152101', '2')}>
                <Checkbox label={<label style={{color: getColor('DTS_152101', '2')}}>불량</label>}
                          checked={_setChecked(currentData, 'DTS_152101', '2')}
                          disabled={changeControlEnable('DTS_152101')}
                          onChange={() => changeValue('DTS_152101', '2')} />
              </span>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
              <Header as={'h4'}>오일누유</Header>
              <Segment.Group>
                <Segment>
                  <Header as={'h4'}>실린더 커버(로커암 커버)</Header>
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 12,
                  }}>
                    <span>
                      <Checkbox label={'없음'}
                                checked={_setChecked(currentData, 'DTS_152201', '1')}
                                disabled={changeControlEnable('DTS_152201')}
                                onChange={() => changeValue('DTS_152201', '1')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_152201', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_152201', '2')}}>미세누유</label>}
                                checked={_setChecked(currentData, 'DTS_152201', '2')}
                                disabled={changeControlEnable('DTS_152201')}
                                onChange={() => changeValue('DTS_152201', '2')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_152201', '3')}>
                      <Checkbox label={<label style={{color: getColor('DTS_152201', '3')}}>누유</label>}
                                checked={_setChecked(currentData, 'DTS_152201', '3')}
                                disabled={changeControlEnable('DTS_152201')}
                                onChange={() => changeValue('DTS_152201', '3')} />
                    </span>
                  </div>
                </Segment>

                <Segment>
                  <Header as={'h4'}>실린더 헤드 / 가스켓</Header>
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 12,
                  }}>
                    <span>
                      <Checkbox label={'없음'}
                                checked={_setChecked(currentData, 'DTS_152202', '1')}
                                disabled={changeControlEnable('DTS_152202')}
                                onChange={() => changeValue('DTS_152202', '1')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_152202', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_152202', '2')}}>미세누유</label>}
                                checked={_setChecked(currentData, 'DTS_152202', '2')}
                                disabled={changeControlEnable('DTS_152202')}
                                onChange={() => changeValue('DTS_152202', '2')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_152202', '3')}>
                      <Checkbox label={<label style={{color: getColor('DTS_152202', '3')}}>누유</label>}
                                checked={_setChecked(currentData, 'DTS_152202', '3')}
                                disabled={changeControlEnable('DTS_152202')}
                                onChange={() => changeValue('DTS_152202', '3')} />
                    </span>
                  </div>
                </Segment>

                <Segment>
                  <Header as={'h4'}>실린더 블록 / 오일팬</Header>
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 12,
                  }}>
                    <span>
                      <Checkbox label={'없음'}
                                checked={_setChecked(currentData, 'DTS_152203', '1')}
                                disabled={changeControlEnable('DTS_152203')}
                                onChange={() => changeValue('DTS_152203', '1')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_152203', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_152203', '2')}}>미세누유</label>}
                                checked={_setChecked(currentData, 'DTS_152203', '2')}
                                disabled={changeControlEnable('DTS_152203')}
                                onChange={() => changeValue('DTS_152203', '2')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_152203', '3')}>
                      <Checkbox label={<label style={{color: getColor('DTS_152203', '3')}}>누유</label>}
                                checked={_setChecked(currentData, 'DTS_152203', '3')}
                                disabled={changeControlEnable('DTS_152203')}
                                onChange={() => changeValue('DTS_152203', '3')} />
                    </span>
                  </div>
                </Segment>

                <Segment>
                  <Header as={'h4'}>오일 유량</Header>
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 12,
                  }}>
                    <span>
                      <Checkbox label={'적정'}
                                checked={_setChecked(currentData, 'DTS_152301', '1')}
                                disabled={changeControlEnable('DTS_152301')}
                                onChange={() => changeValue('DTS_152301', '1')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_152301', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_152301', '2')}}>부족</label>}
                                checked={_setChecked(currentData, 'DTS_152301', '2')}
                                disabled={changeControlEnable('DTS_152301')}
                                onChange={() => changeValue('DTS_152301', '2')} />
                    </span>
                  </div>
                </Segment>
              </Segment.Group>

          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Header as={'h4'}>냉각수 누수</Header>
            <Segment.Group>
              <Segment>
                <Header as={'h4'}>실린더 헤드 / 가스켓</Header>
                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 12,
                }}>
                    <span>
                      <Checkbox label={'없음'}
                                checked={_setChecked(currentData, 'DTS_152401', '1')}
                                disabled={changeControlEnable('DTS_152401')}
                                onChange={() => changeValue('DTS_152401', '1')} />
                    </span>
                  <span style={_setWarningTextColor(currentData, 'DTS_152401', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_152401', '2')}}>미세누수</label>}
                                checked={_setChecked(currentData, 'DTS_152401', '2')}
                                disabled={changeControlEnable('DTS_152401')}
                                onChange={() => changeValue('DTS_152401', '2')} />
                    </span>
                  <span style={_setWarningTextColor(currentData, 'DTS_152401', '3')}>
                      <Checkbox label={<label style={{color: getColor('DTS_152401', '3')}}>누수</label>}
                                checked={_setChecked(currentData, 'DTS_152401', '3')}
                                disabled={changeControlEnable('DTS_152401')}
                                onChange={() => changeValue('DTS_152401', '3')} />
                    </span>
                </div>
              </Segment>

              <Segment>
                <Header as={'h4'}>워터펌프</Header>
                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 12,
                }}>
                    <span>
                      <Checkbox label={'없음'}
                                checked={_setChecked(currentData, 'DTS_152402', '1')}
                                disabled={changeControlEnable('DTS_152402')}
                                onChange={() => changeValue('DTS_152402', '1')} />
                    </span>
                  <span style={_setWarningTextColor(currentData, 'DTS_152402', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_152402', '2')}}>미세누수</label>}
                                checked={_setChecked(currentData, 'DTS_152402', '2')}
                                disabled={changeControlEnable('DTS_152402')}
                                onChange={() => changeValue('DTS_152402', '2')} />
                    </span>
                  <span style={_setWarningTextColor(currentData, 'DTS_152402', '3')}>
                      <Checkbox label={<label style={{color: getColor('DTS_152402', '3')}}>누수</label>}
                                checked={_setChecked(currentData, 'DTS_152402', '3')}
                                disabled={changeControlEnable('DTS_152402')}
                                onChange={() => changeValue('DTS_152402', '3')} />
                    </span>
                </div>
              </Segment>

              <Segment>
                <Header as={'h4'}>라디에이터</Header>
                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 12,
                }}>
                    <span>
                      <Checkbox label={'없음'}
                                checked={_setChecked(currentData, 'DTS_152403', '1')}
                                disabled={changeControlEnable('DTS_152403')}
                                onChange={() => changeValue('DTS_152403', '1')} />
                    </span>
                  <span style={_setWarningTextColor(currentData, 'DTS_152403', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_152403', '2')}}>미세누수</label>}
                                checked={_setChecked(currentData, 'DTS_152403', '2')}
                                disabled={changeControlEnable('DTS_152403')}
                                onChange={() => changeValue('DTS_152403', '2')} />
                    </span>
                  <span style={_setWarningTextColor(currentData, 'DTS_152403', '3')}>
                      <Checkbox label={<label style={{color: getColor('DTS_152403', '3')}}>누수</label>}
                                checked={_setChecked(currentData, 'DTS_152403', '3')}
                                disabled={changeControlEnable('DTS_152403')}
                                onChange={() => changeValue('DTS_152403', '3')} />
                    </span>
                </div>
              </Segment>

              <Segment>
                <Header as={'h4'}>냉각수 수량</Header>
                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 12,
                }}>
                    <span>
                      <Checkbox label={'적정'}
                                checked={_setChecked(currentData, 'DTS_152404', '1')}
                                disabled={changeControlEnable('DTS_152404')}
                                onChange={() => changeValue('DTS_152404', '1')} />
                    </span>
                  <span style={_setWarningTextColor(currentData, 'DTS_152404', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_152404', '2')}}>부족</label>}
                                checked={_setChecked(currentData, 'DTS_152404', '2')}
                                disabled={changeControlEnable('DTS_152404')}
                                onChange={() => changeValue('DTS_152404', '2')} />
                    </span>
                </div>
              </Segment>

            </Segment.Group>

          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Header as={'h4'}>커먼레일</Header>
            <div style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 12,
            }}>
                    <span>
                      <Checkbox label={'양호'}
                                checked={_setChecked(currentData, 'DTS_152501', '1')}
                                disabled={changeControlEnable('DTS_152501')}
                                onChange={() => changeValue('DTS_152501', '1')} />
                    </span>
              <span style={_setWarningTextColor(currentData, 'DTS_152501', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_152501', '2')}}>불량</label>}
                                checked={_setChecked(currentData, 'DTS_152501', '2')}
                                disabled={changeControlEnable('DTS_152501')}
                                onChange={() => changeValue('DTS_152501', '2')} />
                    </span>
            </div>
          </Grid.Column>
        </Grid.Row>

      </Grid>

      <Divider horizontal>
        <Header as='h3'>
          변속기
        </Header>
      </Divider>

      <Grid as={Segment}>
        {
          currentData['BAS_070000'] === "1" &&
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as={'h4'}>자동변속기 (A/T)</Header>
              <Segment.Group>
                <Segment>
                  <Header as={'h4'}>오일누유</Header>
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 12,
                  }}>
                    <span>
                      <Checkbox label={'없음'}
                                checked={_setChecked(currentData, 'DTS_153101', '1')}
                                disabled={changeControlEnable('DTS_153101')}
                                onChange={() => changeValue('DTS_153101', '1')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_153101', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_153101', '2')}}>미세누유</label>}
                                checked={_setChecked(currentData, 'DTS_153101', '2')}
                                disabled={changeControlEnable('DTS_153101')}
                                onChange={() => changeValue('DTS_153101', '2')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_153101', '3')}>
                      <Checkbox label={<label style={{color: getColor('DTS_153101', '3')}}>누유</label>}
                                checked={_setChecked(currentData, 'DTS_153101', '3')}
                                disabled={changeControlEnable('DTS_153101')}
                                onChange={() => changeValue('DTS_153101', '3')} />
                    </span>
                  </div>
                </Segment>

                <Segment>
                  <Header as={'h4'}>오일유량 및 상태</Header>
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 12,
                  }}>
                    <span>
                      <Checkbox label={'적정'}
                                checked={_setChecked(currentData, 'DTS_153102', '1')}
                                disabled={changeControlEnable('DTS_153102')}
                                onChange={() => changeValue('DTS_153102', '1')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_153102', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_153102', '2')}}>부족</label>}
                                checked={_setChecked(currentData, 'DTS_153102', '2')}
                                disabled={changeControlEnable('DTS_153102')}
                                onChange={() => changeValue('DTS_153102', '2')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_153102', '3')}>
                      <Checkbox label={<label style={{color: getColor('DTS_153102', '3')}}>과다</label>}
                                checked={_setChecked(currentData, 'DTS_153102', '3')}
                                disabled={changeControlEnable('DTS_153102')}
                                onChange={() => changeValue('DTS_153102', '3')} />
                    </span>
                  </div>
                </Segment>

                <Segment>
                  <Header as={'h4'}>작동상태(공회전)</Header>
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 12,
                  }}>
                    <span>
                      <Checkbox label={'양호'}
                                checked={_setChecked(currentData, 'DTS_153103', '1')}
                                disabled={changeControlEnable('DTS_153103')}
                                onChange={() => changeValue('DTS_153103', '1')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_153103', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_153103', '2')}}>불량</label>}
                                checked={_setChecked(currentData, 'DTS_153103', '2')}
                                disabled={changeControlEnable('DTS_153103')}
                                onChange={() => changeValue('DTS_153103', '2')} />
                    </span>
                  </div>
                </Segment>
              </Segment.Group>
            </Grid.Column>
          </Grid.Row>
        }

        {
          currentData['BAS_070000'] === "2" &&
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as={'h4'}>수동변속기 (M/T)</Header>
              <Segment.Group>
                <Segment>
                  <Header as={'h4'}>오일누유</Header>
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 12,
                  }}>
                    <span>
                      <Checkbox label={'없음'}
                                checked={_setChecked(currentData, 'DTS_153201', '1')}
                                disabled={changeControlEnable('DTS_153201')}
                                onChange={() => changeValue('DTS_153201', '1')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_153201', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_153201', '2')}}>미세누유</label>}
                                checked={_setChecked(currentData, 'DTS_153201', '2')}
                                disabled={changeControlEnable('DTS_153201')}
                                onChange={() => changeValue('DTS_153201', '2')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_153201', '3')}>
                      <Checkbox label={<label style={{color: getColor('DTS_153201', '3')}}>누유</label>}
                                checked={_setChecked(currentData, 'DTS_153201', '3')}
                                disabled={changeControlEnable('DTS_153201')}
                                onChange={() => changeValue('DTS_153201', '3')} />
                    </span>
                  </div>
                </Segment>

                <Segment>
                  <Header as={'h4'}>기어변속장치</Header>
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 12,
                  }}>
                    <span>
                      <Checkbox label={'양호'}
                                checked={_setChecked(currentData, 'DTS_153202', '1')}
                                disabled={changeControlEnable('DTS_153202')}
                                onChange={() => changeValue('DTS_153202', '1')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_153202', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_153202', '2')}}>불량</label>}
                                checked={_setChecked(currentData, 'DTS_153202', '2')}
                                disabled={changeControlEnable('DTS_153202')}
                                onChange={() => changeValue('DTS_153202', '2')} />
                    </span>
                  </div>
                </Segment>

                <Segment>
                  <Header as={'h4'}>오일유량 및 상태</Header>
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 12,
                  }}>
                    <span>
                      <Checkbox label={'적정'}
                                checked={_setChecked(currentData, 'DTS_153203', '1')}
                                disabled={changeControlEnable('DTS_153203')}
                                onChange={() => changeValue('DTS_153203', '1')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_153203', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_153203', '2')}}>부족</label>}
                                checked={_setChecked(currentData, 'DTS_153203', '2')}
                                disabled={changeControlEnable('DTS_153203')}
                                onChange={() => changeValue('DTS_153203', '2')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_153203', '3')}>
                      <Checkbox label={<label style={{color: getColor('DTS_153203', '3')}}>과다</label>}
                                checked={_setChecked(currentData, 'DTS_153203', '3')}
                                disabled={changeControlEnable('DTS_153203')}
                                onChange={() => changeValue('DTS_153203', '3')} />
                    </span>
                  </div>
                </Segment>

                <Segment>
                  <Header as={'h4'}>작동상태(공회전)</Header>
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 12,
                  }}>
                    <span>
                      <Checkbox label={'양호'}
                                checked={_setChecked(currentData, 'DTS_153204', '1')}
                                disabled={changeControlEnable('DTS_153204')}
                                onChange={() => changeValue('DTS_153204', '1')} />
                    </span>
                    <span style={_setWarningTextColor(currentData, 'DTS_153204', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_153204', '2')}}>불량</label>}
                                checked={_setChecked(currentData, 'DTS_153204', '2')}
                                disabled={changeControlEnable('DTS_153204')}
                                onChange={() => changeValue('DTS_153204', '2')} />
                    </span>
                  </div>
                </Segment>
              </Segment.Group>
            </Grid.Column>
          </Grid.Row>
        }
      </Grid>

      <Divider horizontal>
        <Header as='h3'>
          동력전달
        </Header>
      </Divider>

      <Grid as={Segment}>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as={'h4'}>클러치 어셈블리</Header>
            <div style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 12,
            }}>
                    <span>
                      <Checkbox label={'양호'}
                                checked={_setChecked(currentData, 'DTS_154101', '1')}
                                disabled={changeControlEnable('DTS_154101')}
                                onChange={() => changeValue('DTS_154101', '1')} />
                    </span>
              <span style={_setWarningTextColor(currentData, 'DTS_154101', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_154101', '2')}}>불량</label>}
                                checked={_setChecked(currentData, 'DTS_154101', '2')}
                                disabled={changeControlEnable('DTS_154101')}
                                onChange={() => changeValue('DTS_154101', '2')} />
                    </span>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Header as={'h4'}>등속조인트</Header>
            <div style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 12,
            }}>
                    <span>
                      <Checkbox label={'양호'}
                                checked={_setChecked(currentData, 'DTS_154201', '1')}
                                disabled={changeControlEnable('DTS_154201')}
                                onChange={() => changeValue('DTS_154201', '1')} />
                    </span>
              <span style={_setWarningTextColor(currentData, 'DTS_154201', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_154201', '2')}}>불량</label>}
                                checked={_setChecked(currentData, 'DTS_154201', '2')}
                                disabled={changeControlEnable('DTS_154201')}
                                onChange={() => changeValue('DTS_154201', '2')} />
                    </span>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Header as={'h4'}>추진축 및 베어링</Header>
            <div style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 12,
            }}>
                    <span>
                      <Checkbox label={'양호'}
                                checked={_setChecked(currentData, 'DTS_154301','1')}
                                disabled={changeControlEnable('DTS_154301')}
                                onChange={() => changeValue('DTS_154301', '1')} />
                    </span>
              <span style={_setWarningTextColor(currentData, 'DTS_154301', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_154301', '2')}}>불량</label>}
                                checked={_setChecked(currentData, 'DTS_154301', '2')}
                                disabled={changeControlEnable('DTS_154301')}
                                onChange={() => changeValue('DTS_154301', '2')} />
                    </span>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Header as={'h4'}>디퍼렌셜 기어</Header>
            <div style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 12,
            }}>
                    <span>
                      <Checkbox label={'양호'}
                                checked={_setChecked(currentData, 'DTS_154401', '1')}
                                disabled={changeControlEnable('DTS_154401')}
                                onChange={() => changeValue('DTS_154401', '1')} />
                    </span>
              <span style={_setWarningTextColor(currentData, 'DTS_154401', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_154401', '2')}}>불량</label>}
                                checked={_setChecked(currentData, 'DTS_154401', '2')}
                                disabled={changeControlEnable('DTS_154401')}
                                onChange={() => changeValue('DTS_154401', '2')} />
                    </span>
            </div>
          </Grid.Column>
        </Grid.Row>

      </Grid>

      <Divider horizontal>
        <Header as='h3'>조향</Header>
      </Divider>

      <Grid as={Segment}>
        <Grid.Row>
          <Grid.Column>
            <Header as={'h4'}>동력조향 작동 오일 누유</Header>
            <div style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 12,
            }}>
                    <span>
                        <Checkbox label={'없음'}
                                  checked={_setChecked(currentData, 'DTS_155101', '1')}
                                  disabled={changeControlEnable('DTS_155101')}
                                  onChange={() => changeValue('DTS_155101', '1')} />
                    </span>
              <span style={_setWarningTextColor(currentData, 'DTS_155101', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_155101', '2')}}>미세누유</label>}
                                checked={_setChecked(currentData, 'DTS_155101', '2')}
                                disabled={changeControlEnable('DTS_155101')}
                                onChange={() => changeValue('DTS_155101', '2')} />
                    </span>
              <span style={_setWarningTextColor(currentData, 'DTS_155101', '3')}>
                <Checkbox label={<label style={{color: getColor('DTS_155101', '3')}}>누유</label>}
                          checked={_setChecked(currentData, 'DTS_155101', '3')}
                          disabled={changeControlEnable('DTS_155101')}
                          onChange={() => changeValue('DTS_155101', '3')} />
              </span>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Header as={'h4'}>작동상태</Header>
            <Segment.Group>
              <Segment>
                <Header as={'h4'}>스티어링 펌프</Header>
                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 12,
                }}>
                    <span>
                      <Checkbox label={'양호'}
                                checked={_setChecked(currentData, 'DTS_155202', '1')}
                                disabled={changeControlEnable('DTS_155202')}
                                onChange={() => changeValue('DTS_155202', '1')} />
                    </span>
                  <span style={_setWarningTextColor(currentData, 'DTS_155202', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_155202', '2')}}>불량</label>}
                                checked={_setChecked(currentData, 'DTS_155202', '2')}
                                disabled={changeControlEnable('DTS_155202')}
                                onChange={() => changeValue('DTS_155202', '2')} />
                    </span>
                </div>
              </Segment>

              <Segment>
                <Header as={'h4'}>스티어링 기어(MDPS포함)</Header>
                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 12,
                }}>
                    <span>
                      <Checkbox label={'양호'}
                                checked={_setChecked(currentData, 'DTS_155201', '1')}
                                disabled={changeControlEnable('DTS_155201')}
                                onChange={() => changeValue('DTS_155201', '1')} />
                    </span>
                  <span style={_setWarningTextColor(currentData, 'DTS_155201', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_155201', '2')}}>불량</label>}
                                checked={_setChecked(currentData, 'DTS_155201', '2')}
                                disabled={changeControlEnable('DTS_155201')}
                                onChange={() => changeValue('DTS_155201', '2')} />
                    </span>
                </div>
              </Segment>

              <Segment>
                <Header as={'h4'}>스티어링조인트</Header>
                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 12,
                }}>
                    <span>
                      <Checkbox label={'양호'}
                                checked={_setChecked(currentData, 'DTS_155204', '1')}
                                disabled={changeControlEnable('DTS_155204')}
                                onChange={() => changeValue('DTS_155204', '1')} />
                    </span>
                  <span style={_setWarningTextColor(currentData, 'DTS_155204', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_155204', '2')}}>불량</label>}
                                checked={_setChecked(currentData, 'DTS_155204', '2')}
                                disabled={changeControlEnable('DTS_155204')}
                                onChange={() => changeValue('DTS_155204', '2')} />
                    </span>
                </div>
              </Segment>

              <Segment>
                <Header as={'h4'}>파워고압호스</Header>
                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 12,
                }}>
                    <span>
                      <Checkbox label={'양호'}
                                checked={_setChecked(currentData, 'DTS_155205', '1')}
                                disabled={changeControlEnable('DTS_155205')}
                                onChange={() => changeValue('DTS_155205', '1')} />
                    </span>
                  <span style={_setWarningTextColor(currentData, 'DTS_155205', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_155205', '2')}}>불량</label>}
                                checked={_setChecked(currentData, 'DTS_155205', '2')}
                                disabled={changeControlEnable('DTS_155205')}
                                onChange={() => changeValue('DTS_155205', '2')} />
                    </span>
                </div>
              </Segment>

              <Segment>
                <Header as={'h4'}>타이로드엔드 및 볼조인트</Header>
                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 12,
                }}>
                    <span>
                      <Checkbox label={'양호'}
                                checked={_setChecked(currentData, 'DTS_155203', '1')}
                                disabled={changeControlEnable('DTS_155203')}
                                onChange={() => changeValue('DTS_155203', '1')} />
                    </span>
                  <span style={_setWarningTextColor(currentData, 'DTS_155203', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_155203', '2')}}>불량</label>}
                                checked={_setChecked(currentData, 'DTS_155203', '2')}
                                disabled={changeControlEnable('DTS_155203')}
                                onChange={() => changeValue('DTS_155203', '2')} />
                    </span>
                </div>
              </Segment>

            </Segment.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider horizontal>
        <Header as='h3'>제동</Header>
      </Divider>

      <Grid as={Segment}>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as={'h4'}>브레이크 마스터 실린더오일 누유</Header>
            <div style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 12,
            }}>
                    <span>
                      <Checkbox label={'없음'}
                                checked={_setChecked(currentData, 'DTS_156101', '1')}
                                disabled={changeControlEnable('DTS_156101')}
                                onChange={() => changeValue('DTS_156101', '1')} />
                    </span>
              <span style={_setWarningTextColor(currentData, 'DTS_156101', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_156101', '2')}}>미세누유</label>}
                                checked={_setChecked(currentData, 'DTS_156101', '2')}
                                disabled={changeControlEnable('DTS_156101')}
                                onChange={() => changeValue('DTS_156101', '2')} />
                    </span>
              <span style={_setWarningTextColor(currentData, 'DTS_156101', '3')}>
                      <Checkbox label={<label style={{color: getColor('DTS_156101', '3')}}>누유</label>}
                                checked={_setChecked(currentData, 'DTS_156101', '3')}
                                disabled={changeControlEnable('DTS_156101')}
                                onChange={() => changeValue('DTS_156101', '3')} />
                    </span>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Header as={'h4'}>브레이크 오일 누유</Header>
            <div style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 12,
            }}>
                    <span>
                      <Checkbox label={'없음'}
                                checked={_setChecked(currentData, 'DTS_156201', '1')}
                                disabled={changeControlEnable('DTS_156201')}
                                onChange={() => changeValue('DTS_156201', '1')} />
                    </span>
              <span style={_setWarningTextColor(currentData, 'DTS_156201', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_156201', '2')}}>미세누유</label>}
                                checked={_setChecked(currentData, 'DTS_156201', '2')}
                                disabled={changeControlEnable('DTS_156201')}
                                onChange={() => changeValue('DTS_156201', '2')} />
                    </span>
              <span style={_setWarningTextColor(currentData, 'DTS_156201', '3')}>
                      <Checkbox label={<label style={{color: getColor('DTS_156201', '3')}}>누유</label>}
                                checked={_setChecked(currentData, 'DTS_156201', '3')}
                                disabled={changeControlEnable('DTS_156201')}
                                onChange={() => changeValue('DTS_156201', '3')} />
                    </span>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Header as={'h4'}>배력장치 상태</Header>
            <div style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 12,
            }}>
                    <span>
                      <Checkbox label={'양호'}
                                checked={_setChecked(currentData, 'DTS_156301', '1')}
                                disabled={changeControlEnable('DTS_156301')}
                                onChange={() => changeValue('DTS_156301', '1')} />
                    </span>
              <span style={_setWarningTextColor(currentData, 'DTS_156301', '2')}>
                      <Checkbox label={<label style={{color: getColor('DTS_156301', '2')}}>불량</label>}
                                checked={_setChecked(currentData, 'DTS_156301', '2')}
                                disabled={changeControlEnable('DTS_156301')}
                                onChange={() => changeValue('DTS_156301', '2')} />
                    </span>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider horizontal>
        <Header as='h3'>점검자 특이사항</Header>
      </Divider>

      <Grid as={Segment} style={{marginBottom: '40px'}}>
        <Grid.Row>
          <Grid.Column>
            <Form>
              <TextArea value={_.get(currentData, 'BAS_990000')}
                        disabled={!editable}
                        onChange={(e, {value}) => {
                changeValue('BAS_990000', value);
              }} />
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {
        editable &&
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Button fluid
                      size={'huge'}
                      color={'teal'}
                      icon={'paper plane'}
                      content={'점검내용 전송'}
                      loading={loading}
                      onClick={eventHandler.onClickSave}
                      style={{
                        position: 'fixed',
                        borderRadius: 0,
                        left: 0,
                        bottom: 0,
                        zIndex: 999,
                        opacity: 1}} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }


      <Modal open={storageModalOptions.open}
             size={'mini'}
             closeIcon
             onClose={storageModalOptions.close}>
        <Modal.Header>{storageModalOptions.header}</Modal.Header>
        <Modal.Content>{storageModalOptions.content}</Modal.Content>
        <Modal.Actions>
          <Button content={'불러오기'} color={'blue'} onClick={storageModalOptions.callback}/>
          <Button content={'닫기'} onClick={storageModalOptions.close}/>
        </Modal.Actions>
      </Modal>

    </Container>
  );

}


export default CarPerformanceCheck;
