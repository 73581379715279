import _ from 'lodash';

export const CommonUtil = {
  Currency: {
    format(price) {
      const formatter = new Intl.NumberFormat('ko-KR', {
        style: 'currency',
        currency: 'KRW',
      });

      return formatter.format(price ? _.toNumber(price) : 0);
    },
  },
  Unit: {
    format(unit) {
      const formatter = new Intl.NumberFormat('ko-KR');

      return formatter.format(unit ? _.toNumber(unit) : 0);
    },
  },
  convertToNumber(str) {
    if (!isNaN(str)) return Number(str);
    else return str;
  },
  convertToCurrency(str) {
    if (!isNaN(str)) {
      const formatter = new Intl.NumberFormat('ko-KR');
      return formatter.format(Number(str));
    } else return str;
  },
  Validator: {
    validatePassword(password) {
      const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
      return password !== undefined && password !== null && password.trim() !== '' && regex.test(password);
    },
  },
  delay: (ms) => {
    return new Promise((r) => setTimeout(r, ms));
  }
};
