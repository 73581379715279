import React, {useEffect, useRef, useState} from 'react';
import {
  Button,
  Container, Dimmer,
  Form,
  Grid,
  Header,
  Icon, Input, Loader,
  Message, Modal,
  Segment,
  Table,
  Transition,
} from 'semantic-ui-react';
import axios from 'axios';
import {toast} from 'react-semantic-toasts';
import {useParams} from 'react-router-dom';
import DaumPostCodeModal from '../components/common/DaumPostCode';
import _ from 'lodash';
import {BASE_STYLES, CARMON_INFO} from '../Consts';

const AcceptType = {
  NONE: 'none',
  ACCEPT: 'accept',
  DENIED: 'denied',
};

const AuctionState = {
  SALES_YES: 'SY',
  SALES_NO: 'SN',
};

const CarmonDealerDeposit = () => {
  const params = useParams();
  let dealerAddress = useRef([]);

  const [reservation, setReservation] = useState({});
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [salesState, setSalesState] = useState(params.acceptType);
  const [addressOpen, setAddressOpen] = useState(false);
  const [saveAddressLoading, setSaveAddressLoading] = useState(false);
  const [consignmentAddressModalOption, setConsignmentAddressModalOption] = useState({
    open: false,
    close: () => setConsignmentAddressModalOption(prevState => { return {...prevState, open: false}}),
  });
  const [daumPostModalOptions, setDaumPostModalOptions] = useState({
    address: '',
    addressDetail: '',
    open: false,
    callback: (address, postalCode) => {
      document.getElementById('address').value = address;
      document.getElementById('addressDetail').value = '';
      setFormData(prevState => {
        return {
          ...prevState,
          address: address,
          postalCode: postalCode,
        };
      });
    },
  });



  const nopeReasonOptions = [
    {key: 1, value: 1, text: '낙찰 가격이 마음에 들지 않습니다.'},
    {key: 2, value: 2, text: '당장 차량을 판매할 수 없게 되었습니다.'},
    {key: 3, value: 3, text: '팔 생각이 없었습니다.'},
    {key: 4, value: 4, text: '기타'},
  ];

  useEffect(() => {
    retrieveReservation();
  }, []);

  const retrieveReservation = async () => {
    try {
      const response = await axios.get(`/apis/external/deposit/${params.reservationNumber}`);
      if (response.status === 200) {
        setReservation(response.data);
        setLoading(false);
        retrieveDealerAddress(response.data.latestAuctionId);
      }
    } catch (error) {
      toast({
        type: 'error',
        title: 'ERROR',
        time: 5000,
        description: error.response.data.message,
      });
    }
  };

  const retrieveDealerAddress = async (auctionId) => {
    try {
      const response = await axios.get(`/apis/dealer/address/list/${auctionId}`);
      if (response.status === 200) {
        dealerAddress.current = response.data;
      }
    } catch (error) {
      toast({
        type: 'error',
        title: 'ERROR',
        time: 5000,
        description: error.response.data.message,
      });
    }
  };

  const checkValidate = () => {
    let hasError = false;

    if (!formData.hasOwnProperty('address') || _.isEmpty(formData['address'])) {
      hasError = true;
      toast({
        type: 'warning',
        title: '확인해주세요.',
        time: 5000,
        description: '탁송 도착지 주소가 입력되지 않았습니다.',
      });
    }

    return !hasError;
  };

  const eventHandler = {
    onChangeForm: (event, {name, value}) => {
      setFormData(prevState => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    },
    onClickAddressPopup: () => {
      setAddressOpen(true);
    },
    onClickSaveAddress: () => {
      setConsignmentAddressModalOption(prevState => {
        return {
          ...prevState,
          open: true,
          callback: (addressName) => {
            setSaveAddressLoading(true);
            const data = {
              auctionId: reservation.latestAuctionId,
              addressName: addressName,
              postalCode: formData.postalCode,
              address: formData.address,
              addressDetail: formData.addressDetail
            };

            axios.post('/apis/dealer/address/save', data).then(() => {
              setSaveAddressLoading(false);
              toast({
                type: 'success',
                title: '성공',
                time: 5000,
                description: '탁송지 주소가 저장되었습니다.'
              });

              retrieveDealerAddress(reservation.latestAuctionId);
            }).catch((error) => {
              setSaveAddressLoading(false);
              toast({
                type: 'error',
                title: 'ERROR',
                time: 5000,
                description: error.response.data.message,
              });
            });
          }
        }
      });
    },
    onClickOpenDaumPostModal: () => {
      setAddressOpen(false);
      setDaumPostModalOptions(prevState => {
        return {
          ...prevState,
          open: true,
        };
      });
    },
    onSelectAddress: (selectedAddress) => {
      document.getElementById('address').value = selectedAddress.address;
      document.getElementById('addressDetail').value = selectedAddress.addressDetail;

      setFormData(prevState => {
        return {
          ...prevState,
          address: selectedAddress.address,
          addressDetail: selectedAddress.addressDetail,
        };
      });
    },
    onClickSubmit: async () => {
      if (!checkValidate()) return false;

      try {
        const response = await axios.put(`/apis/external/deposit/${params.reservationNumber}`, formData);

        if (response.status === 200) {
          retrieveReservation();
          toast({
            type: 'success',
            title: '확인',
            time: 2000,
            description: '화면에 출력된 정보대로 대금 결제를 진행해주세요.',
          });
        }
      } catch (error) {
        toast({
          type: 'error',
          title: 'ERROR',
          time: 5000,
          description: error.response.data.message,
        });
      }
    },
  };

  const renderDelaerAddressList = () => {
    const addressList = [];
    dealerAddress.current.map(o => {
      addressList.push(
        <Table.Row key={o.consignmentAddressId} style={{cursor: 'pointer'}} onClick={() => {
          setAddressOpen(false);
          eventHandler.onSelectAddress(o);
        }}>
          <Table.Cell><strong>{o.title}</strong></Table.Cell>
          <Table.Cell>{o.postalCode}</Table.Cell>
          <Table.Cell collapsing>{o.address}</Table.Cell>
          <Table.Cell>{o.addressDetail}</Table.Cell>
        </Table.Row>,
      );
    });

    return addressList;
  };

  return (
    <>
      <Dimmer active={loading} page>
        <Loader size="massive">Loading...</Loader>
      </Dimmer>

      <div style={{
        minHeight: '100vh',
        backgroundColor: BASE_STYLES.THEME_COLOR,
        padding: '20px 10px',
      }}>
        <Segment.Group>
          <Segment><Header as="h1">결제정보 확인</Header></Segment>
          <Segment>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={2}>예약번호</Table.Cell>
                  <Table.Cell><Header as="h2">{reservation.reservationNumber}</Header></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>차량번호</Table.Cell>
                  <Table.Cell><Header as="h2">{reservation.licensePlateNumber}</Header></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>낙찰가</Table.Cell>
                  <Table.Cell><Header as="h2">{Number(reservation.winBidAmount).toLocaleString()}</Header></Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
          <Transition visible={_.isEmpty(reservation.buyerAddress)}
                      animation="slide down"
                      duration={0}>
            <Segment>
              <Form size="huge" id="form">
                <Form.Input required={true}
                            name="address"
                            id="address"
                            readOnly={true}
                            label="탁송 도착지 주소"
                            placeholder="차량을 인도 받을 주소"
                            onChange={eventHandler.onChangeForm}
                            action={<Button color="orange"
                                            icon="search"
                                            size="massive"
                                            onClick={() => {
                                              eventHandler.onClickAddressPopup();
                                            }} />
                            } />
                <Form.Input type="text"
                            id="addressDetail"
                            name="addressDetail"
                            placeholder="나머지 주소"
                            onChange={eventHandler.onChangeForm}
                            action={<Button color="blue"
                                            icon="save"
                                            size="massive"
                                            loading={saveAddressLoading}
                                            disabled={saveAddressLoading || !formData.address || formData.address.length === 0}
                                            onClick={() => {
                                              eventHandler.onClickSaveAddress();
                                            }} />
                            } />


              </Form>
            </Segment>
          </Transition>

          <Transition visible={!_.isEmpty(reservation.buyerAddress)}>
            <Segment>
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={2}>수수료</Table.Cell>
                    <Table.Cell><Header as="h2">{Number(reservation.feeAmount).toLocaleString()}</Header></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>탁송비</Table.Cell>
                    <Table.Cell><Header
                      as="h2">{Number(reservation.transportAmount).toLocaleString()}</Header></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>총 입금 금액</Table.Cell>
                    <Table.Cell><Header as="h2"
                                        color="red">{Number(reservation.totalAmount).toLocaleString()}</Header></Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>입금 계좌</Table.Cell>
                    <Table.Cell>
                      <Header as="h3" style={{margin: 0}}>{CARMON_INFO.BANK_NAME} (예금주: {CARMON_INFO.BANK_ACCOUNT_OWNER})</Header>
                      <Header as="h3" style={{margin: 0}}>{CARMON_INFO.BANK_ACCOUNT_NUMBER}</Header>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Message warning><Icon name="warning sign" />입금내역이 확인되면 탁송기사가 배정됩니다.</Message>
            </Segment>
          </Transition>

          <Transition visible={_.isEmpty(reservation.buyerAddress)} duration={0}>
            <Segment>
              <Grid stackable>
                <Grid.Column width={16}>
                  <Button fluid
                          size="massive"
                          color="teal"
                          icon="paper plane"
                          form="form"
                          type="submit"
                          content="등록"
                          disabled={!_.isEmpty(reservation.buyerAddress)}
                          onClick={eventHandler.onClickSubmit} />
                </Grid.Column>
              </Grid>
            </Segment>
          </Transition>
        </Segment.Group>
        <Modal centered={false} size="small" open={addressOpen}>
          <Modal.Header>탁송지선택</Modal.Header>
          <Modal.Content>
            <Table basic="very" stackable selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>탁송지명</Table.HeaderCell>
                  <Table.HeaderCell>우편번호</Table.HeaderCell>
                  <Table.HeaderCell>주소</Table.HeaderCell>
                  <Table.HeaderCell>상세주소</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {renderDelaerAddressList()}
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => eventHandler.onClickOpenDaumPostModal()} icon="plus" color="blue" content="신규 주소" />
            <Button onClick={() => setAddressOpen(false)} icon="times" color="red" content="닫기" />
          </Modal.Actions>
        </Modal>
        <DaumPostCodeModal modalOption={daumPostModalOptions}
                           setModalOption={setDaumPostModalOptions} />

        <CarmonConsignmentAddress {...consignmentAddressModalOption}/>
      </div>
    </>
  );
};

const CarmonConsignmentAddress = ({open, close, callback}) => {
  const [addressName, setAddressName] = useState('');
  const [loading, setLoading] = useState(false);

  const eventHandler = {
    onChangeName: (e, {name, value}) => {
      setAddressName(value);
    },
    onClickSaveAddress: () => {
      if (addressName.length === 0) {
        toast({
          type: 'warning',
          title: '경고',
          time: 5000,
          description: '주소지 명칭이 입력되지 않았습니다.'
        });
        return false;
      }

      setLoading(true);
      callback && callback(addressName);
      close && close();
    }
  }

  return (
    <Modal open={open}
           size={'mini'}
           closeIcon
           onClose={() => close && close()}
           centered={false}>
      <Modal.Header>
        주소지 명칭을 입력하세요.
      </Modal.Header>

      <Modal.Content>
        <Input
          fluid
          size={'big'}
          name="addressName"
          placeholder={'주소지 명칭'}
          onChange={eventHandler.onChangeName} />

      </Modal.Content>
      <Modal.Actions>
        <Button color="blue"
                icon="save"
                size="large"
                content="저장"
                loading={loading}
                disabled={loading}
                onClick={() => {
                  eventHandler.onClickSaveAddress();
                }} />
      </Modal.Actions>
    </Modal>
  );
}

export default CarmonDealerDeposit;
