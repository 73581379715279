import React from 'react';
import {Container, Divider, Form, Header, Input, List, Segment} from 'semantic-ui-react';

const CarmonCenterRegistration = () => {
  return (
    <Container>
      <Segment>
        <Header as={'h1'}>
          <Header.Content>카몬</Header.Content>
          <Header.Subheader>정비소 서비스 제휴 신청</Header.Subheader>
        </Header>

        <Divider />

        <Form>
          <Form.Field
            control={Input}
            label="상호명"
            value={''}
          />
          <Form.Field
            control={Input}
            label="대표자명"
            value={''}
          />
          <Form.Field
            control={Input}
            label="주소"
            value={''}
          />
          <Form.Field
            control={Input}
            label="연락처"
            value={''}
          />
        </Form>
      </Segment>

      <Segment>
        <Header as={'h3'}>카몬은 어떤 서비스인가요?</Header>
        카몬은 중고차 판매를 원하는 개인이 가까운 카몬 제휴 정비소를 방문,
        국가공인 정비사가 실시한 차량 성능점검 데이터를 기반으로 [카몬 경매 시스템]에 차량이 등록됩니다.


        <Header as={'h3'}>정비사는 뭘 해야하나요?</Header>
        <div>
          정비사분들은 카몬 예약고객이 정비소를 방문하면 다음과 같은 작업을 진행해주시면됩니다.
          <List as='ol' ordered>
            <List.Item as='li'>차량 외관 사진 촬영</List.Item>
            <List.Item as='li'>82호 서식에 기준한 차량 성능 점검 진행</List.Item>
          </List>
        </div>

      </Segment>

    </Container>
  );
}

export default CarmonCenterRegistration;