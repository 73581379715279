import React, {Component, useEffect, useRef, useState} from 'react';
import {Comment, Menu, MenuItem, Segment} from 'semantic-ui-react';
import {useDispatch} from 'react-redux';
import {logout} from '../../reducers/AutoOasisAuthReducer';
import AutoOasisSettlement from './AutoOasisSettlement';
import AutoOasisDashboard from './AutoOasisDashboard';

const AutoOasisLayout = () => {
  const dispatch = useDispatch();

  const [activeItem, setActiveItem] = useState('settlement');
  const currentComponent = useRef(AutoOasisSettlement);

  const handleItemClick = (e, {name}) => {
      setActiveItem(name);
      if (name === 'home') {
        currentComponent.current = AutoOasisDashboard;
      } else if (name === 'settlement') {
        currentComponent.current = AutoOasisSettlement;
      } else if (name === 'logout') {
        dispatch(logout());
      }
  }

  return (
    <Segment basic style={{background:'#78bc29', minHeight: '100vh'}}>
      <Menu size={'large'} pointing secondary inverted>
        <Menu.Item
          name='home'
          content={'홈'}
          active={activeItem === 'home'}
          onClick={handleItemClick}
        />
        <Menu.Item
          name='settlement'
          content={'예약현황'}
          active={activeItem === 'settlement'}
          onClick={handleItemClick}
        />
        <Menu.Menu position='right'>
          <Menu.Item
            name='logout'
            content={'로그아웃'}
            active={activeItem === 'logout'}
            onClick={handleItemClick}
          />
        </Menu.Menu>
      </Menu>

      <Segment style={{marginBottom: '60px'}}>
        {
          React.createElement(currentComponent.current)
        }
      </Segment>

      <Segment basic
               style={{
                 width:'100%',
                 background:'#cfcfcf',
                 position:'absolute',
                 bottom: 0,
                 left: 0}}>Copyright © 카몬 All Rights Reserved.</Segment>
    </Segment>
  );
}

export default AutoOasisLayout
