import React, {useEffect, useRef, useState} from 'react';
import {
  Card,
  Container,
  Dimmer,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Item,
  List, Loader,
  Segment,
  Statistic, Table,
} from 'semantic-ui-react';
import {CountdownCircleTimer} from 'react-countdown-circle-timer';
import {ResponsiveRadar} from '@nivo/radar';
import _ from 'lodash';
import axios from 'axios';
import {toast} from 'react-semantic-toasts';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {CommonUtil} from '../utils/CommonUtil';

const CarmonAuctionProgress = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [remainingTime, setRemainingTime] = useState(0);
  const [reservation, setReservation] = useState({});
  const [displayAvgAmount, setDisplayAvgAmount] = useState(0);
  const [bidRank, setBidRank] = useState([]);

  useEffect(()=> {
    retrieveReservation();
    retrieveBidRank();
  }, []);

  useEffect(() => {
    displayAvgBidAmount();
  }, [reservation]);

  const retrieveReservation = async () => {
    try {
      const response = await axios.get(`/apis/external/auction/progress/${params.reservationNumber}`);
      if (response.status === 200) {
        setReservation(response.data);
        setRemainingTime(response.data.remainingTime);
        setLoading(false);
      }
    } catch (error) {
      toast({
        type: 'error',
        title: 'ERROR',
        time: 5000,
        description: error.response.data.message,
      });
    }
  };

  const retrieveBidRank = async () => {
    try {
      const response = await axios.get(`/apis/external/auction/bid/rank/${params.reservationNumber}`);
      if (response.status === 200) {
        setBidRank(response.data);
      }
    } catch (error) {
      toast({
        type: 'error',
        title: 'ERROR',
        time: 5000,
        description: error.response.data.message,
      });
    }
  }

  const displayAvgBidAmount = async () => {
    for (let i = 0; i <= reservation.avgBidAmount; i += Math.round(reservation.avgBidAmount/100)) {
      setDisplayAvgAmount(`${Number(Math.floor(i/10000)).toLocaleString()}만원`);
      await CommonUtil.delay(5);
    }
    setDisplayAvgAmount(`${Number(Math.floor(reservation.avgBidAmount/10000)).toLocaleString()}만원`);
  };

  return (
    <Container text>
      <Dimmer page active={loading}>
        <Loader size="massive" />
      </Dimmer>
      <Card fluid>
        <Image src={reservation.imageUrl} wrapped ui={false} />
        <Card.Content>
          <Card.Header>{reservation.name}</Card.Header>
          <Card.Meta>{reservation.seriesName} ({reservation.seriesName1}, {reservation.seriesName2})</Card.Meta>
          <Card.Description>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
              <div style={{flex: 1, fontSize: 12}}>
                {moment(reservation.firstDate).format('YYYY년 MM월')} ({reservation.year}년형)<br />
                {(reservation.mileage / 10000).toFixed(1)}만km<br />
              </div>
            </div>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <Grid divided="vertically" stackable>
            <Grid.Row centered>
              <Grid.Column
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <CountdownCircleTimer
                  isPlaying
                  size={240}
                  strokeWidth={20}
                  key={remainingTime}
                  duration={86400}
                  colors="#feb948"
                  initialRemainingTime={remainingTime}
                  onComplete={() => {
                  }}
                  children={({remainingTime}) => {
                    const hours = Math.floor(remainingTime / 3600);
                    const minutes = Math.floor((remainingTime % 3600) / 60);
                    const seconds = remainingTime % 60;

                    return (
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                        <Header as={'h1'} style={{margin: 0, color: '#696969'}}>
                          {_.padStart(_.toString(hours), 2, '0')}:{_.padStart(_.toString(minutes), 2, '0')}:{_.padStart(_.toString(seconds), 2, '0')}
                        </Header>
                        <Header as={'h3'} style={{margin: 0, color: '#696969'}}>
                          {remainingTime > 0 ? '경매진행중' : '경매종료'}
                        </Header>
                      </div>
                    );
                  }}>
                </CountdownCircleTimer>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <Grid.Column width={8} textAlign="center">
                <Statistic color="red" size="small">
                  <Statistic.Value>
                    {displayAvgAmount}
                  </Statistic.Value>
                  <Statistic.Label>평균입찰가</Statistic.Label>
                </Statistic>
              </Grid.Column>
              <Grid.Column width={8} textAlign="center">
                <Statistic color="blue" size="small">
                  <Statistic.Value>{reservation.totalBidCount}</Statistic.Value>
                  <Statistic.Label>입찰수</Statistic.Label>
                </Statistic>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan={3}>
                  <Header>
                    입찰가 TOP 5
                  </Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {
                Array.isArray(bidRank) ?
                  bidRank.map((row, idx) => {
                  return (
                    <Table.Row>
                      <Table.Cell>#{row.rank}</Table.Cell>
                      <Table.Cell textAlign={'center'}>{row.name}</Table.Cell>
                      <Table.Cell textAlign={'right'} style={{fontSize:'1.5rem'}}>
                        <Icon name={reservation.avgBidAmount > row.amount ? 'triangle down' : reservation.avgBidAmount === row.amount ? 'minus' : 'triangle up'}
                              color={reservation.avgBidAmount > row.amount ? 'blue' : reservation.avgBidAmount === row.amount ? 'grey' : 'red'} />
                        {`${Number(row.amount / 10000).toLocaleString()}만원`}
                        <br />
                        <span
                          style={{fontSize:'1.2rem', color: reservation.avgBidAmount > row.amount ? '#2185d0' : reservation.avgBidAmount === row.amount ? '#666666' : '#db2828'}}>
                          (
                          {(Number(row.amount / 10000) - Number(Math.floor(reservation.avgBidAmount / 10000))) > 0 ? '+' : '-'}
                          {`${Math.abs(Number(row.amount / 10000) - Number(Math.floor(reservation.avgBidAmount / 10000))).toLocaleString()}만원`}
                          )
                        </span>
                      </Table.Cell>
                    </Table.Row>
                  )
                })
                  :
                  <Table.Row>
                    <Table.Cell textAlign={'center'} colSpan={3}>현재 입찰자가 없습니다. 잠시 후 다시 확인해주세요.</Table.Cell>
                  </Table.Row>
              }
            </Table.Body>
          </Table>

        </Card.Content>
      </Card>
    </Container>
  );
};

export default CarmonAuctionProgress;