import React, {useEffect} from 'react';
import {Button, Icon, Modal} from 'semantic-ui-react';

const CarmonConfirm = ({
                         title = '',
                         content = '',
                         open = false,
                         close,
                         callBack,
                         size = 'small',
                         centered = false,
                       }) => {
  const onClickOk = () => {
    callBack && callBack();
    close && close();
  };

  const onClickNo = () => {
    close && close();
  };

  return (
    <Modal
      open={open}
      size={size}
      centered={centered}>

      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        {content}
      </Modal.Content>

      <Modal.Actions>
        <Button color="teal" onClick={onClickOk}>
          <Icon name="check" /> 확인
        </Button>
        <Button color="red" onClick={onClickNo}>
          <Icon name="remove" /> 취소
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CarmonConfirm;