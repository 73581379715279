import React, {useEffect, useRef, useState} from 'react';
import {Button, Dimmer, Header, Image, Icon} from 'semantic-ui-react';
import axios from 'axios';
import _ from 'lodash';

const WIRE_FRAME_URL = '/images/wireframe/image.png';

const CarmonImageControl = ({position, currentImageUrl, removeImage, callback}) => {
  const [active, setActive] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const fileControl = useRef();

  useEffect(() => {
    if (currentImageUrl !== null && typeof currentImageUrl === 'object' && !_.startsWith(currentImageUrl.url, "data:")) {
      getBlobFromUrl(currentImageUrl.url);
    } else {
      if (currentImageUrl) {
        if (currentImageUrl.hasOwnProperty('url')) {
          setImageUrl(currentImageUrl.url);
        } else if (typeof currentImageUrl === 'string') {
          setImageUrl(currentImageUrl);
        } else {
          setImageUrl(WIRE_FRAME_URL);
        }
      } else {
        setImageUrl(WIRE_FRAME_URL);
      }
    }
  }, [currentImageUrl]);

  const getBlobFromUrl = async (url) => {
    const response = await axios.get(url, {responseType: 'blob'});
    const file = new File([response.data], 'carmon.jpg');

    imageReadFromFile(file);
  }

  const imageReadFromFile = (file) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      setImageUrl(fileReader.result);
      callback && callback(position, file);
    }
    fileReader.readAsDataURL(file);
  }

  const eventHandler = {
    dimerShow: (show) => {
      setActive(show);
    },
    onClickImageSelector: () => {
      fileControl.current.click();
    },
    onClickImageRemove: () => {
      setImageUrl(WIRE_FRAME_URL);
      removeImage(position);
    },
    onSelectedFile: (event) => {
      let file = event.target.files[0];
      imageReadFromFile(file);
    },
  };

  const content = (
    <div>
      <input type="file" accept="image/*" ref={fileControl} onChange={eventHandler.onSelectedFile} hidden />
      <Button primary
              icon="image outline"
              content="불러오기"
              onClick={eventHandler.onClickImageSelector} />
      <Button icon="trash"
              color='red'
              onClick={eventHandler.onClickImageRemove}
              content="제거" />
    </div>
  );

  return (
    <Dimmer.Dimmable
      as={Image}
      dimmed={active}
      dimmer={{active, content}}
      onMouseEnter={() => eventHandler.dimerShow(true)}
      onMouseLeave={() => eventHandler.dimerShow(false)}
      size="medium"
      src={imageUrl}
    />
  );
};

export default CarmonImageControl;
